import { useRef, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useWindowSize } from 'usehooks-ts'
import {
  useScrollContainerRef,
  getAllSectionsElements,
  getDynamicScrollCount
} from '../../../../helpers/scroll'

export const useActiveSection = () => {
  const location = useLocation()
  const scrollContainerRef = useScrollContainerRef()
  const sectionRefs = useRef<HTMLElement[]>([])
  const [activeSection, setActiveSection] = useState(0)
  const { height = 0 } = useWindowSize()

  useEffect(() => {
    sectionRefs.current = Array.from(getAllSectionsElements()) as HTMLElement[]
  }, [location])

  useEffect(() => {
    const handleDefinedSectionsScroll = () => {
      const sections = sectionRefs.current
      let activeSectionIndex = 0
      for (let i = 0; i < sections.length; i++) {
        const { top } = sections[i].getBoundingClientRect()

        if (top < height / 2) {
          activeSectionIndex = i
        } else {
          break
        }
      }
      setActiveSection(activeSectionIndex)
    }

    const handleDynamicSectionsScroll = () => {
      const scrollContainer = scrollContainerRef.current
      const scrollHeight = scrollContainer?.scrollHeight ?? 0
      const clientHeight = scrollContainer?.clientHeight ?? 0
      const scrollTop = scrollContainer?.scrollTop ?? 0
      const sectionsCount = getDynamicScrollCount()

      const activeSectionIndex = Math.floor(
        (scrollTop / (scrollHeight - clientHeight)) * sectionsCount
      )

      setActiveSection(Math.min(sectionsCount - 1, activeSectionIndex))
    }

    const handleScroll = () => {
      if (sectionRefs.current.length) {
        // check sections elements positions if they are defined
        handleDefinedSectionsScroll()
      } else {
        // calculate scroll progress based on scroll position
        handleDynamicSectionsScroll()
      }
    }

    scrollContainerRef.current?.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      scrollContainerRef.current?.removeEventListener('scroll', handleScroll)
    }
  }, [scrollContainerRef])

  return activeSection
}
