import React, { useEffect, useMemo, useState } from 'react'
import { Element } from '../../../domain/state/Element'
import { Shape } from './Shape'
import * as THREE from 'three'
import { SessionContext } from '../../../utils/session/SessionProvider'

interface ElementControllerProps {
  element: Element
  parentScale: number
  parentRotation: THREE.Quaternion
  parentPosition: THREE.Vector3
}

export function ElementController({
  element,
  parentScale,
  parentRotation,
  parentPosition
}: ElementControllerProps) {
  const { surfaces } = React.useContext(SessionContext)

  const [guid, setGuid] = useState('')

  useEffect(() => {
    if (surfaces?.[element.shapeId]) {
      setGuid(surfaces[element.shapeId].guid)
    } else {
      console.warn(`Shape ID ${element.shapeId} not known`)
    }
  }, [surfaces, element.shapeId])

  const { rotation, scale, position } = useMemo(() => {
    const rotation = new THREE.Quaternion()
      .setFromEuler(element.rotation)
      .multiply(parentRotation)

    const scale = parentScale == 0 ? 0 : element.height / parentScale

    const position = new THREE.Vector3(element.position.x, element.position.y, element.position.z)
      .multiplyScalar(parentScale)
      .applyQuaternion(parentRotation)
      .add(parentPosition)

    return { rotation, scale, position }
  }, [parentScale, parentRotation, parentPosition, element])

  if (!rotation || !scale || !position) return null

  return (
    <group>
      <Shape id={guid} scale={element.height} position={position} rotation={rotation} />
      {element.elements.map((element, index) => (
        <ElementController
          key={`element-controller-${index}`}
          parentScale={scale}
          parentRotation={rotation}
          parentPosition={position}
          element={element}
        />
      ))}
    </group>
  )
}
