import { createPortal } from 'react-dom'
import React, { useEffect } from 'react'
import { Canvas } from '@react-three/fiber'
import { View } from '@react-three/drei'
import { OVERLAY_CONTAINER_ID } from '../frame/overlay/Overlay'
import styles from './ViewPortCanvas.module.scss'

export const ViewPortCanvas = () => {
  const [rootElement, setRootElement] = React.useState<HTMLElement | null>(null)

  useEffect(() => {
    if (rootElement) return

    const findRoot = () => {
      const root = document.getElementById(OVERLAY_CONTAINER_ID)

      if (root) {
        setRootElement(root)
      } else {
        setTimeout(findRoot, 100)
      }
    }

    return findRoot()
  }, [])

  return (
    rootElement &&
    createPortal(
      <div className={styles.vpCanvas}>
        <Canvas
          dpr={[1,2]}
          camera={{
            position: [0, 0, 100],
            fov: 1
          }}
          gl={{ localClippingEnabled: true, alpha: true }}
          resize={{ scroll: false, offsetSize: true }}
        >
          <View.Port />
        </Canvas>
      </div>,
      rootElement
    )
  )
}
