import { useFrame } from "@react-three/fiber"
import { ECS, simIds } from "./ECS"
import { State } from "../state/State"
import * as THREE from 'three'
import Decimal from "decimal.js"
import { targetSecond } from "../../ui/pages/simulator/Simulator"
import { useInterval } from "usehooks-ts"
import { DXYZ } from "../Common"

const entities = ECS.world.with("translation", "state", "three")

export default function ({ observer = undefined }: { observer?: State }) {

  function reposition(target: Decimal, state: State) {
    if (target.greaterThan(state.second)) {
      state.second = target
      if (state.translation.dock && state.translation.dock.parentId.length > 0) {
        const parentSimId = simIds[state.translation.dock.parentId]
        const parent = ECS.world.entity(parentSimId)

        if (parent && parent.state) {
          reposition(target, parent.state)

          state.translation.coordinates.x = state.translation.dock.position.x.add(parent.state.translation.coordinates.x)
          state.translation.coordinates.y = state.translation.dock.position.y.add(parent.state.translation.coordinates.y)
          state.translation.coordinates.z = state.translation.dock.position.z.add(parent.state.translation.coordinates.z)

          // console.debug(`Docked ${state.id} on ${parent.state.id}[${parentSimId}]`)
        } else {
          console.warn('Docked on non-indexed parent', state.id, parentSimId)
          state.translation.coordinates.x = state.translation.dock.position.x
          state.translation.coordinates.y = state.translation.dock.position.y
          state.translation.coordinates.z = state.translation.dock.position.z
        }
      } else if (state.translation.orbit) {
        const parentSimId = simIds['celestial-'+state.translation.orbit.parentId]
        const parent = ECS.world.entity(parentSimId)

        if (parent && parent.state) {
          reposition(target, parent.state)

          state.translation.coordinates.x = parent.state.translation.coordinates.x.add(state.translation.orbit.local_x)
          state.translation.coordinates.y = parent.state.translation.coordinates.y.add(state.translation.orbit.local_y)
          state.translation.coordinates.z = parent.state.translation.coordinates.z.add(state.translation.orbit.local_z)
          // console.debug(`Orbiting ${state.id} on ${parent.state.id} [${parentSimId}]`)
        } else {
          if (state.translation.orbit.parentId != 0) { // Exception for the star not being indexed
            console.warn('Orbiting non-indexed parent', state.id, parentSimId)
          }
          state.translation.coordinates.x = state.translation.orbit.local_x
          state.translation.coordinates.y = state.translation.orbit.local_y
          state.translation.coordinates.z = state.translation.orbit.local_z
        }
      }
    }
  }

  useInterval(() => {
    if (observer) {
      const target = targetSecond
      for (const { state } of entities) {
        State.updateTo(target, state)
      }
      for (const { state } of entities) {
        reposition(target, state)   
      }
    }
  }, 100)

  useFrame(function TranslationSystem(_, dt) {
    if (observer) {
      for (const { translation, state, three } of entities) {
        three.position.x = (state.translation.coordinates.x.sub(observer.translation.coordinates.x)).toNumber()
        three.position.y = (state.translation.coordinates.y.sub(observer.translation.coordinates.y)).toNumber()
        three.position.z = (state.translation.coordinates.z.sub(observer.translation.coordinates.z)).toNumber()
        
        // if (state.id[0] != 'c') {
        //   console.log(state.id,state.translation.coordinates.x.toString())
        // }

        three.rotation.setFromQuaternion(state.translation.rotation)
      }
    }
  })

  return null
}