import { Astronation as CommonDTO } from '../domain/protobuf/common'
import Decimal from 'decimal.js'

export class BigDecimal {
  private static readonly NanoFactor = new Decimal(1_000_000_000)

  public static ToJsNumber(input: CommonDTO.BigDecimal): Decimal {
    if (input == null || input == undefined) return new Decimal(0)
    else {
      let units = input.units? input.units : 0
      let nanos = input.nanos? input.nanos : 0
      return new Decimal(units).add(new Decimal(nanos).div(BigDecimal.NanoFactor))
    }
  }

  public static ToProtoBuf(input: Decimal): CommonDTO.BigDecimal {
    let result = new CommonDTO.BigDecimal()

    let units = Decimal.trunc(input).toNumber()

    result.units = units
    result.nanos = input.sub(units).mul(BigDecimal.NanoFactor).toNumber()

    return result
  }
}
