import React from 'react'
import { routes } from '../../../router'
import { Tabs } from '../../layout/tabs/Tabs'

export const Profile = () => {
  return (
    <div className="container">
      <Tabs
        links={[
          { label: 'Information', path: routes.profileInfo },
          { label: 'Statistics', path: routes.profileStats },
          { label: 'Accounts', path: routes.profileLinked },
        ]}
        allowVertical = {true}
      />
    </div>
  )
}
