import * as THREE from 'three'
import { Effect } from './Effect'
import { Expose, Transform, Type, plainToInstance } from 'class-transformer'
import { XYZ } from '../Common'
import { Astronation } from '../protobuf/state'

export class PEH {
  @Expose()
  power!: number

  @Expose()
  energy!: number

  @Expose()
  heat!: number
}

export class Compound {
  @Expose()
  share!: number
  @Expose()
  number!: number
  @Expose()
  count!: number
}

export class Element {
  @Expose()
  shapeId!: number

  @Expose()
  radius!: number

  @Expose()
  height!: number

  @Expose()
  fullness!: number

  @Expose()
  emptiness!: number

  @Expose()
  @Type(() => XYZ)
  @Transform(
    ({ value }) => (value ? new THREE.Euler(value.x, value.y, value.z, 'YXZ') : new THREE.Vector3()),
    { toClassOnly: true }
  )
  rotation!: THREE.Euler

  @Expose()
  @Type(() => XYZ)
  @Transform(
    ({ value }) => (value ? new THREE.Vector3(value.x, value.y, value.z) : new THREE.Vector3()),
    { toClassOnly: true }
  )
  position!: THREE.Vector3

  @Type(() => Compound)
  @Expose()
  composite!: Compound[]

  @Type(() => Element)
  @Expose()
  elements!: Element[]

  @Type(() => PEH)
  @Expose()
  peh!: PEH

  @Expose()
  configId?: number

  @Type(() => Effect)
  @Expose()
  effects?: Effect[]

  static fromProtobuf(input: Astronation.Element): Element {
    let output = ({ 
      shapeId: input.shapeId,
      radius: input.radius,
      height: input.height,
      fullness: input.fullness,
      emptiness: input.emptiness,
      rotation: new THREE.Euler(input.rotation.x, input.rotation.y, input.rotation.z, 'YXZ'),
      position: new THREE.Vector3(input.position.x, input.position.y, input.position.z),
      composite: input.composite.map((c) => ({ share: c.share, number: c.number, count: c.count })),
      peh: { power: input.peh.power, energy: input.peh.energy, heat: input.peh.heat },
      configId: input.cfgId,
      elements: input.elements.map((e) => Element.fromProtobuf(e))
    })
    return output
  }
}

export class ElementBody {
  @Type(() => Element)
  @Expose()
  elements!: Element[]

  static getAtPath = (path: number[], elements: Element[]): Element | undefined => {
    if (!path || path.length == 0) {
      return undefined
    } else {
      if (path.length == 1) return elements[path[0]]
      else return ElementBody.getAtPath(path.slice(1), elements[path[0]].elements)
    }
  }

  static fromProtobuf(input : Astronation.Body): ElementBody {
    let output: ElementBody = {
      elements: input.elements.map((e) => Element.fromProtobuf(e))
    }
    return output
  }
}
