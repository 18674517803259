import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { routes } from '../../../../router'
import { SessionContext } from '../../../../utils/session/SessionProvider'
import { config } from '../../../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faGlobe, faRightFromBracket, faRightToBracket } from '@fortawesome/free-solid-svg-icons'
import styles from './Overlay.module.scss'

interface HeaderProps {
  smallLogo?: boolean
  isMenuOpen?: boolean
  onMenuToggle: (isOpen: boolean) => void
}

export default function Header({ smallLogo, isMenuOpen, onMenuToggle }: HeaderProps) {
  const { identified, openAuth, logout } = React.useContext(SessionContext)
  const navigate = useNavigate()

  const nonIdentifiedLinks = [
    ['Store', routes.storeDefault],
    ['Stations', routes.stations]
  ]

  const identifiedLinks = [
    ...nonIdentifiedLinks,
    ['Vehicles', routes.vehicles],
    ['Profile', routes.profile]
  ]

  const links = identified ? identifiedLinks : nonIdentifiedLinks

  const underlineBlur = (
    <span className={styles.underline}>
      <span className={styles.underlineBlur} />
    </span>
  )

  const closedMenu = (
    <button className={styles.menuToggle}>
      Menu
      <span className={styles.icon}>
        <FontAwesomeIcon icon={faBars} />
      </span>
    </button>
  )

  const openMenu = (
    <>
      {links.map(([label, path]) => (
        <Link to={path} key={path} className={styles.menuLink}>
          {label}
          {underlineBlur}
        </Link>
      ))}
    </>
  )

  const handleAuthAction = () => {
    if (identified) {
      logout?.()
    } else {
      openAuth?.()
    }
  }

  const authActionLabel = identified ? 'Logout' : 'Login'
  const smallLogoUrl = config.image('logo/a-logo.png')
  const textLogoUrl = config.image('logo/text-logo.png')

  const authIcon = identified ? faRightFromBracket : faRightToBracket

  return (
    <header className={styles.header}>
      <div className={styles.logoContainer}>
        <Link to={routes.home}>
          <img
            src={smallLogo ? smallLogoUrl : textLogoUrl}
            alt="Astronation"
            className={styles.logo}
          />
        </Link>
      </div>

      <div
        className={styles.menu}
        onMouseEnter={() => onMenuToggle(true)}
        onMouseLeave={() => onMenuToggle(false)}
      >
        {isMenuOpen ? openMenu : closedMenu}
      </div>

      <div className={styles.headerActions}>
        
        <button onClick={() => navigate(routes.systemMap)} className={styles.action} title='System map'>
          <svg className={styles.background} viewBox="0 0 76 37" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.36 6.4C.93 4.42 2.34 1.64 4.79 1.64h60.31c6.08 0 11 4.93 11 11v12c0 6.08-4.92 11-11 11H29.1c-3.53 0-6.85-1.7-8.91-4.56L2.36 6.4z" fill="#0D282E" stroke="#428B9B" strokeWidth="1.5"/>
          </svg>
          <span>
            <FontAwesomeIcon icon={faGlobe} />
          </span>
        </button>
        <button onClick={handleAuthAction} className={styles.action} title={authActionLabel}>
          <FontAwesomeIcon icon={authIcon} />
        </button>
      </div>
    </header>
  )
}
