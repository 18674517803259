import React, { useState } from 'react'
import { Form, Link } from 'react-router-dom'
import { SessionContext } from '../../../utils/session/SessionProvider'
import { routes } from '../../../router'
import { Button } from '../../layout/controls/button/Button'
import { Input } from '../../layout/controls/input/Input'
import { nodeApi } from '../../../api/NodeApi'

export default function AuthToken({
  isVisible,
  setToken
}: {
  isVisible: boolean
  setToken: (token: string) => void
}) {
  const sessionCtx = React.useContext(SessionContext)
  if (!sessionCtx || !isVisible) return <></>

  const [email, setEmail] = useState<string>('')
  const [emailError, setEmailError] = useState<string | null>(null)

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setEmail(event.target.value)

  const handleSendCode = async () => {
    setEmailError(null) // reset error state

    if (!email) {
      setEmailError('Please enter an email address')
      return
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegex.test(email)) {
      setEmailError('Please enter a valid email address')
      return
    }

    try {
      console.log(`Sending verification code to: ${email}`)
      nodeApi.getAuthToken({ kind: 2, data: email }).then(
        (codeResult) => {
          if (codeResult) {
            setToken(codeResult.token)
          } else {
            setEmailError('Failed. Check the data and try again')
          }
        },
        (reason) => {
          console.error(`Failed sending code to provided email`, reason)
        }
      )
    } catch (err) {
      console.error('Error requesting code:', err)
      setEmailError('Failed to request code. Please try again.')
    }
  }

  return (
    <Form onSubmit={handleSendCode}>
      <h1>Access Verification</h1>

      <Input
        placeholder="Email"
        label="Register or login with your email"
        value={email}
        onChange={handleEmailChange}
        error={emailError}
      />

      <p>
        Your email will be linked to any purchases you make. <br />
        Make sure your email account is secure!
      </p>

      <Button type="submit">Send me the access code</Button>
      <Link to={routes.home}>
        <Button variant="ghost">Go back to main page</Button>
      </Link>
    </Form>
  )
}
