import { getGPUTier } from 'detect-gpu'
import { useEffect, useState } from 'react'

const HIGH_END_FPS = 150

export const isHighEndGpu = () => {
  const [isHighEnd, setIsHighEnd] = useState<boolean>(false)

  useEffect(() => {
    getGPUTier().then(({ fps = 0 }) => {
      setIsHighEnd(fps >= HIGH_END_FPS)
    })
  }, [])

  return isHighEnd
}
