import React from 'react'
import styles from './BodyContents.module.scss'
import clsx from 'clsx'
import { Button } from '../../layout/controls/button/Button'
import { Vector3 } from 'three'
import { useSurfaceData } from '../../helpers/session/useSurfaceData'
import { labelForItemKind, labelForVehicleKind } from '../../helpers/labels'
import { VehicleEntity } from '../../helpers/session/useOwnedVehicles'
import { ElementBody } from '../../../domain/state/Element'
import { ElementCarousel, RenderOverlayProps } from '../../layout/carousels/element/ElementCarousel'

interface OtherVehiclesProps {
  body: ElementBody
  path: number[]
  onSetAsCurrent: (index: number) => void
}

export const BodyContents = ({
  body,
  path,
  onSetAsCurrent
}: OtherVehiclesProps) => {
  const surfaceData = useSurfaceData()
  const element = ElementBody.getAtPath(path, body.elements)
  const contents = element?.elements ?? body.elements
  
  if (contents.length == 0) return <></>

  const currentIndex = path[path.length - 1] ?? -1
    
  const renderOverlay = ({ id, element, isActive, overlayProps }: RenderOverlayProps) => {
    const isCurrent = currentIndex === Number(id)
    const surface = surfaceData(element.shapeId)

    return (
      <div
        className={clsx(styles.overlay, {
          [styles.active]: isActive,
          [styles.current]: isCurrent
        })}
        {...overlayProps}
      >
        <div className={styles.content}>
          <h3>{surface.name}</h3>

          <div className={styles.link}>
            <Button
              variant="secondary"
              size="small"
              onClick={() => {
                if (!isCurrent) onSetAsCurrent(Number(id))
              }}
            >
              {isCurrent ? 'Current' : 'Select'}
            </Button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.ovContainer}>
      <h2>Contents</h2>
      <ElementCarousel
        overlayOnTop={true}
        overflowCount={5}
        slideOffset={1.2}
        size={0.8}
        prefix="configure-contents"
        elements={contents}
        height={200}
        modelPosition={new Vector3(0, 0.13, 0)}
        renderHtmlOverlay={renderOverlay}
        cameraDistance={80}
      />
    </div>
  )
}
