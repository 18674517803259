import React, { useEffect, useRef, useState } from 'react'

// @ts-ignore
import ShopifyBuy from '@shopify/buy-button-js'

import styles from './ShopifyListing.module.scss'
import { config } from '../../../config'
import { recalculateScrollHeight } from '../../helpers/scroll'
import { Spinner } from '../../layout/controls/spinner/Spinner'

const client = ShopifyBuy.buildClient({
  domain: config.shopify.domain,
  storefrontAccessToken: config.shopify.token
})

const ui = ShopifyBuy.UI.init(client)

export const ShopifyListing = () => {
  const [isLoading, setLoading] = useState(true)
  const listingRef = useRef<HTMLDivElement>(null)
  const initialisedRef = useRef(false)

  useEffect(() => {
    if (!initialisedRef.current) {
      ui.createComponent('collection', {
        id: 272681795657,
        node: listingRef.current,
        moneyFormat: '%E2%82%AC%7B%7Bamount_with_dot_separator%7D%7D',
        options: {
          productSet: {
            iframe: false,
            events: {
              beforeRender: () => setLoading(false),
              // shopify populates the content without triggering ResizeOvserver
              // so we trigger it manually to properply calculate the scroll dots sections
              afterRender: recalculateScrollHeight
            }
          },
          product: {
            contents: {
              options: false
            },
            text: {
              button: 'View details'
            },
            buttonDestination: 'modal'
          }
        }
      })

      initialisedRef.current = true
    }
  }, [listingRef])

  return (
    <div className={styles.shopifyContainer} ref={listingRef}>
      <Spinner visible={isLoading} />
    </div>
  )
}
