import { Fullscreen } from '@react-three/uikit'
import { Defaults } from '@react-three/uikit-default'
import React from 'react'

export default function Overlay({ children }: { children: React.ReactNode }) {
  return (
    <Fullscreen flexDirection="column" alignItems="center" distanceToCamera={420}>
      <Defaults>{children}</Defaults>
    </Fullscreen>
  )
}
