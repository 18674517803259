import React from 'react'
import { config } from '../../../config'
import { FooterSection } from '../../layout/sections/footer/FooterSection'
import { ScrollSection } from '../../layout/sections/scroll/ScrollSection'

export const PrivacyPolicy = () => {
  const logoHeaderUrl = config.image('logo/a-logo-header.png')
  return (
    <div className="container-text">
      <ScrollSection>
        <img src={logoHeaderUrl} alt="Astronation" className="logo" />
        <h1>Privacy policy</h1>
        <p>
          The Personally Identifiable Information Administrator is: Astronation Sp. z o.o., ul.
          Boleslawa Prusa 16A/11, Poznań, Poland, Registered in the National Court Register by the
          District Court of Nowe Miasto and Wilda VIII Commercial Division of the National Court
          Register, under the number KRS: 0000715909, email: info@@astronation.world.
        </p>
        <p>
          Through our Website and Application, we collect the following personally identifiable
          data:
          <ul>
            <li>
              <strong>E-mail</strong> - You will be asked to provide an e-mail address for
              registration in the Service and Application
            </li>
            <li>
              <strong>Address</strong> - information derived from general internet connection
              policies such as IP address (and other information contained in system logs) are used
              by the Service's administrator for technical purposes. IP addresses can also be used
              for statistical purposes - to collect general demographic information (for example,
              the region of the connection).
            </li>
            <li>
              <strong>Public keys</strong> - You will be asked to identify your account using public
              keys in the Service and Application
            </li>
          </ul>
        </p>
      </ScrollSection>

      <ScrollSection>
        <p>
          Any claim relating to Astronation application or website shall be governed by the laws of
          the Republic of Poland without regard to its conflict of law provisions.
        </p>
        <p>
          Pursuant to the Data Protection Act of 29 August 1997 (Journal of Laws of 2014, item 1182,
          1662) you have the right to correct, supplement, update, rectify and request the removal
          of your personally identifiable data. To do so or if you simply want more information use
          the appropriate bookmarks on the Web site or send a message by e-mail to
          info@@astronation.world.
        </p>

        <p>
          We protect personal information by reasonable security safeguards against loss or theft,
          as well as unauthorized access, disclosure, copying, use or modification.
        </p>
        <p>
          Astronation Sp. z o.o. reserves the right to modify this privacy policy at any time, and
          inform you about significant changes via email. Changes and clarifications will take
          effect immediately upon their posting on the website. If we make material changes to this
          policy, we will notify you here that it has been updated, so that you are aware of what
          information we collect, how we use it, and under what circumstances, if any, we use and/or
          disclose it.
        </p>
      </ScrollSection>

      <ScrollSection>
        <p>
          You agree that Astronation Sp. z o.o. may collect and use your personally identifiable
          data mentioned above (email, address, public keys) and the data related to your device,
          its operating system and the Astronation application itself. This data may be gathered
          periodically to facilitate the distribution of software updates, continuous improvement
          and other communications related to the application (if any). You also agree to allow
          Astronation Sp. z o.o. to automatically make updates to the application without your
          express consent for each update. The updates mentioned may change the application’s terms,
          conditions, features, items, mechanics, or any other element of it. You acknowledge that
          failure to download and install the updates may lead to your inability to use the
          application – in such case, you shall not be entitled to receive a refund of any fees or
          any other form of compensation.
        </p>
      </ScrollSection>
      <FooterSection />
    </div>
  )
}
