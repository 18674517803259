/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.20.3
 * source: messages.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from './common'
import * as dependency_2 from './state'
import * as pb_1 from 'google-protobuf'
export namespace Astronation {
  export class SocketMessage extends pb_1.Message {
    #one_of_decls: number[][] = []
    constructor(
      data?:
        | any[]
        | {
            kind?: number
            payload?: Uint8Array
          }
    ) {
      super()
      pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls)
      if (!Array.isArray(data) && typeof data == 'object') {
        if ('kind' in data && data.kind != undefined) {
          this.kind = data.kind
        }
        if ('payload' in data && data.payload != undefined) {
          this.payload = data.payload
        }
      }
    }
    get kind() {
      return pb_1.Message.getFieldWithDefault(this, 1, 0) as number
    }
    set kind(value: number) {
      pb_1.Message.setField(this, 1, value)
    }
    get payload() {
      return pb_1.Message.getFieldWithDefault(this, 2, new Uint8Array(0)) as Uint8Array
    }
    set payload(value: Uint8Array) {
      pb_1.Message.setField(this, 2, value)
    }
    static fromObject(data: { kind?: number; payload?: Uint8Array }): SocketMessage {
      const message = new SocketMessage({})
      if (data.kind != null) {
        message.kind = data.kind
      }
      if (data.payload != null) {
        message.payload = data.payload
      }
      return message
    }
    toObject() {
      const data: {
        kind?: number
        payload?: Uint8Array
      } = {}
      if (this.kind != null) {
        data.kind = this.kind
      }
      if (this.payload != null) {
        data.payload = this.payload
      }
      return data
    }
    serialize(): Uint8Array
    serialize(w: pb_1.BinaryWriter): void
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
      const writer = w || new pb_1.BinaryWriter()
      if (this.kind != 0) writer.writeInt32(1, this.kind)
      if (this.payload.length) writer.writeBytes(2, this.payload)
      if (!w) return writer.getResultBuffer()
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SocketMessage {
      const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes),
        message = new SocketMessage()
      while (reader.nextField()) {
        if (reader.isEndGroup()) break
        switch (reader.getFieldNumber()) {
          case 1:
            message.kind = reader.readInt32()
            break
          case 2:
            message.payload = reader.readBytes()
            break
          default:
            reader.skipField()
        }
      }
      return message
    }
    serializeBinary(): Uint8Array {
      return this.serialize()
    }
    static deserializeBinary(bytes: Uint8Array): SocketMessage {
      return SocketMessage.deserialize(bytes)
    }
  }
  export class SystemInfo extends pb_1.Message {
    #one_of_decls: number[][] = []
    constructor(
      data?:
        | any[]
        | {
            celestials?: Celestial[]
          }
    ) {
      super()
      pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls)
      if (!Array.isArray(data) && typeof data == 'object') {
        if ('celestials' in data && data.celestials != undefined) {
          this.celestials = data.celestials
        }
      }
    }
    get celestials() {
      return pb_1.Message.getRepeatedWrapperField(this, Celestial, 1) as Celestial[]
    }
    set celestials(value: Celestial[]) {
      pb_1.Message.setRepeatedWrapperField(this, 1, value)
    }
    static fromObject(data: { celestials?: ReturnType<typeof Celestial.prototype.toObject>[] }): SystemInfo {
      const message = new SystemInfo({})
      if (data.celestials != null) {
        message.celestials = data.celestials.map((item) => Celestial.fromObject(item))
      }
      return message
    }
    toObject() {
      const data: {
        celestials?: ReturnType<typeof Celestial.prototype.toObject>[]
      } = {}
      if (this.celestials != null) {
        data.celestials = this.celestials.map((item: Celestial) => item.toObject())
      }
      return data
    }
    serialize(): Uint8Array
    serialize(w: pb_1.BinaryWriter): void
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
      const writer = w || new pb_1.BinaryWriter()
      if (this.celestials.length) writer.writeRepeatedMessage(1, this.celestials, (item: Celestial) => item.serialize(writer))
      if (!w) return writer.getResultBuffer()
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SystemInfo {
      const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes),
        message = new SystemInfo()
      while (reader.nextField()) {
        if (reader.isEndGroup()) break
        switch (reader.getFieldNumber()) {
          case 1:
            reader.readMessage(message.celestials, () =>
              pb_1.Message.addToRepeatedWrapperField(message, 1, Celestial.deserialize(reader), Celestial)
            )
            break
          default:
            reader.skipField()
        }
      }
      return message
    }
    serializeBinary(): Uint8Array {
      return this.serialize()
    }
    static deserializeBinary(bytes: Uint8Array): SystemInfo {
      return SystemInfo.deserialize(bytes)
    }
  }
  export class Reflection extends pb_1.Message {
    #one_of_decls: number[][] = []
    constructor(
      data?:
        | any[]
        | {
            id?: string
            strength?: number
            locations?: Location[]
          }
    ) {
      super()
      pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [3], this.#one_of_decls)
      if (!Array.isArray(data) && typeof data == 'object') {
        if ('id' in data && data.id != undefined) {
          this.id = data.id
        }
        if ('strength' in data && data.strength != undefined) {
          this.strength = data.strength
        }
        if ('locations' in data && data.locations != undefined) {
          this.locations = data.locations
        }
      }
    }
    get id() {
      return pb_1.Message.getFieldWithDefault(this, 1, '') as string
    }
    set id(value: string) {
      pb_1.Message.setField(this, 1, value)
    }
    get strength() {
      return pb_1.Message.getFieldWithDefault(this, 2, 0) as number
    }
    set strength(value: number) {
      pb_1.Message.setField(this, 2, value)
    }
    get locations() {
      return pb_1.Message.getRepeatedWrapperField(this, Location, 3) as Location[]
    }
    set locations(value: Location[]) {
      pb_1.Message.setRepeatedWrapperField(this, 3, value)
    }
    static fromObject(data: { id?: string; strength?: number; locations?: ReturnType<typeof Location.prototype.toObject>[] }): Reflection {
      const message = new Reflection({})
      if (data.id != null) {
        message.id = data.id
      }
      if (data.strength != null) {
        message.strength = data.strength
      }
      if (data.locations != null) {
        message.locations = data.locations.map((item) => Location.fromObject(item))
      }
      return message
    }
    toObject() {
      const data: {
        id?: string
        strength?: number
        locations?: ReturnType<typeof Location.prototype.toObject>[]
      } = {}
      if (this.id != null) {
        data.id = this.id
      }
      if (this.strength != null) {
        data.strength = this.strength
      }
      if (this.locations != null) {
        data.locations = this.locations.map((item: Location) => item.toObject())
      }
      return data
    }
    serialize(): Uint8Array
    serialize(w: pb_1.BinaryWriter): void
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
      const writer = w || new pb_1.BinaryWriter()
      if (this.id.length) writer.writeString(1, this.id)
      if (this.strength != 0) writer.writeDouble(2, this.strength)
      if (this.locations.length) writer.writeRepeatedMessage(3, this.locations, (item: Location) => item.serialize(writer))
      if (!w) return writer.getResultBuffer()
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Reflection {
      const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes),
        message = new Reflection()
      while (reader.nextField()) {
        if (reader.isEndGroup()) break
        switch (reader.getFieldNumber()) {
          case 1:
            message.id = reader.readString()
            break
          case 2:
            message.strength = reader.readDouble()
            break
          case 3:
            reader.readMessage(message.locations, () =>
              pb_1.Message.addToRepeatedWrapperField(message, 3, Location.deserialize(reader), Location)
            )
            break
          default:
            reader.skipField()
        }
      }
      return message
    }
    serializeBinary(): Uint8Array {
      return this.serialize()
    }
    static deserializeBinary(bytes: Uint8Array): Reflection {
      return Reflection.deserialize(bytes)
    }
  }
  export class Location extends pb_1.Message {
    #one_of_decls: number[][] = []
    constructor(
      data?:
        | any[]
        | {
            id?: string
            second?: dependency_1.Astronation.BigDecimal
            translation?: dependency_2.Astronation.Translation
          }
    ) {
      super()
      pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls)
      if (!Array.isArray(data) && typeof data == 'object') {
        if ('id' in data && data.id != undefined) {
          this.id = data.id
        }
        if ('second' in data && data.second != undefined) {
          this.second = data.second
        }
        if ('translation' in data && data.translation != undefined) {
          this.translation = data.translation
        }
      }
    }
    get id() {
      return pb_1.Message.getFieldWithDefault(this, 1, '') as string
    }
    set id(value: string) {
      pb_1.Message.setField(this, 1, value)
    }
    get second() {
      return pb_1.Message.getWrapperField(this, dependency_1.Astronation.BigDecimal, 2) as dependency_1.Astronation.BigDecimal
    }
    set second(value: dependency_1.Astronation.BigDecimal) {
      pb_1.Message.setWrapperField(this, 2, value)
    }
    get has_second() {
      return pb_1.Message.getField(this, 2) != null
    }
    get translation() {
      return pb_1.Message.getWrapperField(this, dependency_2.Astronation.Translation, 3) as dependency_2.Astronation.Translation
    }
    set translation(value: dependency_2.Astronation.Translation) {
      pb_1.Message.setWrapperField(this, 3, value)
    }
    get has_translation() {
      return pb_1.Message.getField(this, 3) != null
    }
    static fromObject(data: {
      id?: string
      second?: ReturnType<typeof dependency_1.Astronation.BigDecimal.prototype.toObject>
      translation?: ReturnType<typeof dependency_2.Astronation.Translation.prototype.toObject>
    }): Location {
      const message = new Location({})
      if (data.id != null) {
        message.id = data.id
      }
      if (data.second != null) {
        message.second = dependency_1.Astronation.BigDecimal.fromObject(data.second)
      }
      if (data.translation != null) {
        message.translation = dependency_2.Astronation.Translation.fromObject(data.translation)
      }
      return message
    }
    toObject() {
      const data: {
        id?: string
        second?: ReturnType<typeof dependency_1.Astronation.BigDecimal.prototype.toObject>
        translation?: ReturnType<typeof dependency_2.Astronation.Translation.prototype.toObject>
      } = {}
      if (this.id != null) {
        data.id = this.id
      }
      if (this.second != null) {
        data.second = this.second.toObject()
      }
      if (this.translation != null) {
        data.translation = this.translation.toObject()
      }
      return data
    }
    serialize(): Uint8Array
    serialize(w: pb_1.BinaryWriter): void
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
      const writer = w || new pb_1.BinaryWriter()
      if (this.id.length) writer.writeString(1, this.id)
      if (this.has_second) writer.writeMessage(2, this.second, () => this.second.serialize(writer))
      if (this.has_translation) writer.writeMessage(3, this.translation, () => this.translation.serialize(writer))
      if (!w) return writer.getResultBuffer()
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Location {
      const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes),
        message = new Location()
      while (reader.nextField()) {
        if (reader.isEndGroup()) break
        switch (reader.getFieldNumber()) {
          case 1:
            message.id = reader.readString()
            break
          case 2:
            reader.readMessage(message.second, () => (message.second = dependency_1.Astronation.BigDecimal.deserialize(reader)))
            break
          case 3:
            reader.readMessage(message.translation, () => (message.translation = dependency_2.Astronation.Translation.deserialize(reader)))
            break
          default:
            reader.skipField()
        }
      }
      return message
    }
    serializeBinary(): Uint8Array {
      return this.serialize()
    }
    static deserializeBinary(bytes: Uint8Array): Location {
      return Location.deserialize(bytes)
    }
  }
  export class Celestial extends pb_1.Message {
    #one_of_decls: number[][] = []
    constructor(
      data?:
        | any[]
        | {
            id?: number
            second?: dependency_1.Astronation.BigDecimal
            orbit?: dependency_2.Astronation.Orbit
            body?: dependency_2.Astronation.Body
            rotation?: dependency_1.Astronation.Vector3D
            revolution?: dependency_1.Astronation.Vector3D
          }
    ) {
      super()
      pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls)
      if (!Array.isArray(data) && typeof data == 'object') {
        if ('id' in data && data.id != undefined) {
          this.id = data.id
        }
        if ('second' in data && data.second != undefined) {
          this.second = data.second
        }
        if ('orbit' in data && data.orbit != undefined) {
          this.orbit = data.orbit
        }
        if ('body' in data && data.body != undefined) {
          this.body = data.body
        }
        if ('rotation' in data && data.rotation != undefined) {
          this.rotation = data.rotation
        }
        if ('revolution' in data && data.revolution != undefined) {
          this.revolution = data.revolution
        }
      }
    }
    get id() {
      return pb_1.Message.getFieldWithDefault(this, 1, 0) as number
    }
    set id(value: number) {
      pb_1.Message.setField(this, 1, value)
    }
    get second() {
      return pb_1.Message.getWrapperField(this, dependency_1.Astronation.BigDecimal, 2) as dependency_1.Astronation.BigDecimal
    }
    set second(value: dependency_1.Astronation.BigDecimal) {
      pb_1.Message.setWrapperField(this, 2, value)
    }
    get has_second() {
      return pb_1.Message.getField(this, 2) != null
    }
    get orbit() {
      return pb_1.Message.getWrapperField(this, dependency_2.Astronation.Orbit, 3) as dependency_2.Astronation.Orbit
    }
    set orbit(value: dependency_2.Astronation.Orbit) {
      pb_1.Message.setWrapperField(this, 3, value)
    }
    get has_orbit() {
      return pb_1.Message.getField(this, 3) != null
    }
    get body() {
      return pb_1.Message.getWrapperField(this, dependency_2.Astronation.Body, 4) as dependency_2.Astronation.Body
    }
    set body(value: dependency_2.Astronation.Body) {
      pb_1.Message.setWrapperField(this, 4, value)
    }
    get has_body() {
      return pb_1.Message.getField(this, 4) != null
    }
    get rotation() {
      return pb_1.Message.getWrapperField(this, dependency_1.Astronation.Vector3D, 5) as dependency_1.Astronation.Vector3D
    }
    set rotation(value: dependency_1.Astronation.Vector3D) {
      pb_1.Message.setWrapperField(this, 5, value)
    }
    get has_rotation() {
      return pb_1.Message.getField(this, 5) != null
    }
    get revolution() {
      return pb_1.Message.getWrapperField(this, dependency_1.Astronation.Vector3D, 6) as dependency_1.Astronation.Vector3D
    }
    set revolution(value: dependency_1.Astronation.Vector3D) {
      pb_1.Message.setWrapperField(this, 6, value)
    }
    get has_revolution() {
      return pb_1.Message.getField(this, 6) != null
    }
    static fromObject(data: {
      id?: number
      second?: ReturnType<typeof dependency_1.Astronation.BigDecimal.prototype.toObject>
      orbit?: ReturnType<typeof dependency_2.Astronation.Orbit.prototype.toObject>
      body?: ReturnType<typeof dependency_2.Astronation.Body.prototype.toObject>
      rotation?: ReturnType<typeof dependency_1.Astronation.Vector3D.prototype.toObject>
      revolution?: ReturnType<typeof dependency_1.Astronation.Vector3D.prototype.toObject>
    }): Celestial {
      const message = new Celestial({})
      if (data.id != null) {
        message.id = data.id
      }
      if (data.second != null) {
        message.second = dependency_1.Astronation.BigDecimal.fromObject(data.second)
      }
      if (data.orbit != null) {
        message.orbit = dependency_2.Astronation.Orbit.fromObject(data.orbit)
      }
      if (data.body != null) {
        message.body = dependency_2.Astronation.Body.fromObject(data.body)
      }
      if (data.rotation != null) {
        message.rotation = dependency_1.Astronation.Vector3D.fromObject(data.rotation)
      }
      if (data.revolution != null) {
        message.revolution = dependency_1.Astronation.Vector3D.fromObject(data.revolution)
      }
      return message
    }
    toObject() {
      const data: {
        id?: number
        second?: ReturnType<typeof dependency_1.Astronation.BigDecimal.prototype.toObject>
        orbit?: ReturnType<typeof dependency_2.Astronation.Orbit.prototype.toObject>
        body?: ReturnType<typeof dependency_2.Astronation.Body.prototype.toObject>
        rotation?: ReturnType<typeof dependency_1.Astronation.Vector3D.prototype.toObject>
        revolution?: ReturnType<typeof dependency_1.Astronation.Vector3D.prototype.toObject>
      } = {}
      if (this.id != null) {
        data.id = this.id
      }
      if (this.second != null) {
        data.second = this.second.toObject()
      }
      if (this.orbit != null) {
        data.orbit = this.orbit.toObject()
      }
      if (this.body != null) {
        data.body = this.body.toObject()
      }
      if (this.rotation != null) {
        data.rotation = this.rotation.toObject()
      }
      if (this.revolution != null) {
        data.revolution = this.revolution.toObject()
      }
      return data
    }
    serialize(): Uint8Array
    serialize(w: pb_1.BinaryWriter): void
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
      const writer = w || new pb_1.BinaryWriter()
      if (this.id != 0) writer.writeInt32(1, this.id)
      if (this.has_second) writer.writeMessage(2, this.second, () => this.second.serialize(writer))
      if (this.has_orbit) writer.writeMessage(3, this.orbit, () => this.orbit.serialize(writer))
      if (this.has_body) writer.writeMessage(4, this.body, () => this.body.serialize(writer))
      if (this.has_rotation) writer.writeMessage(5, this.rotation, () => this.rotation.serialize(writer))
      if (this.has_revolution) writer.writeMessage(6, this.revolution, () => this.revolution.serialize(writer))
      if (!w) return writer.getResultBuffer()
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Celestial {
      const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes),
        message = new Celestial()
      while (reader.nextField()) {
        if (reader.isEndGroup()) break
        switch (reader.getFieldNumber()) {
          case 1:
            message.id = reader.readInt32()
            break
          case 2:
            reader.readMessage(message.second, () => (message.second = dependency_1.Astronation.BigDecimal.deserialize(reader)))
            break
          case 3:
            reader.readMessage(message.orbit, () => (message.orbit = dependency_2.Astronation.Orbit.deserialize(reader)))
            break
          case 4:
            reader.readMessage(message.body, () => (message.body = dependency_2.Astronation.Body.deserialize(reader)))
            break
          case 5:
            reader.readMessage(message.rotation, () => (message.rotation = dependency_1.Astronation.Vector3D.deserialize(reader)))
            break
          case 6:
            reader.readMessage(message.revolution, () => (message.revolution = dependency_1.Astronation.Vector3D.deserialize(reader)))
            break
          default:
            reader.skipField()
        }
      }
      return message
    }
    serializeBinary(): Uint8Array {
      return this.serialize()
    }
    static deserializeBinary(bytes: Uint8Array): Celestial {
      return Celestial.deserialize(bytes)
    }
  }
  export class BodyElement extends pb_1.Message {
    #one_of_decls: number[][] = []
    constructor(
      data?:
        | any[]
        | {
            path?: dependency_1.Astronation.IntList
            element?: dependency_2.Astronation.Element
          }
    ) {
      super()
      pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls)
      if (!Array.isArray(data) && typeof data == 'object') {
        if ('path' in data && data.path != undefined) {
          this.path = data.path
        }
        if ('element' in data && data.element != undefined) {
          this.element = data.element
        }
      }
    }
    get path() {
      return pb_1.Message.getWrapperField(this, dependency_1.Astronation.IntList, 1) as dependency_1.Astronation.IntList
    }
    set path(value: dependency_1.Astronation.IntList) {
      pb_1.Message.setWrapperField(this, 1, value)
    }
    get has_path() {
      return pb_1.Message.getField(this, 1) != null
    }
    get element() {
      return pb_1.Message.getWrapperField(this, dependency_2.Astronation.Element, 2) as dependency_2.Astronation.Element
    }
    set element(value: dependency_2.Astronation.Element) {
      pb_1.Message.setWrapperField(this, 2, value)
    }
    get has_element() {
      return pb_1.Message.getField(this, 2) != null
    }
    static fromObject(data: {
      path?: ReturnType<typeof dependency_1.Astronation.IntList.prototype.toObject>
      element?: ReturnType<typeof dependency_2.Astronation.Element.prototype.toObject>
    }): BodyElement {
      const message = new BodyElement({})
      if (data.path != null) {
        message.path = dependency_1.Astronation.IntList.fromObject(data.path)
      }
      if (data.element != null) {
        message.element = dependency_2.Astronation.Element.fromObject(data.element)
      }
      return message
    }
    toObject() {
      const data: {
        path?: ReturnType<typeof dependency_1.Astronation.IntList.prototype.toObject>
        element?: ReturnType<typeof dependency_2.Astronation.Element.prototype.toObject>
      } = {}
      if (this.path != null) {
        data.path = this.path.toObject()
      }
      if (this.element != null) {
        data.element = this.element.toObject()
      }
      return data
    }
    serialize(): Uint8Array
    serialize(w: pb_1.BinaryWriter): void
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
      const writer = w || new pb_1.BinaryWriter()
      if (this.has_path) writer.writeMessage(1, this.path, () => this.path.serialize(writer))
      if (this.has_element) writer.writeMessage(2, this.element, () => this.element.serialize(writer))
      if (!w) return writer.getResultBuffer()
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): BodyElement {
      const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes),
        message = new BodyElement()
      while (reader.nextField()) {
        if (reader.isEndGroup()) break
        switch (reader.getFieldNumber()) {
          case 1:
            reader.readMessage(message.path, () => (message.path = dependency_1.Astronation.IntList.deserialize(reader)))
            break
          case 2:
            reader.readMessage(message.element, () => (message.element = dependency_2.Astronation.Element.deserialize(reader)))
            break
          default:
            reader.skipField()
        }
      }
      return message
    }
    serializeBinary(): Uint8Array {
      return this.serialize()
    }
    static deserializeBinary(bytes: Uint8Array): BodyElement {
      return BodyElement.deserialize(bytes)
    }
  }
}
