import React from 'react'
import styles from './Overlay.module.scss'
import { BackToTop } from './BackToTop'

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <BackToTop />
    </footer>
  )
}
