import { useContext, useEffect, useState } from 'react'
import { SessionContext } from '../../../utils/session/SessionProvider'
import { queryOnboardEntity } from '../../../api/queries'

export const useCurrentEntity = () => {
  const { user } = useContext(SessionContext)

  const { mutateAsync: onboardEntity } = queryOnboardEntity()

  const [currentEntityId, setCurrentEntityId] = useState(user?.guid ?? '')

  const setCurrentEntity = async (guid: string) => {
    try {
      await onboardEntity(guid)
      setCurrentEntityId(guid)
    } catch {
      console.error('Failed onboarding entity')
    }
  }

  useEffect(() => {
    user?.guid && setCurrentEntityId(user.guid)
  }, [user?.guid])

  return {
    currentEntityId,
    setCurrentEntity
  }
}
