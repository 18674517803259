import React from 'react'
import * as THREE from 'three'
import { ThreeEvent } from '@react-three/fiber'
import { Celestial } from '../../../domain/Celestials'
import { BodyOfElements } from '../elements/BodyOfElements'

interface CelestialControllerProps {
  celestial: Celestial
  size: number
  rotation?: THREE.Quaternion
  position?: THREE.Vector3
  onClick?: (event: ThreeEvent<MouseEvent>) => void
}

export function CelestialController({
  celestial,
  position,
  rotation,
  size,
  onClick
}: CelestialControllerProps) {
  return (
    <BodyOfElements
      body={celestial.body}
      position={position}
      rotation={rotation}
      size={size}
      onClick={onClick}
    />
  )
}
