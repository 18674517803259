import Decimal from 'decimal.js'
import * as THREE from 'three'
import { BasePhysics } from '../../../math/BasePhysics'
import { Exclude, Expose, plainToClass, plainToClassFromExist, plainToInstance, Transform } from 'class-transformer'
import { Astronation } from '../../protobuf/state'
import { BigDecimal } from '../../../math/BigDecimal'

@Expose()
export class Orbit {
  @Expose()
  parentId!: number

  @Expose()
  @Transform(({ value }) => (value ? new Decimal(value) : new Decimal(0)), { toClassOnly: true })
  @Transform(({ obj }) => obj.distancePerAttempt.toNumber(), { toPlainOnly: true })
  angle!: Decimal

  @Expose()
  @Transform(({ value }) => (value ? new Decimal(value) : new Decimal(0)), { toClassOnly: true })
  @Transform(({ obj }) => obj.distancePerAttempt.toNumber(), { toPlainOnly: true })
  minRadius!: Decimal

  @Expose()
  @Transform(({ value }) => (value ? new Decimal(value) : new Decimal(0)), { toClassOnly: true })
  @Transform(({ obj }) => obj.distancePerAttempt.toNumber(), { toPlainOnly: true })
  eccentricity!: Decimal

  @Expose()
  @Transform(({ value }) => (value ? new Decimal(value) : new Decimal(0)), { toClassOnly: true })
  @Transform(({ obj }) => obj.distancePerAttempt.toNumber(), { toPlainOnly: true })
  inclination!: Decimal

  @Expose()
  @Transform(({ value }) => (value ? new Decimal(value) : new Decimal(0)), { toClassOnly: true })
  @Transform(({ obj }) => obj.distancePerAttempt.toNumber(), { toPlainOnly: true })
  periapsis!: Decimal

  @Expose()
  @Transform(({ value }) => (value ? new Decimal(value) : new Decimal(0)), { toClassOnly: true })
  @Transform(({ obj }) => obj.distancePerAttempt.toNumber(), { toPlainOnly: true })
  longitude!: Decimal

  @Expose()
  ownMass!: number

  @Expose()
  parentMass!: number

  @Exclude()
  private baseSecond: Decimal = new Decimal(0)

  @Exclude()
  private gParent: number = 0
  @Exclude()
  private maxRadius: Decimal = new Decimal(0)
  @Exclude()
  private semiMajor: Decimal = new Decimal(0)
  @Exclude()
  private sinLongitude: number = 0
  @Exclude()
  private cosLongitude: number = 0
  @Exclude()
  private cosInclination: number = 0
  @Exclude()
  private sinInclination: number = 0
  @Exclude()
  private baseAngle: Decimal = new Decimal(0)

  @Exclude()
  public local_x: Decimal = new Decimal(0)
  @Exclude()
  public local_y: Decimal = new Decimal(0)
  @Exclude()
  public local_z: Decimal = new Decimal(0)

  @Exclude()
  public absolute_x: Decimal = new Decimal(0)
  @Exclude()
  public absolute_y: Decimal = new Decimal(0)
  @Exclude()
  public absolute_z: Decimal = new Decimal(0)

  @Exclude()
  private bigOne = new Decimal(1)

  @Exclude()
  private _meanMotion?: Decimal
  get meanMotion(): Decimal {
    if (this._meanMotion == undefined) {
      this._meanMotion = new Decimal(
        this.semiMajor.isPositive() && this.gParent
          ? Math.sqrt(this.gParent / Math.pow(this.semiMajor.toNumber(), 3))
          : 0
      )
    }

    return this._meanMotion
  }

  constructor(parentId: number,
    minRadius: Decimal,
    angle: Decimal,
    eccentricity: Decimal,
    inclination: Decimal,
    periapsis: Decimal,
    longitude: Decimal,
    ownMass: number,
    parentMass: number) {
      this.parentId = parentId
      this.minRadius = minRadius
      this.angle = angle
      this.eccentricity = eccentricity
      this.inclination = inclination
      this.periapsis = periapsis
      this.longitude = longitude
      this.ownMass = ownMass
      this.parentMass = parentMass
  }

  initialize() {
    this.maxRadius = this.minRadius
      .mul(this.eccentricity.add(1))
      .div(new Decimal(1).sub(this.eccentricity))
    this.semiMajor = this.minRadius.add(this.maxRadius).div(2)

    this.baseAngle = this.angle

    this.sinLongitude = Math.sin(this.longitude.toNumber())
    this.cosLongitude = Math.cos(this.longitude.toNumber())
    this.cosInclination = Math.cos(this.inclination.toNumber())
    this.sinInclination = Math.sin(this.inclination.toNumber())

    this.local_x = new Decimal(0)
    this.local_y = new Decimal(0)
    this.local_z = new Decimal(0)

    this.gParent = BasePhysics.G * this.parentMass
  }

  updateTo(targetSecond: Decimal) {
    this.angle = this.baseAngle
      .add(this.meanMotion.mul(targetSecond.sub(this.baseSecond ?? 0)))
      .mod(BasePhysics.TAU)

    const currentRadius = this.semiMajor
      .mul(this.bigOne.sub(this.eccentricity.mul(this.eccentricity)))
      .div(this.bigOne.add(this.eccentricity.mul(Decimal.cos(this.angle))))

    const perAngle = this.periapsis.add(this.angle)
    const cosPerAngle = Decimal.cos(perAngle)
    const sinPerAngle = Decimal.sin(perAngle)

    this.local_x = currentRadius.mul(
      cosPerAngle
        .mul(this.cosLongitude)
        .sub(sinPerAngle.mul(this.cosInclination * this.sinLongitude))
    )
    this.local_y = currentRadius.mul(sinPerAngle.mul(this.sinInclination))
    this.local_z = currentRadius.mul(
      cosPerAngle
        .mul(this.sinLongitude)
        .add(sinPerAngle.mul(this.cosLongitude * this.cosInclination))
    )
  }

  static fromProtobuf(input: Astronation.Orbit): Orbit | undefined {
    if (!input || !input.minRadius || (input.minRadius.units == 0 && input.minRadius.nanos == 0)) {
      return undefined
    }

    const orbit = new Orbit(
      input.parentId,
      BigDecimal.ToJsNumber(input.minRadius),
      BigDecimal.ToJsNumber(input.angle),
      BigDecimal.ToJsNumber(input.eccentricity),
      BigDecimal.ToJsNumber(input.inclination),
      BigDecimal.ToJsNumber(input.periapsis),
      BigDecimal.ToJsNumber(input.longitude),
      input.ownMass,
      0 // FIXME
    )
    
    orbit.initialize()
    return orbit
  }
}
