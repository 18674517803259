import React, { ReactNode } from 'react'
import styles from './ScrollSection.module.scss'
import clsx from 'clsx'
import { getSectionClassName } from '../../../helpers/scroll'

export const ScrollSection = ({ children }: { children: ReactNode }) => {
  return (
    <div>
      <div className={clsx(styles.marker, getSectionClassName('top'))} />
      {children}
      <div className={clsx(styles.marker, getSectionClassName('bottom'))} />
    </div>
  )
}
