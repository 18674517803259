import React, { useContext } from 'react'
import styles from './Profile.module.scss'
import stylesContent from '../../layout/sections/content/ContentSection.module.scss'
import { Button } from '../../layout/controls/button/Button'
import { ContentSection } from '../../layout/sections/content/ContentSection'
import { SessionContext } from '../../../utils/session/SessionProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCross, faCrosshairs, faPlus } from '@fortawesome/free-solid-svg-icons'

export const ProfileLinkedAccounts = () => {
  const { user } = useContext(SessionContext)

  return (
      <ContentSection
        className={styles.profileContent}>
        <h1 style={{display: 'flex', flexDirection: 'row'}}>
          <span>Linked accounts</span>
          <button className={stylesContent.toolButton}>
            <FontAwesomeIcon icon={faPlus} size='2x' />
          </button>
        </h1>
      </ContentSection>
  )
}
