import React, { useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import styles from './Checkout.module.scss'
import { EntityPreview, EntityPreviewRef } from '../../layout/previews/entity/EntityPreview'
import { queryCheckout as queryCheckoutBody, queryStoreVehicles } from '../../../api/queries'
import { useSurfaceData } from '../../helpers/session/useSurfaceData'
import { PurchaseOptions } from './PurchaseOptions'
import {
  getVehicleRotationOptions,
  RotationControls
} from '../../layout/controls/rotation/RotationControls'
import { ScrollSection } from '../../layout/sections/scroll/ScrollSection'
import { CheckoutDetails } from './CheckoutDetails'
import { ContentSection } from '../../layout/sections/content/ContentSection'

const defaultRotation = 3

export const Checkout = () => {
  const id = useParams().id ?? ''
  const ref = useRef<EntityPreviewRef>(null)

  const [paymentOpen, setPaymentOpen] = useState(false)
  const [selectedRotation, selectRotation] = useState<number>(defaultRotation)

  const { data: vehicleOffers, isFetching: fetchingStoreVehicles } = queryStoreVehicles()
  const offers = vehicleOffers?.filter((item) => item.checkout === id) ?? []

  const { data: checkoutBody, isFetching: fetchingCheckoutBody } = queryCheckoutBody(id)

  const getSurfaceData = useSurfaceData()
  const coreSurfaceInfo = getSurfaceData(checkoutBody?.elements?.[0]?.shapeId ?? 0)

  const isLoading = fetchingStoreVehicles || fetchingCheckoutBody
  if (isLoading) return
  if (!checkoutBody || !coreSurfaceInfo) return

  const rotationOptions = getVehicleRotationOptions(coreSurfaceInfo.guid)

  return (
    <div className="container-grid">
      <RotationControls
        className={styles.controls}
        options={rotationOptions}
        selectedOption={selectedRotation}
        onChange={(selectedIndex) => {
          selectRotation(selectedIndex)
          if (selectedIndex === selectedRotation) {
            ref.current?.resetRotation()
          }
        }}
      />
      <ScrollSection>
        <EntityPreview
          ref={ref}
          body={checkoutBody}
          rotation={rotationOptions[selectedRotation].rotation}
        />
      </ScrollSection>
      <CheckoutDetails
        onBuyPress={() => setPaymentOpen(true)}
        surfaceInfo={coreSurfaceInfo}
        offers={offers}
        visible={!paymentOpen}
      />
      <PurchaseOptions
        checkout={id}
        surfaceInfo={coreSurfaceInfo}
        onBackPress={() => setPaymentOpen(false)}
        offers={offers}
        visible={paymentOpen}
      />
    </div>
  )
}
