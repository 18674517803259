import React, { useRef, useState } from 'react'
import styles from './Current.module.scss'
import { EntityPreview, EntityPreviewRef } from '../../layout/previews/entity/EntityPreview'
import { Button } from '../../layout/controls/button/Button'
import { ContentSection } from '../../layout/sections/content/ContentSection'
import { Link } from 'react-router-dom'
import { routes } from '../../../router'
import { useSurfaceData } from '../../helpers/session/useSurfaceData'
import { labelForItemKind, labelForVehicleKind } from '../../helpers/labels'
import {
  getVehicleRotationOptions,
  RotationControls
} from '../../layout/controls/rotation/RotationControls'
import { State } from '../../../domain/state/State'
import { ElementBody } from '../../../domain/state/Element'
import { Shape } from '../../parts/elements/Shape'

import * as THREE from 'three'
import { ElementPreview } from '../../layout/previews/element/ElementPreview'
import { Select } from '../../layout/controls/select/Select'

interface CurrentProps {
  entity: State
  path: number[]
  onBackClick: () => void
}

export const Current = ({ entity, path, onBackClick }: CurrentProps) => {
  const surfaces = useSurfaceData()
  const ref = useRef<EntityPreviewRef>(null)
  const [selected, setSelected] = useState<number>(3) //  default rotation

  const element = ElementBody.getAtPath(path, entity.body.elements)
  const surfaceData = surfaces(element?.shapeId??entity.body.elements[0].shapeId)

  const rotationOptions = getVehicleRotationOptions(surfaceData.guid)

  const title = surfaceData.name // TODO: replace with entity metadata
  const subtitle = path.length > 0 ? labelForItemKind(surfaceData.kind) : 'Configure entity'
  const caption = path.length > 0 ? 'Element configuration' : 'Entity configuration'

  const updateConfiguration = () => {}
  const reloadConfiguration = () => {}

  return (
    <>
      <RotationControls
        className={styles.controls}
        options={rotationOptions}
        selectedOption={selected}
        onChange={(index) => {
          setSelected(index)
          if (index === selected) {
            ref.current?.resetRotation()
          }
        }}
      />
      <div className={styles.cvContainer}>
        <div className={styles.preview}>
          <div className={styles.previewWrapper}>
            {(!element) && <EntityPreview ref={ref} rotation={rotationOptions[selected].rotation} body={entity.body} />}
            {(!!element) && <ElementPreview ref={ref} rotation={rotationOptions[selected].rotation} element={element} />}
          </div>
        </div>

        <div className={styles.details}>
          <ContentSection
            enabledBack={path.length > 0}
            handleBackPress={onBackClick}
            
            caption={caption}
            title={title}
            subtitle={subtitle}
          >
            <p>
              <Select label='Service' options={[{ caption: '... TBA ...', value:'-'}]} />
              <Select label='Kind' options={[{ caption: '... TBA ...', value:'-'}]} />
              <Select label='Data' options={[{ caption: '... TBA ...', value:'-'}]} />
            </p>
            <div className={styles.buttons}>
              <Button variant='secondary' size='medium' onClick={updateConfiguration}>
                Confirm
              </Button>
              <Button variant='secondary' size='medium' onClick={reloadConfiguration}>
                Reload
              </Button>
            </div>
          </ContentSection>
        </div>
      </div>
    </>
  )
}
