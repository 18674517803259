import React from 'react'
import styles from './Checkout.module.scss'
import { Button } from '../../layout/controls/button/Button'
import { config } from '../../../config'
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js'
import { SessionContext } from '../../../utils/session/SessionProvider'
import { nodeApi, StoreOffer } from '../../../api/NodeApi'

interface PaymentButtonsProps {
  offers: StoreOffer[]
  visible: boolean
  updateStatus: (paymentStatus: string) => void
}

export const PaymentButtons = ({ offers, visible, updateStatus }: PaymentButtonsProps) => {
  if (!visible || offers.length == 0) return

  const { session, user } = React.useContext(SessionContext)

  const coinbaseCommerceGSSVehicle = offers?.find((i) => i.kind == 1)
  const desoNftzAlphaShips = offers?.find((i) => i.kind == 2)
  // const desoNftzPathfinderStations = offers?.find((i) => i.kind == 3)
  // const openseaExplorerCards = offers?.find((i) => i.kind == 4)
  const paypalGSSVehicleOffer = offers?.find((i) => i.kind == 5)

  const handleClaimNft = async () => {
    try {
      if (desoNftzAlphaShips) {
        await nodeApi.claimNft(desoNftzAlphaShips.storeId, session!, user!.system)
        updateStatus('complete')
      } else updateStatus('Missing claimable offer')
    } catch (error) {
      console.error(error)
      updateStatus(`Failed due to: ${error}`)
    }
  }

  return (
    <>
      <p>How would you like to pay?</p>

      <div className={styles.paypal}>
        <PayPalScriptProvider
          options={{
            clientId: config.paypalClientId,
            currency: 'EUR'
          }}
        >
          <PayPalButtons
            onError={(e) => updateStatus(`Failed paypal payment: ${e}`)}
            createOrder={async () => {
              const response = await nodeApi.createOrder(
                {
                  guid: paypalGSSVehicleOffer?.checkout ?? '',
                  quantity: 1
                },
                session!,
                user!.system
              )
              return response.id
            }}
            onApprove={async (data) => {
              await nodeApi.capturePayment(data.orderID, session!, user!.system)
              updateStatus('complete')
            }}
          />
        </PayPalScriptProvider>
      </div>

      <p>Are you into NFTs?</p>
      <div className={styles.crypto}>
        {/* <a
          href={config.coinbaseCheckoutUrl(coinbaseCommerceGSSVehicle?.storeId ?? '')}
          target="_blank"
        >
          <Button variant="rectangle" size="medium">
            Buy with crypto
          </Button>
        </a> */}

        <a href={config.nftzCheckoutUrl(desoNftzAlphaShips?.checkoutLink ?? '')} target="_blank">
          <Button variant="rectangle" size="medium">
            Buy NFT
          </Button>
        </a>

        <Button variant="rectangle" size="medium" onClick={handleClaimNft}>
          Claim NFT
        </Button>
      </div>
    </>
  )
}
