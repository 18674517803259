import { animated } from '@react-spring/three'
import { Html } from '@react-three/drei'
import { Canvas } from '@react-three/fiber'
import { EffectComposer, HueSaturation } from '@react-three/postprocessing'
import React, { ReactNode, useState } from 'react'
import { Vector3, Quaternion } from 'three'
import { Slide } from './Slide'
import styles from './ElementCarousel.module.scss'
import { BlendFunction } from 'postprocessing'
import { FloatingGroup } from '../../../helpers/3d/FloatingGroup'
import { useCarousel } from '../../../helpers/useCarousel'
import clsx from 'clsx'
import { Element, ElementBody } from '../../../../domain/state/Element'
import { BodyOfElements } from '../../../parts/elements/BodyOfElements'
import { Spinner } from '../../controls/spinner/Spinner'
import { RotatingGroup } from '../../../helpers/3d/RotatingGroup'
import { ElementController } from '../../../parts/elements/ElementController'
import * as THREE from 'three'
import { Shape } from '../../../parts/elements/Shape'
import { useSurfaceData } from '../../../helpers/session/useSurfaceData'

export interface RenderOverlayProps {
  id: string
  isActive: boolean
  element: Element
  index: number
  overlayProps: {
    onClick: () => void
    onMouseEnter: () => void
    onMouseLeave: () => void
  }
}

export interface ElementCarouselProps {
  elements: Element[]
  size: number
  overlayOnTop?: boolean
  cameraDistance?: number
  slideOffset?: number
  overflowCount?: number
  modelPosition?: Vector3
  isFloating?: boolean
  isRotating?: boolean
  height: number
  prefix: string
  className?: string
  renderHtmlOverlay: (args: RenderOverlayProps) => ReactNode
}

export const ElementCarousel = ({
  elements,
  size = 1,
  renderHtmlOverlay,
  overlayOnTop = false,
  cameraDistance = 75,
  slideOffset = 1.2,
  overflowCount = 2,
  isFloating = false,
  isRotating = false,
  modelPosition,
  className,
  height,
  prefix
}: ElementCarouselProps) => {
  const surfaces = useSurfaceData()
  const [activeIndex, setActiveIndex] = useState(1)
  const [hoveredIndex, setHoveredIndex] = useState<number>()

  const { position, slides, slideDistance } = useCarousel({
    items: elements.map((data, id) => ({ data, id })),
    activeIndex,
    slideOffset,
    overflowCount
  })

  if (elements.length == 0)
    return (
      <div
        className={styles.bodyCarouselPlaceholder}
        style={{ touchAction: 'none', height: `${height}px`, width: '100%' }}
      >
        <Spinner visible={true} />
      </div>
    )

  const elementRotation = new THREE.Quaternion().identity()
  const elementPosition = new THREE.Vector3()

  return (
    <div className={clsx(className, styles.bodyCarousel)}>
      <Canvas
        className={clsx({ [styles.bottomOverlayContainer]: !overlayOnTop })}
        dpr={[1,2]}
        shadows="basic"
        camera={{ position: [0, 0, cameraDistance], fov: 1 }}
        style={{ touchAction: 'none', height: `${height}px`, width: '100%' }}
        gl={{ localClippingEnabled: true, alpha: true }}
        resize={{ scroll: false, offsetSize: true }}
      >
        <ambientLight intensity={Math.PI / 8} />

        <EffectComposer>
          <HueSaturation blendFunction={BlendFunction.NORMAL} hue={0} saturation={0} />
        </EffectComposer>

        <animated.group position={position.to((x, y, z) => [x, y, z])}>
          {slides.map(({ data, index }) => {
            const isActive = activeIndex === index
            const surface = surfaces(data.data.shapeId)
            return (
              <Slide
                key={`${prefix}-${index}`}
                position={new Vector3((index - 1) * slideDistance, 0, 0)}
                isActive={isActive}
                isHovered={hoveredIndex === index}
              >
                <FloatingGroup paused={!isActive || !isFloating}>
                  <RotatingGroup paused={!isActive || !isRotating}>
                    <group rotation={[0.4, -0.1 * (index - activeIndex), 0]}>
                      <Shape id={surface.guid} scale={size} position={elementPosition} rotation={elementRotation} />
                    </group>
                  </RotatingGroup>
                </FloatingGroup>

                <Html zIndexRange={overlayOnTop ? undefined : [0, 0]}>
                  {renderHtmlOverlay({
                    id: data.id.toString(),
                    isActive,
                    element: data.data,
                    index,
                    overlayProps: {
                      onClick: () => setActiveIndex(index),
                      onMouseEnter: () => setHoveredIndex(index),
                      onMouseLeave: () => setHoveredIndex(undefined)
                    }
                  })}
                </Html>
              </Slide>
            )
          })}
        </animated.group>
      </Canvas>
    </div>
  )
}
