import { ElementBody } from './state/Element'
import * as THREE from 'three'
import { Orbit } from './state/movement/Orbit'
import { Expose, Transform, Type, plainToInstance } from 'class-transformer'
import { XYZ } from './Common'

export class Celestial {
  @Expose()
  id!: number

  @Expose()
  second!: number

  @Type(() => ElementBody)
  @Expose()
  body!: ElementBody

  @Expose()
  @Type(() => XYZ)
  @Transform(
    ({ value }) => (value ? new THREE.Vector3(value.x, value.y, value.z) : new THREE.Vector3()),
    { toClassOnly: true }
  )
  rotation!: THREE.Vector3

  @Expose()
  @Type(() => XYZ)
  @Transform(
    ({ value }) => (value ? new THREE.Vector3(value.x, value.y, value.z) : new THREE.Vector3()),
    { toClassOnly: true }
  )
  revolution!: THREE.Vector3

  @Expose()
  @Type(() => Orbit)
  orbit!: Orbit
}
