import React from 'react'
import styles from './FavoriteToggle.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart as faHeartSolid } from '@fortawesome/free-solid-svg-icons'
import { faHeart } from '@fortawesome/free-regular-svg-icons'
import clsx from 'clsx'

interface FavoriteToggleProps {
  isFavorite: boolean
  onToggle?: (isFavorite: boolean) => void
  className?: string
  visible?: boolean
}

export const FavoriteToggle = ({
  isFavorite,
  onToggle,
  className,
  visible
}: FavoriteToggleProps) => {
  if (!visible) return null
  return (
    <button
      className={clsx(styles.favoriteToggle, className)}
      onClick={() => onToggle?.(!isFavorite)}
    >
      <FontAwesomeIcon icon={isFavorite ? faHeartSolid : faHeart} size="xl" />
    </button>
  )
}
