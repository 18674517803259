import React, { forwardRef, ReactNode, useEffect, useRef, useState } from 'react'
import * as THREE from 'three'
import { ElementController } from '../elements/ElementController'
import { ECS, SimCS } from '../../../domain/systems/ECS'
import { With } from 'miniplex'


export const SimulatedEntity = (entity: With<SimCS, "state">) => {
  console.log
  return <ECS.Entity entity={entity}>
    <ECS.Component name="three">
      <group quaternion={entity.translation.rotation} position={entity.translation.position}>
        {entity.state.body.elements.map((element, index) => (
          <ElementController
            key={`ec-${index}`}
            parentScale={1}
            parentRotation={new THREE.Quaternion().identity()}
            parentPosition={new THREE.Vector3()}
            element={element}
          />
        ))}
      </group>
    </ECS.Component>
  </ECS.Entity>
}
