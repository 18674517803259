import { OrbitControls } from '@react-three/drei'
import { Bloom, EffectComposer, HueSaturation } from '@react-three/postprocessing'
import React, { useState } from 'react'
import { BlendFunction } from 'postprocessing'
import { ECS } from '../../../domain/systems/ECS'
import { BodyOfElements } from '../../parts/elements/BodyOfElements'
import TranslationSystem from '../../../domain/systems/TranslationSystem'
import { SessionContext } from '../../../utils/session/SessionProvider'
import { WorldSocketContext } from '../../../utils/session/WorldSocketProvider'
import Decimal from 'decimal.js'
import { useFrame } from '@react-three/fiber'
import { SimulatedEntity } from '../../parts/entities/SimulatedEntity'

// const statusColor = {
//   [ReadyState.CONNECTING]: theme.palette.primary,
//   [ReadyState.OPEN]: theme.palette.success,
//   [ReadyState.CLOSING]: theme.palette.secondary,
//   [ReadyState.CLOSED]: theme.palette.error,
//   [ReadyState.UNINSTANTIATED]: theme.palette.grey
// }[readyState]

// <Container
// width={16}
// height={16}
// alignSelf='center'
// borderRadius={8}
// marginBottom={4}
// backgroundColor={statusColor}
// ></Container>

export var targetSecond = new Decimal(0)
const entities = ECS.world.with("state")

export default function Simulator() {

  const { observer } = React.useContext(WorldSocketContext)

  useFrame((_, dt) => {
    targetSecond = new Decimal(Date.now())
  })
  
  const refDistance = (observer?.state.body.elements[0].height ?? 2) / 2

  return <>
    <OrbitControls
      autoRotate={false}
      minDistance={refDistance * 2}
      maxDistance={refDistance * 6}
      enableRotate={true}
      enablePan={true}
      enableZoom={true}
    />

    <ambientLight intensity={Math.PI / 4} />
    <pointLight decay={0} position={[1, 1, 1]} intensity={Math.PI} castShadow />

    <ECS.Entities in={entities} children={SimulatedEntity}/>

    <EffectComposer autoClear={false}>
      <Bloom mipmapBlur />
      <HueSaturation
        blendFunction={BlendFunction.NORMAL}
        hue={0} // hue in radians
        saturation={0} // saturation in radians
      />
    </EffectComposer>

    {observer && <TranslationSystem observer={observer.state} /> }
  </>
}
