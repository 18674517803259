/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.20.3
 * source: common.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as pb_1 from 'google-protobuf'
export namespace Astronation {
  export class BigDecimal extends pb_1.Message {
    #one_of_decls: number[][] = []
    constructor(
      data?:
        | any[]
        | {
            units?: number
            nanos?: number
          }
    ) {
      super()
      pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls)
      if (!Array.isArray(data) && typeof data == 'object') {
        if ('units' in data && data.units != undefined) {
          this.units = data.units
        }
        if ('nanos' in data && data.nanos != undefined) {
          this.nanos = data.nanos
        }
      }
    }
    get units() {
      return pb_1.Message.getFieldWithDefault(this, 1, 0) as number
    }
    set units(value: number) {
      pb_1.Message.setField(this, 1, value)
    }
    get nanos() {
      return pb_1.Message.getFieldWithDefault(this, 2, 0) as number
    }
    set nanos(value: number) {
      pb_1.Message.setField(this, 2, value)
    }
    static fromObject(data: { units?: number; nanos?: number }): BigDecimal {
      const message = new BigDecimal({})
      if (data.units != null) {
        message.units = data.units
      }
      if (data.nanos != null) {
        message.nanos = data.nanos
      }
      return message
    }
    toObject() {
      const data: {
        units?: number
        nanos?: number
      } = {}
      if (this.units != null) {
        data.units = this.units
      }
      if (this.nanos != null) {
        data.nanos = this.nanos
      }
      return data
    }
    serialize(): Uint8Array
    serialize(w: pb_1.BinaryWriter): void
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
      const writer = w || new pb_1.BinaryWriter()
      if (this.units != 0) writer.writeInt64(1, this.units)
      if (this.nanos != 0) writer.writeSfixed32(2, this.nanos)
      if (!w) return writer.getResultBuffer()
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): BigDecimal {
      const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes),
        message = new BigDecimal()
      while (reader.nextField()) {
        if (reader.isEndGroup()) break
        switch (reader.getFieldNumber()) {
          case 1:
            message.units = reader.readInt64()
            break
          case 2:
            message.nanos = reader.readSfixed32()
            break
          default:
            reader.skipField()
        }
      }
      return message
    }
    serializeBinary(): Uint8Array {
      return this.serialize()
    }
    static deserializeBinary(bytes: Uint8Array): BigDecimal {
      return BigDecimal.deserialize(bytes)
    }
  }
  export class Vector3D extends pb_1.Message {
    #one_of_decls: number[][] = []
    constructor(
      data?:
        | any[]
        | {
            x?: number
            y?: number
            z?: number
          }
    ) {
      super()
      pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls)
      if (!Array.isArray(data) && typeof data == 'object') {
        if ('x' in data && data.x != undefined) {
          this.x = data.x
        }
        if ('y' in data && data.y != undefined) {
          this.y = data.y
        }
        if ('z' in data && data.z != undefined) {
          this.z = data.z
        }
      }
    }
    get x() {
      return pb_1.Message.getFieldWithDefault(this, 1, 0) as number
    }
    set x(value: number) {
      pb_1.Message.setField(this, 1, value)
    }
    get y() {
      return pb_1.Message.getFieldWithDefault(this, 2, 0) as number
    }
    set y(value: number) {
      pb_1.Message.setField(this, 2, value)
    }
    get z() {
      return pb_1.Message.getFieldWithDefault(this, 3, 0) as number
    }
    set z(value: number) {
      pb_1.Message.setField(this, 3, value)
    }
    static fromObject(data: { x?: number; y?: number; z?: number }): Vector3D {
      const message = new Vector3D({})
      if (data.x != null) {
        message.x = data.x
      }
      if (data.y != null) {
        message.y = data.y
      }
      if (data.z != null) {
        message.z = data.z
      }
      return message
    }
    toObject() {
      const data: {
        x?: number
        y?: number
        z?: number
      } = {}
      if (this.x != null) {
        data.x = this.x
      }
      if (this.y != null) {
        data.y = this.y
      }
      if (this.z != null) {
        data.z = this.z
      }
      return data
    }
    serialize(): Uint8Array
    serialize(w: pb_1.BinaryWriter): void
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
      const writer = w || new pb_1.BinaryWriter()
      if (this.x != 0) writer.writeDouble(1, this.x)
      if (this.y != 0) writer.writeDouble(2, this.y)
      if (this.z != 0) writer.writeDouble(3, this.z)
      if (!w) return writer.getResultBuffer()
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Vector3D {
      const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes),
        message = new Vector3D()
      while (reader.nextField()) {
        if (reader.isEndGroup()) break
        switch (reader.getFieldNumber()) {
          case 1:
            message.x = reader.readDouble()
            break
          case 2:
            message.y = reader.readDouble()
            break
          case 3:
            message.z = reader.readDouble()
            break
          default:
            reader.skipField()
        }
      }
      return message
    }
    serializeBinary(): Uint8Array {
      return this.serialize()
    }
    static deserializeBinary(bytes: Uint8Array): Vector3D {
      return Vector3D.deserialize(bytes)
    }
  }
  export class Vector3BD extends pb_1.Message {
    #one_of_decls: number[][] = []
    constructor(
      data?:
        | any[]
        | {
            x?: BigDecimal
            y?: BigDecimal
            z?: BigDecimal
          }
    ) {
      super()
      pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls)
      if (!Array.isArray(data) && typeof data == 'object') {
        if ('x' in data && data.x != undefined) {
          this.x = data.x
        }
        if ('y' in data && data.y != undefined) {
          this.y = data.y
        }
        if ('z' in data && data.z != undefined) {
          this.z = data.z
        }
      }
    }
    get x() {
      return pb_1.Message.getWrapperField(this, BigDecimal, 1) as BigDecimal
    }
    set x(value: BigDecimal) {
      pb_1.Message.setWrapperField(this, 1, value)
    }
    get has_x() {
      return pb_1.Message.getField(this, 1) != null
    }
    get y() {
      return pb_1.Message.getWrapperField(this, BigDecimal, 2) as BigDecimal
    }
    set y(value: BigDecimal) {
      pb_1.Message.setWrapperField(this, 2, value)
    }
    get has_y() {
      return pb_1.Message.getField(this, 2) != null
    }
    get z() {
      return pb_1.Message.getWrapperField(this, BigDecimal, 3) as BigDecimal
    }
    set z(value: BigDecimal) {
      pb_1.Message.setWrapperField(this, 3, value)
    }
    get has_z() {
      return pb_1.Message.getField(this, 3) != null
    }
    static fromObject(data: {
      x?: ReturnType<typeof BigDecimal.prototype.toObject>
      y?: ReturnType<typeof BigDecimal.prototype.toObject>
      z?: ReturnType<typeof BigDecimal.prototype.toObject>
    }): Vector3BD {
      const message = new Vector3BD({})
      if (data.x != null) {
        message.x = BigDecimal.fromObject(data.x)
      }
      if (data.y != null) {
        message.y = BigDecimal.fromObject(data.y)
      }
      if (data.z != null) {
        message.z = BigDecimal.fromObject(data.z)
      }
      return message
    }
    toObject() {
      const data: {
        x?: ReturnType<typeof BigDecimal.prototype.toObject>
        y?: ReturnType<typeof BigDecimal.prototype.toObject>
        z?: ReturnType<typeof BigDecimal.prototype.toObject>
      } = {}
      if (this.x != null) {
        data.x = this.x.toObject()
      }
      if (this.y != null) {
        data.y = this.y.toObject()
      }
      if (this.z != null) {
        data.z = this.z.toObject()
      }
      return data
    }
    serialize(): Uint8Array
    serialize(w: pb_1.BinaryWriter): void
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
      const writer = w || new pb_1.BinaryWriter()
      if (this.has_x) writer.writeMessage(1, this.x, () => this.x.serialize(writer))
      if (this.has_y) writer.writeMessage(2, this.y, () => this.y.serialize(writer))
      if (this.has_z) writer.writeMessage(3, this.z, () => this.z.serialize(writer))
      if (!w) return writer.getResultBuffer()
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Vector3BD {
      const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes),
        message = new Vector3BD()
      while (reader.nextField()) {
        if (reader.isEndGroup()) break
        switch (reader.getFieldNumber()) {
          case 1:
            reader.readMessage(message.x, () => (message.x = BigDecimal.deserialize(reader)))
            break
          case 2:
            reader.readMessage(message.y, () => (message.y = BigDecimal.deserialize(reader)))
            break
          case 3:
            reader.readMessage(message.z, () => (message.z = BigDecimal.deserialize(reader)))
            break
          default:
            reader.skipField()
        }
      }
      return message
    }
    serializeBinary(): Uint8Array {
      return this.serialize()
    }
    static deserializeBinary(bytes: Uint8Array): Vector3BD {
      return Vector3BD.deserialize(bytes)
    }
  }
  export class IntList extends pb_1.Message {
    #one_of_decls: number[][] = []
    constructor(
      data?:
        | any[]
        | {
            values?: number[]
          }
    ) {
      super()
      pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls)
      if (!Array.isArray(data) && typeof data == 'object') {
        if ('values' in data && data.values != undefined) {
          this.values = data.values
        }
      }
    }
    get values() {
      return pb_1.Message.getFieldWithDefault(this, 1, []) as number[]
    }
    set values(value: number[]) {
      pb_1.Message.setField(this, 1, value)
    }
    static fromObject(data: { values?: number[] }): IntList {
      const message = new IntList({})
      if (data.values != null) {
        message.values = data.values
      }
      return message
    }
    toObject() {
      const data: {
        values?: number[]
      } = {}
      if (this.values != null) {
        data.values = this.values
      }
      return data
    }
    serialize(): Uint8Array
    serialize(w: pb_1.BinaryWriter): void
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
      const writer = w || new pb_1.BinaryWriter()
      if (this.values.length) writer.writePackedInt32(1, this.values)
      if (!w) return writer.getResultBuffer()
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): IntList {
      const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes),
        message = new IntList()
      while (reader.nextField()) {
        if (reader.isEndGroup()) break
        switch (reader.getFieldNumber()) {
          case 1:
            message.values = reader.readPackedInt32()
            break
          default:
            reader.skipField()
        }
      }
      return message
    }
    serializeBinary(): Uint8Array {
      return this.serialize()
    }
    static deserializeBinary(bytes: Uint8Array): IntList {
      return IntList.deserialize(bytes)
    }
  }
  export class NestedIntList extends pb_1.Message {
    #one_of_decls: number[][] = []
    constructor(
      data?:
        | any[]
        | {
            values?: IntList[]
          }
    ) {
      super()
      pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls)
      if (!Array.isArray(data) && typeof data == 'object') {
        if ('values' in data && data.values != undefined) {
          this.values = data.values
        }
      }
    }
    get values() {
      return pb_1.Message.getRepeatedWrapperField(this, IntList, 1) as IntList[]
    }
    set values(value: IntList[]) {
      pb_1.Message.setRepeatedWrapperField(this, 1, value)
    }
    static fromObject(data: { values?: ReturnType<typeof IntList.prototype.toObject>[] }): NestedIntList {
      const message = new NestedIntList({})
      if (data.values != null) {
        message.values = data.values.map((item) => IntList.fromObject(item))
      }
      return message
    }
    toObject() {
      const data: {
        values?: ReturnType<typeof IntList.prototype.toObject>[]
      } = {}
      if (this.values != null) {
        data.values = this.values.map((item: IntList) => item.toObject())
      }
      return data
    }
    serialize(): Uint8Array
    serialize(w: pb_1.BinaryWriter): void
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
      const writer = w || new pb_1.BinaryWriter()
      if (this.values.length) writer.writeRepeatedMessage(1, this.values, (item: IntList) => item.serialize(writer))
      if (!w) return writer.getResultBuffer()
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): NestedIntList {
      const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes),
        message = new NestedIntList()
      while (reader.nextField()) {
        if (reader.isEndGroup()) break
        switch (reader.getFieldNumber()) {
          case 1:
            reader.readMessage(message.values, () =>
              pb_1.Message.addToRepeatedWrapperField(message, 1, IntList.deserialize(reader), IntList)
            )
            break
          default:
            reader.skipField()
        }
      }
      return message
    }
    serializeBinary(): Uint8Array {
      return this.serialize()
    }
    static deserializeBinary(bytes: Uint8Array): NestedIntList {
      return NestedIntList.deserialize(bytes)
    }
  }
  export class StringList extends pb_1.Message {
    #one_of_decls: number[][] = []
    constructor(
      data?:
        | any[]
        | {
            values?: string[]
          }
    ) {
      super()
      pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls)
      if (!Array.isArray(data) && typeof data == 'object') {
        if ('values' in data && data.values != undefined) {
          this.values = data.values
        }
      }
    }
    get values() {
      return pb_1.Message.getFieldWithDefault(this, 1, []) as string[]
    }
    set values(value: string[]) {
      pb_1.Message.setField(this, 1, value)
    }
    static fromObject(data: { values?: string[] }): StringList {
      const message = new StringList({})
      if (data.values != null) {
        message.values = data.values
      }
      return message
    }
    toObject() {
      const data: {
        values?: string[]
      } = {}
      if (this.values != null) {
        data.values = this.values
      }
      return data
    }
    serialize(): Uint8Array
    serialize(w: pb_1.BinaryWriter): void
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
      const writer = w || new pb_1.BinaryWriter()
      if (this.values.length) writer.writeRepeatedString(1, this.values)
      if (!w) return writer.getResultBuffer()
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): StringList {
      const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes),
        message = new StringList()
      while (reader.nextField()) {
        if (reader.isEndGroup()) break
        switch (reader.getFieldNumber()) {
          case 1:
            pb_1.Message.addToRepeatedField(message, 1, reader.readString())
            break
          default:
            reader.skipField()
        }
      }
      return message
    }
    serializeBinary(): Uint8Array {
      return this.serialize()
    }
    static deserializeBinary(bytes: Uint8Array): StringList {
      return StringList.deserialize(bytes)
    }
  }
}
