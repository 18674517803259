export const labelForVehicleKind = (kind?: number) => {
  switch (kind) {
    case 0:
      return 'Universal'
    case 1:
      return 'Vanity'
    case 2:
      return 'Cargo'
    case 3:
      return 'Armored'
    case 4:
      return 'Passenger'
    case 5:
      return 'Bomber'
    case 6:
      return 'Interceptor'
    case 7:
      return 'Destroyer'
    case 8:
      return 'Cruiser'
    case 9:
      return 'Research'
    case 10:
      return 'Scout'
    case 11:
      return 'Expedition'
    case 12:
      return 'Recovery'
    case 13:
      return 'Technical'
    case 14:
      return 'Medical'
    case 15:
      return 'Mining'
    case 16:
      return 'Construction'
    case 17:
      return 'Farming'
    case 18:
      return 'Rescue'
    default:
      return 'Other'
  }
}

export const labelForItemKind = (kind?: number) => {
  switch (kind) {
    case 0:
      return 'Universal'
    case 1:
      return 'Structural'
    case 2:
      return 'Anchor'
    case 3:
      return 'Chassis'
    case 4:
      return 'Construction'
    case 5:
      return 'Parking'
    case 6:
      return 'Service'
    case 7:
      return 'Weapon'
    case 8:
      return 'Shield'
    case 9:
      return 'Research'
    case 10:
      return 'Generator'
    case 11:
      return 'Habitat'
    case 12:
      return 'Support'
    case 13:
      return 'Transport'
    case 14:
      return 'Thruster'
    case 15:
      return 'Antenna'
    case 16:
      return 'Radar'
    case 17:
      return 'Beacon'
    case 18:
      return 'Radio'
    case 18:
      return 'Radio'
    case 19:
      return 'Telecom'
    case 20:
      return 'Trade'
    case 21:
      return 'Warehouse'
    case 22:
      return 'Vanity'
    case 23:
      return 'Display'
    default:
      return 'Other'
  }
}

export const labelForEntityClass = (kind?: number) => {
  switch (kind) {
    case 0:
      return 'Item'
    case 1:
      return 'Vehicle'
    case 2:
      return 'Building'
    case 3:
      return 'Celestial'
    case 4:
      return 'Rock'
    case 5:
      return 'Alien'
    case 6:
      return 'Astroid'
    default:
      return 'Unknown'
  }
}
