import { State } from '../domain/state/State'
import { config } from '../config'
import { SessionData, UserData } from '../utils/session/SessionProvider'
import axios from 'axios'
import { Celestial } from '../domain/Celestials'
import { plainToInstance } from 'class-transformer'
import { SurfaceData, SurfacesResponse } from '../domain/Surfaces'
import { ElementBody } from '../domain/state/Element'

interface AuthTokenResponse {
  token: string
}

interface CreateOrderPayload {
  guid: string
  quantity: number
}

interface CreateOrderResponse {
  id: string
}

interface StationsResponse {
  favorite: string[]
  known: string[]
  managed: string[]
  owned: string[]
}

interface UserStats {
  userId: number
  kills: number
  reputation: number
  experience: number
}

export interface StoreOffer {
  guid: string
  name: string
  checkout: string
  checkoutLink: string
  info: string
  kind: number
  price: number
  priceString: string
  promo: string
  storeId: string
}

type StoreOffersResponse = StoreOffer[]

class NodeApi {
  private nodeUrl = (path: string, system?: string) => `${config.nodeUrl(system)}/v4/${path}`
  private urls = {
    surfaces: (s?: string) => this.nodeUrl('surfaces', s),
    celestials: (s?: string) => this.nodeUrl('celestials', s),
    stations: '',
    entity: {
      state: (guid: string, s?: string) => this.nodeUrl(`entity/${guid}`, s),
      reconfigure: ''
    },
    store: {
      checkout: (id: string, s?: string) => this.nodeUrl(`store/checkout/${id}`, s),
      vehicles: (s?: string) => this.nodeUrl('store/vehicles', s),
      skies: (s?: string) => this.nodeUrl('store/skies', s)
    },
    auth: {
      token: (s?: string) => this.nodeUrl('auth/token', s),
      validate: (s?: string) => this.nodeUrl('auth/validate', s),
      renew: (s?: string) => this.nodeUrl('auth/renew', s)
    },
    deso: {
      claimNft: (id: string, s?: string) => this.nodeUrl('deso/claim-nft', s)
    },
    paypal: {
      order: (s?: string) => `https://${config.node(s)}/paypal/order`,
      payment: (s?: string) => `https://${config.node(s)}/paypal/payment`
    },
    user: {
      data: (s?: string) => this.nodeUrl('user/data', s),
      stats: (s?: string) => this.nodeUrl('user/stats', s),
      vehicles: (s?: string) => this.nodeUrl('user/vehicles', s),

      onboard: (s?: string) => this.nodeUrl('user/entity', s),
      stations: (s?: string) => this.nodeUrl('user/stations', s)
    }
  }

  public async createOrder(
    data: CreateOrderPayload,
    jwt: string,
    system: string
  ): Promise<CreateOrderResponse> {
    const response = await axios({
      method: 'POST',
      url: this.urls.paypal.order(system),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${jwt}`
      },
      data
    })

    if ((response.status = 200)) {
      return response.data as CreateOrderResponse
    } else {
      throw new Error('Failed to create order')
    }
  }

  public async getUserVehicles(jwt: string, system: string): Promise<string[]> {
    const response = await axios({
      method: 'GET',
      url: this.urls.user.vehicles(system),
      headers: {
        'Content-Type': 'text/plain',
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((response) => {
        if (response.status == 200) {
          const data = response.data as { spaceships: string[] }
          return data.spaceships
        } else return []
      })
      .catch((reason) => {
        console.error('Failed to fetch user data', reason)
        return []
      })

    return response
  }

  public async getUserStations(jwt: string, system: string): Promise<StationsResponse | void> {
    const response = await axios({
      method: 'GET',
      url: this.urls.user.stations(system),
      headers: {
        'Content-Type': 'text/plain',
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((response) => {
        if (response.status == 200) {
          return response.data as StationsResponse
        } else return undefined
      })
      .catch((reason) => {
        console.error('Failed to fetch user data', reason)
        return undefined
      })

    return response
  }

  public async getUserData(jwt: string, system: string): Promise<UserData | undefined> {
    const response = await axios({
      method: 'GET',
      url: this.urls.user.data(system),
      headers: {
        'Content-Type': 'text/plain',
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((response) => {
        if (response.status == 200) return response.data as UserData
        else return undefined
      })
      .catch((reason) => {
        console.error('Failed to fetch user data', reason)
        return undefined
      })

    return response
  }

  public async getUserStats(jwt: string, system: string): Promise<UserStats | undefined> {
    const response = await axios({
      method: 'GET',
      url: this.urls.user.stats(system),
      headers: {
        'Content-Type': 'text/plain',
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((response) => {
        if (response.status == 200) return response.data as UserStats
        else return undefined
      })
      .catch((reason) => {
        console.error('Failed to fetch user data', reason)
        return undefined
      })

    return response
  }

  public async capturePayment(orderId: string, jwt: string, system: string): Promise<void> {
    const response = await axios({
      method: 'POST',
      url: this.urls.paypal.payment(system),
      headers: {
        'Content-Type': 'text/plain',
        Authorization: `Bearer ${jwt}`
      },
      data: orderId
    })

    if (response.status != 200) {
      throw new Error('Failed to approve payment')
    }
  }

  public async claimNft(nftId: string, jwt: string, system: string): Promise<void> {
    const response = await axios({
      method: 'GET',
      url: this.urls.deso.claimNft(nftId, system),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`
      }
    })

    if (response.status != 200) {
      throw new Error('Failed to claim NFT')
    }
  }

  public async getAuthToken(credentials: {
    kind: number
    data: string
  }): Promise<AuthTokenResponse | undefined> {
    const result = axios({
      method: 'POST',
      url: this.urls.auth.token(),
      headers: { 'Content-Type': 'application/json' },
      data: credentials
    })
      .then((response) => {
        if (response.status == 200) return response.data as AuthTokenResponse
        else return undefined
      })
      .catch((reason) => {
        console.error('Failed sending auth code', reason)
        return undefined
      })

    return result
  }

  public async validateAuthToken(credentials: {
    kind: number
    token: string
    code: string
  }): Promise<AuthTokenResponse | undefined> {
    const result = axios({
      method: 'POST',
      url: this.urls.auth.validate(),
      headers: { 'Content-Type': 'application/json' },
      data: credentials
    })
      .then((response) => {
        if (response.status == 200) {
          return response.data as AuthTokenResponse
        } else return undefined
      })
      .catch((reason) => {
        console.error('Failed checking auth code', reason)
        return undefined
      })

    return result
  }

  public async renew(jwt: string, system?: string): Promise<AuthTokenResponse | undefined> {
    const result = axios({
      method: 'GET',
      url: this.urls.auth.renew(system),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((response) => {
        if ((response.status = 200)) {
          return response.data as AuthTokenResponse
        } else return undefined
      })
      .catch((reason) => {
        console.error('Failed renewing JWT', reason)
        return undefined
      })

    return result
  }

  public async getEntity(guid: string, system?: string): Promise<State | undefined> {
    const result = axios({
      method: 'GET',
      url: this.urls.entity.state(guid, system),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        if ((response.status = 200)) {
          return response.data as State
        } else return undefined
      })
      .catch((reason) => {
        console.error('Failed fetching entity state', reason)
        return undefined
      })

    return result
  }

  public async getCelestials(session?: SessionData): Promise<Record<number, Celestial>> {
    const result = axios({
      method: 'GET',
      url: this.urls.celestials(session?.user.system),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        if ((response.status = 200)) {
          const { celestials } = response.data as { celestials: Celestial[] }
          const instances = plainToInstance(Celestial, celestials, {
            excludeExtraneousValues: true
          })
          let result: Record<number, Celestial> = {}
          for (const celestial of instances) {
            celestial.orbit.initialize()
            result[celestial.id] = celestial
          }
          return result
        } else return []
      })
      .catch((reason) => {
        console.error('Failed fetching star system', reason)
        return []
      })

    return result
  }

  public async getSurfaces(system: string): Promise<SurfacesResponse | undefined> {
    const result = axios({
      method: 'GET',
      url: this.urls.surfaces(system),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        if ((response.status = 200)) {
          const surfaces = response.data as SurfacesResponse
          return surfaces
        } else return undefined
      })
      .catch((reason) => {
        console.error('Failed fetching surface map', reason)
        return undefined
      })

    return result
  }

  public async getStoreVehicles(system: string): Promise<StoreOffersResponse | undefined> {
    const result = axios({
      method: 'GET',
      url: this.urls.store.vehicles(system),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        if ((response.status = 200)) {
          return response.data.data as StoreOffersResponse
        } else return undefined
      })
      .catch((reason) => {
        console.error('Failed fetching surface map', reason)
        return undefined
      })

    return result
  }

  public async getCheckout(id: string, system: string): Promise<ElementBody | undefined> {
    const result = axios({
      method: 'GET',
      url: this.urls.store.checkout(id, system),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        if ((response.status = 200)) {
          return response.data as ElementBody
        } else return undefined
      })
      .catch((reason) => {
        console.error('Failed fetching surface map', reason)
        return undefined
      })

    return result
  }

  public async onboardEntity(entityId: string, jwt: string, system: string): Promise<void> {
    const result = axios({
      method: 'POST',
      url: this.urls.user.onboard(system),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`
      },
      data: entityId
    })
      .then(() => {
        return undefined
      })
      .catch((reason) => {
        console.error('Failed fetching surface map', reason)
        return undefined
      })

    return result
  }
}

export const nodeApi = new NodeApi()
