import { useLocalStorage } from 'usehooks-ts'
import { Outlet } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { config } from '../../config'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../router'
import { SurfaceData, SurfaceMetadata } from '../../domain/Surfaces'
import { Celestial } from '../../domain/Celestials'
import { nodeApi } from '../../api/NodeApi'

export interface UserData {
  id: number
  guid: string
  system: string
  deso?: string
  nick?: string
  email: string
}

export interface SessionData {
  jwt: string
  user: UserData
}

export interface SessionCtx {
  loading: boolean
  identified: boolean
  session?: string
  surfaces?: Record<number, SurfaceData>
  surfaceMetadata?: Record<number, SurfaceMetadata>
  celestials?: Record<string, Celestial>

  user?: UserData

  openAuth?: () => void
  useAuth?: (jwt: string) => void
  closeAuth?: () => void
  logout?: () => void
}

export const SessionContext = React.createContext<SessionCtx>({ identified: false, loading: true })

export default function SessionProvider() {
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  const [session, setSession] = useLocalStorage<string | undefined>(
    config.sessionStorageKey,
    undefined
  )
  const [surfaces, setSurfaces] = useLocalStorage<Record<number, SurfaceData> | undefined>(
    config.surfacesCacheKey,
    undefined
  )
  const [surfaceMetadata, setSurfaceMetadata] = useLocalStorage<
    Record<number, SurfaceMetadata> | undefined
  >(config.surfacesMetadataCacheKey, undefined)
  const [celestials, setCelestials] = useState<Record<number, Celestial>>({})
  const [user, setUser] = useState<UserData | undefined>(undefined)

  const identified = session != undefined && session.length > 0

  useEffect(() => {
    const renewSession = async () => {
      setLoading(true)
      if (session) {
        try {
          const sessionData = await nodeApi.renew(session, 'pe31')
          if (sessionData) {
            useAuth(sessionData.token)
            await nodeApi.getUserData(sessionData.token, 'pe31').then(
              (data) => setUser(data),
              (reason) => console.error(`Failed fetching star system info`, reason)
            )
          } else {
            useAuth(undefined)
            setUser(undefined)
          }
        } catch (error) {
          console.warn('Failed to renew session.', error)
          useAuth(undefined)
        }
      }
      console.debug('Initializing session data')
      await nodeApi.getCelestials().then(
        (celestials) => setCelestials(celestials),
        (reason) => console.error(`Failed fetching star system info`, reason)
      )

      if (
        surfaces == undefined ||
        Object.keys(surfaces).length == 0 ||
        surfaceMetadata == undefined ||
        Object.keys(surfaceMetadata).length == 0
      ) {
        await nodeApi.getSurfaces(user?.system ?? 'pe31').then(
          (response) => {
            if (!response) {
              console.error(`Undefined response for surfaces`)
              return
            }
            const surfaces = response.known.reduce(
              (result: Record<number, SurfaceData>, item, index) => {
                result[item.id] = item
                return result
              },
              {}
            )
            setSurfaces(surfaces)

            const surfaceMeta = response.metadata.reduce(
              (result: Record<number, SurfaceMetadata>, item, index) => {
                result[item.surfaceId] = item
                return result
              },
              {}
            )
            setSurfaceMetadata(surfaceMeta)
          },
          (reason) => console.error(`Failed fetching base surfaces`, reason)
        )
      }

      setLoading(false)
    }

    renewSession()
  }, [session])

  const openAuth = () => navigate(routes.login)
  const useAuth = async (jwt: string | undefined) => {
    setSession(jwt)
  }
  const closeAuth = () => navigate(routes.vehicles)
  const logout = () => {
    navigate(routes.home)
    setSession(undefined)
  }

  const sessionCtx: SessionCtx = {
    identified,
    session,
    surfaces,
    surfaceMetadata,
    celestials,
    user,
    openAuth,
    useAuth,
    closeAuth,
    logout,
    loading
  }

  return (
    <SessionContext.Provider value={sessionCtx}>
      <Outlet />
    </SessionContext.Provider>
  )
}
