import React, { ReactNode, useRef, useState } from 'react'
import 'react-multi-carousel/lib/styles.css'
import { Group } from 'three'
import { useFrame } from '@react-three/fiber'

export const FloatingGroup = ({ paused, children }: { paused?: boolean; children: ReactNode }) => {
  const ref = useRef<Group>(null)
  const timeElapsedRef = useRef<number>(0)
  const [timeElapsedRandomOffset] = useState(Math.random() * 1000)

  const frequency = 1.2
  const amplitude = 0.01
  const rotationAmplitude = Math.PI / 150

  useFrame((_, delta) => {
    if (paused) return
    timeElapsedRef.current += delta

    const t = timeElapsedRef.current + timeElapsedRandomOffset

    const floatY = Math.sin(t * frequency) * amplitude

    const rotationX = Math.sin(t * frequency) * rotationAmplitude
    const rotationZ = Math.cos(t * frequency) * rotationAmplitude

    ref.current?.position.set(0, floatY, 0)
    ref.current?.rotation.set(rotationX, ref.current.rotation.y, rotationZ)
  })

  return <group ref={ref}>{children}</group>
}
