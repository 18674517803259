import { Canvas } from '@react-three/fiber'
import React from 'react'
import { Outlet } from 'react-router-dom'
import Skybox from '../skybox/Skybox'
import Overlay from './overlay/Overlay'
import styles from './Frame.module.scss'

export const Frame = () => (
  <>
    <Canvas
      className={styles.layout}
      dpr={[1,2]}
      camera={{ position: [0, 0, 1], fov: 92.5 }}
      style={{ touchAction: 'none' }}
      gl={{ localClippingEnabled: true }}
    >
      <Skybox />
    </Canvas>
    <Overlay>
      <Outlet />
    </Overlay>
  </>
)
