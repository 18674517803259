import React, { useRef } from 'react'
import { Vector3, Group } from 'three'
import { useFrame, useThree } from '@react-three/fiber'

interface MouseShakerProps {
  distance?: number
  children: React.ReactNode
}

const MouseShaker: React.FC<MouseShakerProps> = ({ distance = 0.02, children }) => {
  const ref = useRef<Group>(null!)
  const vec = new Vector3()
  const { pointer } = useThree()

  useFrame(() => {
    if (ref.current) {
      ref.current.position.lerp(vec.set(pointer.x * distance, pointer.y * distance, 0), 0.1)
    }
  })

  return <group ref={ref}>{children}</group>
}

export default MouseShaker
