import { clsx } from 'clsx'
import React, { SelectHTMLAttributes } from 'react'
import { InputHTMLAttributes, forwardRef } from 'react'
import styles from './Select.module.scss'

export interface CaptionWithValue {
  caption: string
  value: string
}

type SelectProps = SelectHTMLAttributes<HTMLSelectElement> & {
  label?: string
  error?: string | null
  selectClassname?: string
  options: CaptionWithValue[]
}

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  ({ label, error, className, selectClassname, options, ...selectProps }, ref) => {
    return (
      <div className={clsx([styles.select, className, { [styles.invalid]: error }])}>
        {label && <label htmlFor={label}>{label}</label>}
        <div>
          <select ref={ref} className={selectClassname} {...selectProps}>
            {options.map((o) => <option value={o.value}>{o.caption}</option>)}
          </select>
        </div>
        {error && <span className={styles.error}>{error}</span>}
      </div>
    )
  }
)

Select.displayName = Select.name
