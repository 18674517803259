import React, { useState } from 'react'
import { ScrollSection } from '../../layout/sections/scroll/ScrollSection'
import { Current } from './Current'
import { BodyContents } from './BodyContents'
import { useParams } from 'react-router-dom'
import { queryEntity } from '../../../api/queries'

export const Configure = () => {
  const params = useParams()
  if (!params.id) {
    console.log('Entity preview requires id to be provided')
  }

  const [ path, setPath ] = useState<number[]>([])

  const setCurrent = (index: number) => setPath(path.concat(index))

  const { data, isFetching } = queryEntity(params.id!)

  if (isFetching || !data) return <></>
  
  const onBackClick = () => setPath(path.slice(0, -1))

  return (
    <div className='container'>
      <ScrollSection>
        <Current
          entity={data}
          path={path}
          onBackClick={onBackClick}
        />
      </ScrollSection>
      <ScrollSection>
        <BodyContents
          path={path}
          body={data.body}
          onSetAsCurrent={setCurrent}
        />
      </ScrollSection>
    </div>
  )
}
