import clsx from 'clsx'
import React from 'react'
import styles from './PulseIcon.module.scss'

export const PulseIcon = ({ visible }: { visible?: boolean }) => {
  return (
    <div className={clsx(styles.pulseIcon, { [styles.visible]: visible })}>
      <span />
      <span />
    </div>
  )
}
