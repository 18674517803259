import React, { useRef, useState, useEffect } from 'react'
import { isRouteErrorResponse, Link, useRouteError } from 'react-router-dom'
import { Canvas, useFrame, ThreeElements } from '@react-three/fiber'
import { Text } from '@react-three/drei'
import * as THREE from 'three'
import { Frame } from '../../ui/layout/frame/Frame'
import { ScrollSection } from '../../ui/layout/sections/scroll/ScrollSection'
import Overlay from '../../ui/layout/frame/overlay/Overlay'
import { ErrorPage } from './ErrorPage'
import { routes } from '../../router'
import { Button } from '../../ui/layout/controls/button/Button'

function ErrorCube(props: ThreeElements['mesh']) {
  const meshRef = useRef<THREE.Mesh>(null!)
  const [hovered, setHover] = useState(false)

  useFrame((state, delta) => {
    meshRef.current.rotation.x += delta
    meshRef.current.rotation.y += delta
  })

  return (
    <mesh
      {...props}
      ref={meshRef}
      scale={hovered ? 1.1 : 1}
      onPointerOver={() => setHover(true)}
      onPointerOut={() => setHover(false)}
    >
      <boxGeometry args={[1, 1, 1]} />
      <meshStandardMaterial color={hovered ? 'hotpink' : 'orange'} />
    </mesh>
  )
}

function ErrorScene({ errorMessage }: { errorMessage: string }) {
  return (
    <Canvas
      dpr={[1,2]}
    >
      <ambientLight intensity={0.5} />
      <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
      <pointLight position={[-10, -10, -10]} />
      <ErrorCube position={[-1.2, 0, 0]} />
      <ErrorCube position={[1.2, 0, 0]} />
      <Text position={[0, 1.5, 0]} fontSize={0.5} color="white" anchorX="center" anchorY="middle">
        Error
      </Text>
      <Text
        position={[0, -1.5, 0]}
        fontSize={0.2}
        color="white"
        anchorX="center"
        anchorY="middle"
        maxWidth={5}
      >
        {errorMessage}
      </Text>
    </Canvas>
  )
}

export default function ErrorView() {
  const error = useRouteError()
  const [errorMessage, setErrorMessage] = useState<string>('Unknown error')

  useEffect(() => {
    if (isRouteErrorResponse(error)) {
      setErrorMessage(error.data?.message || error.statusText)
    } else if (error instanceof Error) {
      setErrorMessage(error.message)
    } else if (typeof error === 'string') {
      setErrorMessage(error)
    } else {
      console.error(error)
      setErrorMessage('Unknown error')
    }
  }, [error])

  return (
      <Overlay>
        <ErrorPage code='WTF' text={errorMessage}>
          <Link to={routes.home}>
            <Button variant='primary' size='large' >Open homepage</Button>
          </Link>
        </ErrorPage>
      </Overlay>
  )
}
