import React from 'react'
import clsx from 'clsx'
import { Button } from '../../layout/controls/button/Button'
import { PulseIcon } from '../../layout/controls/pulseIcon/PulseIcon'
import styles from './CheckoutsCarousel.module.scss'
import { routes } from '../../../router'
import { useNavigate } from 'react-router-dom'
import { BodyCarousel } from '../../layout/carousels/body/BodyCarousel'
import { queryCheckouts } from '../../../api/queries'
import { useSurfaceData } from '../../helpers/session/useSurfaceData'
import { labelForVehicleKind } from '../../helpers/labels'

export const CheckoutsCarousel = ({ ids }: { ids: string[] }) => {
  const navigate = useNavigate()
  const surfaces = useSurfaceData()

  const onViewDetails = (id: string) => navigate(routes.checkoutFor(id))

  const { data: checkouts, isFetching: pending } = queryCheckouts(ids)

  const bodies = pending ? [] : ids.map((id) => ({ id, data: checkouts[id] }))

  return (
      <BodyCarousel
        bodies={bodies}
        overlayOnTop={true}
        size={4.5}
        prefix="checkouts-carousel"
        slideOffset={1.5}
        overflowCount={2}
        isFloating={true}
        isRotating={false}
        height={400}
        className={styles.checkoutCarousel}
        renderHtmlOverlay={({ id, isActive, body, overlayProps }) => {
          if (!body) return <></>
          const shapeId = body.elements[0].shapeId
          const surface = surfaces(shapeId)

          return (
            <div
              className={clsx(styles.info, {
                [styles.active]: isActive
              })}
              {...overlayProps}
            >
              <PulseIcon visible={isActive} />

              <div className={styles.label}>
                <h3>{surface.name}</h3>
                <p>{labelForVehicleKind(surface.kind)}</p>
              </div>
              <div className={styles.line} />
              <div className={styles.link}>
                <Button variant="secondary" size="small" onClick={() => id && onViewDetails(id)}>
                  VIEW DETAILS
                </Button>
              </div>
            </div>
          )
        }}
      />
  )
}
