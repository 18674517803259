import React from 'react'
import { CurrentVehicle } from './CurrentVehicle'
import { OtherVehicles } from './OtherVehicles'
import { useCurrentEntity } from '../../helpers/session/useCurrentEntity'
import { ScrollSection } from '../../layout/sections/scroll/ScrollSection'
import { Spinner } from '../../layout/controls/spinner/Spinner'
import { useOwnedVehicles } from '../../helpers/session/useOwnedVehicles'

export const Vehicles = () => {
  const { currentEntityId, setCurrentEntity } = useCurrentEntity()
  const { vehicles, isLoading: allVehiclesLoading } = useOwnedVehicles()

  const current = vehicles.find(({ entityId }) => entityId === currentEntityId)

  const isLoading = !current || !currentEntityId || allVehiclesLoading

  const otherVisible = !isLoading && vehicles.length > 1

  return (
    <div className='container'>
      <Spinner visible={isLoading} />
      <ScrollSection>
        <CurrentVehicle
          visible={!isLoading}
          mainSurface={current?.mainSurface}
          body={current?.body}
          id={current?.entityId??''}
        />
      </ScrollSection>
      <ScrollSection>
        <OtherVehicles
          visible={otherVisible}
          currentEntityId={currentEntityId}
          onSetAsCurrent={setCurrentEntity}
          allVehicles={vehicles}
        />
      </ScrollSection>
    </div>
  )
}
