import React, { Suspense, useEffect } from 'react'
import { Button } from '../../layout/controls/button/Button'
import { Link } from 'react-router-dom'
import { routes } from '../../../router'
import { View } from '@react-three/drei'
import { FloatingGroup } from '../../helpers/3d/FloatingGroup'
import { Quaternion, Vector3 } from 'three'
import { ViewPortCanvas } from '../../layout/viewPortCanvas/ViewPortCanvas'
import { queryCheckouts, queryStoreVehicles } from '../../../api/queries'
import { BodyOfElements } from '../../parts/elements/BodyOfElements'
import { labelForVehicleKind } from '../../helpers/labels'
import { useSurfaceData } from '../../helpers/session/useSurfaceData'
import styles from './ShipsListing.module.scss'
import { ContentSection } from '../../layout/sections/content/ContentSection'

export const ShipsListing = () => {
  const surfaces = useSurfaceData()

  const { data: vehicles, isFetching: fetchingOffers } = queryStoreVehicles()
  const offers = vehicles?.filter((v) => v.kind == 1) ?? []
  const { data: bodies, isFetching: fetchingCheckouts } = queryCheckouts(
    offers.map((i) => i.checkout)
  )

  return (
    <div className="container">
      <ViewPortCanvas />
      <div className="container-grid">
        {offers?.map((offer) => {
          const body = bodies[offer.checkout]
          const surface = surfaces(body?.elements?.[0]?.shapeId ?? 0)
          const itemKind = labelForVehicleKind(surface.kind)
          return (
            <ContentSection
              title={surface.name}
              subtitle={itemKind}
              sidetitle={offer.priceString}
              key={`checkout-${offer.checkout}`}
            >
              <View className={styles.preview}>
                <ambientLight intensity={Math.PI / 6} />
                <directionalLight position={[10, 10, 10]} intensity={4} />

                <group rotation={[Math.PI / 6, Math.PI / 4, 0]}>
                  <FloatingGroup>
                    <BodyOfElements
                      body={body}
                      size={7}
                      rotation={new Quaternion().identity()}
                      position={new Vector3()}
                    />
                  </FloatingGroup>
                </group>
              </View>

              <p className={styles.description}>{offer.info}</p>

              <Link to={routes.checkoutFor(offer.checkout)}>
                <Button variant="rectangle" size="medium">
                  View details
                </Button>
              </Link>
            </ContentSection>
          )
        })}
      </div>
    </div>
  )
}
