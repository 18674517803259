import React from 'react'
import styles from './StatsBadge.module.scss'
import clsx from 'clsx'
import { statics } from '../../../api/Statics'

export const getLevelProgress = (experience: number) => {
  const currentLevel = statToLevel(experience)

  const getTargetExperienceForLevel = (level: number) => {
    if (level <= 0) return 0
    return Math.pow(2, level)
  }

  const nextLevel = currentLevel + 1
  const currentLevelExperience = getTargetExperienceForLevel(currentLevel)
  const nextLevelExperience = getTargetExperienceForLevel(nextLevel)

  return {
    min: currentLevelExperience,
    current: experience,
    max: nextLevelExperience,
    percentage:
      (experience - currentLevelExperience) / (nextLevelExperience - currentLevelExperience)
  }
}

export const statToLevel = (experience: number) => {
  const logArgument = Math.max(experience, 1)
  return Math.floor(Math.log2(logArgument))
}

export enum StatKind {
  Experience,
  Reputation,
  Kills
}

interface StatsBadgeProps {
  stat: number
  kind: StatKind
}

export const StatsBadge = ({ stat, kind }: StatsBadgeProps) => {
  const level = statToLevel(stat)

  const levelToBadge = (level: number) => {
    const badge = Array(5).fill(-1)
    const CONVERSION_BASE = 5

    let _level = level
    let index = 0
    while (_level > 0 && index < badge.length) {
      badge[index] = _level % CONVERSION_BASE
      _level = Math.floor(_level / CONVERSION_BASE)
      index++
    }

    return badge
  }

  const isNegative = level < 0

  const iconsSet =
    kind == StatKind.Experience
      ? statics.experienceIcon
      : isNegative
        ? statics.negativeRepIcon
        : statics.positiveRepIcon
  const filterClass =
    kind == StatKind.Experience ? 'exp-filter' : isNegative ? 'bad-rep-filter' : 'good-rep-filter'

  const iconLevel = levelToBadge(Math.abs(level))

  return (
    <div className={clsx([styles.statsBadge, filterClass])}>
      <img
        src={iconsSet(Math.max(0, iconLevel[0]) + 1)}
        className={clsx({ [styles.dimmed]: iconLevel[0] < 0 })}
      />
      <img
        src={iconsSet(Math.max(0, iconLevel[1]) + 1)}
        className={clsx({ [styles.dimmed]: iconLevel[1] < 0 })}
      />
      <img
        src={iconsSet(Math.max(0, iconLevel[2]) + 1)}
        className={clsx({ [styles.dimmed]: iconLevel[2] < 0 })}
      />
      <img
        src={iconsSet(Math.max(0, iconLevel[3]) + 1)}
        className={clsx({ [styles.dimmed]: iconLevel[3] < 0 })}
      />
      <img
        src={iconsSet(Math.max(0, iconLevel[4]) + 1)}
        className={clsx({ [styles.dimmed]: iconLevel[4] < 0 })}
      />
    </div>
  )
}
