import { Container } from '@react-three/uikit'
import React from 'react'

export default function TopRow({ children }: { children: React.ReactNode }) {
  return (
    <Container marginTop={-432}>
      <Container justifyContent="center" overflow="hidden" flexDirection="column">
        {children}
      </Container>
    </Container>
  )
}
