import React, { useEffect, useState } from 'react'
import { useWindowSize } from 'usehooks-ts'
import { SessionContext } from '../../../../utils/session/SessionProvider'

interface OverlayShapeOptions {
  isMenuOpen: boolean
}

export const useOverlayShape = ({ isMenuOpen }: OverlayShapeOptions) => {
  const sideBarHeight = 100
  const bottomBarWidth = 100

  const { identified } = React.useContext(SessionContext)
  const { width = 0, height = 0 } = useWindowSize()
  const [bgWidth, setBgWidth] = useState(0)
  const [bgHeight, setBgHeight] = useState(0)
  const [path, setPath] = useState('')
  const isNarrow = width < 930

  useEffect(() => {
    const frameWidth = Math.max(620, Math.min(1372, width - 52))
    setBgWidth(frameWidth + 20)
    const frameHeight = Math.max(280, height - 22)
    setBgHeight(frameHeight)

    const menuWidth = isMenuOpen ? (identified ? 380 : 180) : 80
    const logoWidth = isNarrow ? 12 : 160
    const handyWidth = 110
    const topCenterOffset = isNarrow ? 62 : -12

    const widthOffset = Math.max(0, (frameWidth - logoWidth - menuWidth - handyWidth - 3 * 58) / 2)
    const heightOffset = Math.max(0, (frameHeight - sideBarHeight - 120) / 2)

    const path = `
      M5.8 ${heightOffset + sideBarHeight + 89.1} 
      L5.2 ${heightOffset + sideBarHeight + 88.3} l.6.8 l5.5-3.8 
      A10 10 0 0017 ${heightOffset + sideBarHeight + 74.6}
      V${heightOffset + 76.3}
      c0-4.4-2.2-8.4-5.8-10.8 l-5.3-3.5 
      A10 10 0 011 ${heightOffset + 52.8}
      V51
      c0-6 5-11 11-11
      h${logoWidth}
      a10 10 0 0010.5-6 
      l13-20 
      l.8.6 l-.8-.6 
      a10 10 0 0110-5.6
      h${widthOffset + topCenterOffset}
      a10 10 0 0110 5.6
      L${logoWidth + widthOffset + 68 + topCenterOffset} 34.6
      a10 10 0 0010.5 5.4
      h${menuWidth}
      a10 10 0 0010.5-5.4
      l13-20
      a10 10 0 0110-5.6
      H${frameWidth - handyWidth + topCenterOffset}
      a10 10 0 0110 5.6
      l13 20
      a10 10 0 0010.5 5.4
      H${frameWidth}
      c6 0 11 5 11 11
      v${heightOffset + 2}
      c0 3.9-2 7.4-5.3 9.4 l-4.5 2.7
      c-3.9 2.4-6.2 6.6-6.2 11.1
      v${sideBarHeight}
      c0 4.4 2.2 8.5 5.9 10.9 l5.1 3.3 c3.1 2 5 5.5 5 9.2
      v${heightOffset + 2}
      c0 6-5 11-11 11
      H${24 + frameWidth / 2 + (bottomBarWidth + 40) / 2}
      a10 10 0 01-9.5-5.4 
      l-12-21
      a10 10 0 00-11.3-6.4
      H${28 + frameWidth / 2 - (bottomBarWidth + 40) / 2}
      a10 10 0 00-11.2 6.4 
      l-12 21
      a10 10 0 01-9.5 5.4
      H12 c-6 0-11-4.9-11-11
      V${heightOffset + sideBarHeight + 98.2}
      c0-3.6 1.8-7 4.8-9.1z
    `
    setPath(path)
  }, [width, height, isMenuOpen, identified])

  return {
    path,
    width: bgWidth,
    height: bgHeight,
    isNarrow,
    pathStyle: {
      transition: 'd 200ms ease-out'
    }
  }
}
