import React from 'react'
import { config } from '../../../config'
import { FooterSection } from '../../layout/sections/footer/FooterSection'
import { ScrollSection } from '../../layout/sections/scroll/ScrollSection'

export const TermsOfService = () => {
  const logoHeaderUrl = config.image('logo/a-logo-header.png')
  return (
    <div className="container-text">
      <ScrollSection>
        <img src={logoHeaderUrl} alt="Astronation" className="logo" />
        <h1>Terms and conditions</h1>
        <p>
          By accessing Astronation application, or website, you are agreeing to be bound by these
          Terms and Conditions of Use, all applicable laws and regulations, and agree that you are
          responsible for compliance with any applicable local laws. If you do not agree with any of
          these terms, you are prohibited from using Astronation’s application or accessing its
          website. Please close the website or exit and uninstall the application immediately if you
          disagree with these terms.
        </p>
        <p>
          The materials contained on, in, or available through the application or website (excluding
          third party resources) are owned by or licensed to the Astronation Sp. z o.o. and are
          protected by applicable copyright and trademark laws. All rights are reserved.
        </p>
      </ScrollSection>

      <ScrollSection>
        <p>
          The materials, applications, and websites of Astronation are provided on an “as is” basis.
          Astronation Sp. z o.o. makes no warranties, expressed or implied, and hereby disclaims and
          negates all other warranties, including without limitation, implied warranties or
          conditions of merchantability, fitness for a particular purpose, or non-infringement of
          intellectual property or other violation of rights.
          <br />
          Furthermore, Astronation Sp. z o.o. does not warrant or make any representations
          concerning the accuracy, likely results, or reliability of the use of the materials or
          applications or otherwise relating to such materials or on any sites linked to this site
          or application. Astronation Sp. z o.o. is not liable for any direct or indirect damages,
          including but not limited to, damages for personal injury, damages to any equipment or
          lost data, related to your use of the Astronation application or website.
        </p>
        <p>
          Astronation Sp. z o.o. supplies the application and the website for personal and private
          use only.
          <br />
          The Astronation application or website may provide links to, or embed, third party
          applications or websites. This Agreement does not apply to those third party resources. If
          you choose to enter or use mentioned third party resources, you agree that Astronation Sp.
          z o.o. is not responsible for the availability of such resources and do not review or
          endorse and shall not be liable, directly or indirectly, for how these third party
          resources treat your personally identifiable information due to the nature of the third
          party’s resources and content or the use that others make of these third party resources.
        </p>

        <h2>Seizure warning</h2>
        <p>
          Using virtual reality device may expose its’ user to certain light patterns or flashing
          lights. This may induce epileptic seizure in the user, even if there is no history of
          prior such occurrences. Users of virtual reality devices should review the health and
          safety warnings issued by their respective manufacturers.
        </p>
        <h2>Internet access fees and services</h2>
        <p>
          To access and use this application or website, you need to have internet access and pay
          any of the service fees associated with such access.
        </p>
      </ScrollSection>

      <ScrollSection>
        <h2>License</h2>
        <p>
          Astronation Sp. z o.o. grants you a non-exclusive, territorially unlimited license to use
          the Application or the website for the duration of the Agreement. You cannot sub-license
          and the License cannot be transferred to another entity without the explicit, written
          consent of the Astronation Sp. z o.o.. Specifically, you cannot rent, lease, sell, assign,
          reverse engineer, disassemble, modify or transfer the application or the website. You
          cannot download the application or access the website in violation of any applicable laws
          or regulations.
        </p>
        <p>
          This license shall automatically terminate if you violate any of these restrictions and
          may be terminated by Astronation Sp. z o.o. at any time. Upon termination this application
          must not be used and all copies must be permanently uninstalled. Violation of the license
          restrictions may lead to further civil or criminal legal action being brought against you
          by Astronation Sp. z o.o. and/or Astronation’s third party licensors.
        </p>
        <h2>Support</h2>
        <p>
          Astronation Sp. z o.o. does not take any obligation to provide technical support or
          maintenance for this application or the website. As mentioned, this application is
          provided on an “as is” and “as available” basis. Astronation Sp. z o.o. does not make any
          warranty that the application will meet your requirements or that the use of the
          application will be uninterrupted, timely, secure or error free. You expressly agree that
          the use of the Astronation application or the website is at your sole risk.
        </p>
      </ScrollSection>
      <FooterSection />
    </div>
  )
}
