import { useContext } from 'react'
import { SessionContext } from '../../../utils/session/SessionProvider'
import { SurfaceData, SurfaceMetadata } from '../../../domain/Surfaces'

export type SurfaceDataWithMeta = SurfaceData & { meta?: SurfaceMetadata }

export const useSurfaceData = () => {
  const { surfaceMetadata, surfaces } = useContext(SessionContext)

  return (shapeId: number) => ({
    ...(surfaces?.[shapeId] as SurfaceData),
    meta: surfaceMetadata?.[shapeId] as SurfaceMetadata
  })
}
