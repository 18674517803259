import 'reflect-metadata'
import './styles/style.scss'

import React from 'react'
import ReactDOM from 'react-dom/client'

import ErrorBoundary from './utils/errors/ErrorBoundary'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'

import { RouterProvider } from 'react-router-dom'
import { router } from './router'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const apiClient = new QueryClient()

const client = new ApolloClient({
  uri: 'https://arweave.net/graphql',
  cache: new InMemoryCache()
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <ApolloProvider client={client}>
        <QueryClientProvider client={apiClient}>
          <RouterProvider router={router} />
        </QueryClientProvider>
      </ApolloProvider>
    </ErrorBoundary>
  </React.StrictMode>
)
