import React, { useRef } from 'react'
import 'react-multi-carousel/lib/styles.css'
import { SpotLight, Vector3 } from 'three'
import { useSpring, animated } from '@react-spring/three'

interface SlideProps {
  position?: Vector3
  isActive?: boolean
  isHovered?: boolean
  children: React.ReactNode
}

export const Slide = ({ position, isActive, isHovered, children }: SlideProps) => {
  const lightRef = useRef<SpotLight>(null)

  const { scale, lightIntensity } = useSpring({
    scale: isActive ? 1 : 0.75,
    lightIntensity: isActive ? 5 : isHovered ? 3 : 1,
    config: { duration: 150, mass: 0.4 }
  })

  return (
    <animated.group
      ref={(ref) => {
        if (ref && lightRef.current) {
          lightRef.current.target = ref
        }
      }}
      position={position}
      scale={scale}
    >
      <animated.spotLight
        ref={lightRef}
        position={new Vector3(0, 2, 1)}
        decay={1}
        intensity={lightIntensity}
        angle={Math.PI / 12}
      />

      {children}
    </animated.group>
  )
}
