import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faDiscord,
  faTwitter,
  faYoutube,
  faFacebook,
  faItchIo,
  faXTwitter
} from '@fortawesome/free-brands-svg-icons'
import { config } from '../../../../config'
import styles from './FooterSection.module.scss'
import { routes } from '../../../../router'
import { Link } from 'react-router-dom'

const socialLinks = [
  {
    title: 'Join the discord server of Astronation',
    icon: faDiscord,
    url: 'https://discord.gg/aC3wddk'
  },
  {
    title: 'Twitter news stream',
    icon: faXTwitter,
    url: 'https://x.com/astronationvr'
  },
  {
    title: 'YouTube channel',
    icon: faYoutube,
    url: 'https://www.youtube.com/channel/UCILBQGjRCKNE1WGbfBgdLBw'
  }
]

const logoUrl = config.image('logo/logo_440.png')

export const FooterSection = () => (
  <section className={styles.footer}>
    <div className={styles.links}>
      <div>
        <Link to={routes.privacy}>Privacy Policy</Link>
        <Link to={routes.terms}>Terms of Service</Link>
      </div>
    </div>

    <div className={styles.logo}>
      <img src={logoUrl} alt="logo" />
    </div>

    <div className={styles.social}>
      {socialLinks.map(({ title, icon, url }) => (
        <a key={url} href={url} title={title} target="_blank">
          <FontAwesomeIcon icon={icon} size="xl" />
        </a>
      ))}
    </div>
  </section>
)
