import React, { useState } from 'react'
import Header from './Header'
import Footer from './Footer'
import { useOverlayShape } from './useOverlayShape'
import styles from './Overlay.module.scss'
import { isHighEndGpu } from '../../../helpers/isHighEndGpu'
import clsx from 'clsx'
import { SCROLL_CONTAINER_ID, useScrollToTopOnNavigate } from '../../../helpers/scroll'
import { ScrollDots } from './scrollDots/ScrollDots'

const CLIP_PATH_ID = 'overlayClipId'
export const OVERLAY_CONTAINER_ID = 'overlayContainerId'

export default function Overlay({ children }: { children?: React.ReactNode }) {
  useScrollToTopOnNavigate()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const isGpu = isHighEndGpu()

  const { path, width, height, isNarrow, pathStyle } = useOverlayShape({ isMenuOpen })

  return (
    <div className={styles.overlay}>
      <div className={styles.overlayContainer} id={OVERLAY_CONTAINER_ID}>
        <Header isMenuOpen={isMenuOpen} onMenuToggle={setIsMenuOpen} smallLogo={isNarrow} />
        <svg className={styles.clippath} width="0" height="0">
          <defs>
            <clipPath id={CLIP_PATH_ID}>
              <path d={path} style={pathStyle} />
            </clipPath>
          </defs>
        </svg>

        <div
          className={clsx(styles.overlayContent, isGpu && styles.highEnd)}
          style={{ clipPath: `url(#${CLIP_PATH_ID})` }}
          id={SCROLL_CONTAINER_ID}
        >
          {children}
        </div>

        <svg
          className={styles.frame}
          width={width}
          height={height}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path
              d={path}
              stroke="url(#paint_gradient)"
              strokeOpacity="0.3"
              strokeWidth="2"
              style={pathStyle}
            />
            <path d={path} stroke="white" strokeOpacity="0.2" strokeWidth="2" style={pathStyle} />
            <path d={path} stroke="#3FCAE8" strokeOpacity="0.6" strokeWidth="1" style={pathStyle} />
          </g>
          <defs>
            <linearGradient
              id="paint_gradient"
              x1="-51.9089"
              y1="-74.2163"
              x2="1409"
              y2="994.187"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#425E5F" />
              <stop offset="1" stopColor="#18292B" />
            </linearGradient>
          </defs>
        </svg>
        <Footer />
        <ScrollDots />
      </div>
    </div>
  )
}
