import { useContext, useMemo } from 'react'
import { SessionContext } from '../../../utils/session/SessionProvider'
import { queryEntities, queryOwnedVehiclesIds } from '../../../api/queries'
import { SurfaceDataWithMeta, useSurfaceData } from './useSurfaceData'
import { ElementBody } from '../../../domain/state/Element'

export interface VehicleEntity {
  entityId: string
  mainSurface: SurfaceDataWithMeta
  body: ElementBody
}

export const useOwnedVehicles = () => {
  const { data: vehiclesIds, isFetching: vehiclesLoading } = queryOwnedVehiclesIds()
  const { data: entities, isFetching: entitiesLoading } = queryEntities(vehiclesIds ?? [])
  const surfaceData = useSurfaceData()

  const ownedVehicles: VehicleEntity[] = useMemo(() => {
    return (vehiclesIds ?? [])
      .map((guid) => {
        const bodyShapeId = entities?.[guid]?.body?.elements[0].shapeId

        return {
          entityId: guid,
          mainSurface: surfaceData(bodyShapeId),
          body: entities?.[guid]?.body
        }
      })
      .filter((vehicle) => vehicle !== null)
  }, [entities, vehiclesIds])

  return {
    isLoading: vehiclesLoading || entitiesLoading,
    vehicles: ownedVehicles
  }
}
