import React from 'react'
import styles from './OtherVehicles.module.scss'
import { BodyCarousel, RenderOverlayProps } from '../../layout/carousels/body/BodyCarousel'
import clsx from 'clsx'
import { Button } from '../../layout/controls/button/Button'
import { Vector3 } from 'three'
import { useSurfaceData } from '../../helpers/session/useSurfaceData'
import { labelForVehicleKind } from '../../helpers/labels'
import { VehicleEntity } from '../../helpers/session/useOwnedVehicles'

interface OtherVehiclesProps {
  visible: boolean
  allVehicles: VehicleEntity[]
  currentEntityId: string
  onSetAsCurrent: (id: string) => void
}

export const OtherVehicles = ({
  visible,
  allVehicles,
  currentEntityId,
  onSetAsCurrent
}: OtherVehiclesProps) => {
  if (!visible) return

  const surfaceData = useSurfaceData()
  const bodies = allVehicles.map(({ entityId, body }) => ({
    id: entityId,
    data: body
  }))

  const renderOverlay = ({ id, body, isActive, overlayProps }: RenderOverlayProps) => {
    const isCurrent = currentEntityId === id
    const bodyShapeId = body?.elements[0].shapeId
    const vehicleData = surfaceData(bodyShapeId)

    return (
      <div
        className={clsx(styles.overlay, {
          [styles.active]: isActive,
          [styles.current]: isCurrent
        })}
        {...overlayProps}
      >
        <div className={styles.content}>
          <h3>{vehicleData.name}</h3>
          <p>{labelForVehicleKind(vehicleData.kind)}</p>

          <div className={styles.link}>
            <Button
              variant="secondary"
              size="small"
              onClick={() => {
                if (!isCurrent) onSetAsCurrent(id)
              }}
            >
              {isCurrent ? 'Current entity' : 'Set as current'}
            </Button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.ovContainer}>
      <h2>Other with access</h2>
      <BodyCarousel
        overlayOnTop={false}
        slideOffset={1}
        size={1}
        prefix="other-vehicles"
        bodies={bodies}
        height={400}
        modelPosition={new Vector3(0, 0.13, 0)}
        renderHtmlOverlay={renderOverlay}
      />
    </div>
  )
}
