import React from 'react'
import { clsx } from 'clsx'
import { ButtonHTMLAttributes, forwardRef } from 'react'
import styles from './Button.module.scss'

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: 'primary' | 'secondary' | 'ghost' | 'rectangle'
  size?: 'small' | 'medium' | 'large'
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const { children, variant = 'primary', size = 'large', className, ...rest } = props
    return (
      <button
        ref={ref}
        {...props}
        className={clsx(styles.button, className, styles[variant], styles[size])}
      >
        {children}
      </button>
    )
  }
)

Button.displayName = Button.name
