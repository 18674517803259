import React from 'react'
import { Link, Navigate, useParams } from 'react-router-dom'
import { routes } from '../../../router'
import { CelestialPreview } from './CelestialPreview'
import { ContentSection } from '../../layout/sections/content/ContentSection'
import { InfoTable } from '../../layout/infoTable/InfoTable'
import { config } from '../../../config'
import { SessionContext } from '../../../utils/session/SessionProvider'
import { useSurfaceData } from '../../helpers/session/useSurfaceData'
import { labelForEntityClass } from '../../helpers/labels'
import { StationsCarousel } from './StationsCarousel'
import { RelatedItem } from '../../layout/sections/content/Related'
import { statics } from '../../../api/Statics'
import { useAllStations } from '../../helpers/session/useAllStations'
import { State } from '../../../domain/state/State'

export const CelestialDetails: React.FC = () => {
  const { id: idString } = useParams()
  const id = parseInt(idString ?? '0')

  const { celestials, loading } = React.useContext(SessionContext)
  if (!celestials) return <></>

  const celestial = Object.values(celestials).filter((value) => value.id == id)[0]
  const orbiters = Object.values(celestials).filter(
    (value) => value.orbit.parentId == id
  )

  if (!celestial) {
    return loading ? <></> : <Navigate to="/not-found" replace />
  }

  const allStations = useAllStations()

  const surfaceElement = celestial.body.elements[0]
  const surfaces = useSurfaceData()
  const surface = surfaces(surfaceElement.shapeId)

  const infoTable = [
    { label: 'Radius', value: `${Math.round(surfaceElement.radius / 1e3)} km` },
    { label: 'Mass', value: `${Math.round(celestial.orbit.ownMass / 1e21)} ET` },
    { label: 'Power', value: `${Math.round(surfaceElement.peh.power / 1e3)} kPa` },
    { label: 'Energy', value: `${Math.round(surfaceElement.peh.energy / 1e6)} MC` },
    { label: 'Heat', value: `${Math.round(surfaceElement.peh.heat)} K` }
  ]

  const related: RelatedItem[] = orbiters.map((orbiter) => {
    const relatedShapeId = orbiter.body.elements[0].shapeId
    const relatedSurface = surfaces(relatedShapeId)

    return {
      id: orbiter.id.toString(),
      link: routes.celestialFor(orbiter.id.toString()),
      image: statics.thumbnailUrl(relatedSurface.guid),
      name: relatedSurface.name
    }
  })

  const stations: State[] = allStations.all.filter((station) => (station.entity.translation.orbit?.parentId??'') == id.toString()).map((station) => station.entity)

  return (
    <div className='container'>
      <div className='container-grid'>
        <div className='canvas'>
          <CelestialPreview celestial={celestial} />
        </div>
        <ContentSection
          title={surface.name}
          subtitle={surface.meta.header}
          description={surface.meta.description}
          relatedKind='Orbiting bodies'
          related={related}
        >
          <hr/>
          <h4>Details</h4>
          <InfoTable items={infoTable} />
        </ContentSection>
      </div>
      <StationsCarousel stations={stations} />
    </div>
  )
}
