import React from 'react'
import styles from './Progress.module.scss'

interface ProgressProps {
  value: number
  max: number
  progress: number
}

const Progress: React.FC<ProgressProps> = ({ value, max, progress }) => {
  return (
    <div className={styles.progressContainer}>
      <div>
        <span style={{ width: `${progress}%` }} />
      </div>
      <div>
        <span>{value}</span>
        <span>{max} (next level)</span>
      </div>
    </div>
  )
}

export default Progress
