import React from 'react'
import { Text } from '@react-three/drei'

import { Canvas } from '@react-three/fiber'
import { EffectComposer, HueSaturation } from '@react-three/postprocessing'
import { BlendFunction } from 'postprocessing'
import { FloatingGroup } from '../../ui/helpers/3d/FloatingGroup'
import { FooterSection } from '../../ui/layout/sections/footer/FooterSection'
import { ScrollSection } from '../../ui/layout/sections/scroll/ScrollSection'

export const ErrorPage = ({code, text, children}: { code: string, text: string, children: React.ReactNode }) => {
  
  return (
    <div>
      <ScrollSection>
        <Canvas
          dpr={[1,2]}
          shadows="basic"
          camera={{ position: [0, 0, 100], fov: 4 }}
          style={{ touchAction: 'none', height: `420px`, width: '100%' }}
          gl={{ localClippingEnabled: true, alpha: true }}
          resize={{ scroll: false, offsetSize: true }}
        >
          <ambientLight intensity={Math.PI / 42} />

          <EffectComposer>
            <HueSaturation blendFunction={BlendFunction.NORMAL} hue={0} saturation={0} />
          </EffectComposer>

          <FloatingGroup>
            <Text
              position={[0, 1, -3]}
              fontSize={4}
              fontWeight={800}
              color="#3fcae8"
              anchorX="center"
              anchorY="middle"
            >
              {code}
            </Text>
            <Text 
              position={[0, -1, -2]} 
              fontSize={1} 
              fontWeight={600}
              color="#22788a" 
              anchorX="center" 
              anchorY="middle"
              textAlign='center'
              overflowWrap='break-word'
              maxWidth={12}
            >
              {text}
            </Text>
          </FloatingGroup>
        </Canvas>
      </ScrollSection>
      <ScrollSection>
        {children}
      </ScrollSection>
      <ScrollSection>
        <FooterSection />
      </ScrollSection>
    </div>
  )
}

