/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.20.3
 * source: state.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from './common'
import * as pb_1 from 'google-protobuf'
export namespace Astronation {
  export class EntityConfig extends pb_1.Message {
    #one_of_decls: number[][] = []
    constructor(
      data?:
        | any[]
        | {
            id?: number
            version?: number
            service?: number
            kind?: number
            data?: string
          }
    ) {
      super()
      pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls)
      if (!Array.isArray(data) && typeof data == 'object') {
        if ('id' in data && data.id != undefined) {
          this.id = data.id
        }
        if ('version' in data && data.version != undefined) {
          this.version = data.version
        }
        if ('service' in data && data.service != undefined) {
          this.service = data.service
        }
        if ('kind' in data && data.kind != undefined) {
          this.kind = data.kind
        }
        if ('data' in data && data.data != undefined) {
          this.data = data.data
        }
      }
    }
    get id() {
      return pb_1.Message.getFieldWithDefault(this, 1, 0) as number
    }
    set id(value: number) {
      pb_1.Message.setField(this, 1, value)
    }
    get version() {
      return pb_1.Message.getFieldWithDefault(this, 2, 0) as number
    }
    set version(value: number) {
      pb_1.Message.setField(this, 2, value)
    }
    get service() {
      return pb_1.Message.getFieldWithDefault(this, 3, 0) as number
    }
    set service(value: number) {
      pb_1.Message.setField(this, 3, value)
    }
    get kind() {
      return pb_1.Message.getFieldWithDefault(this, 4, 0) as number
    }
    set kind(value: number) {
      pb_1.Message.setField(this, 4, value)
    }
    get data() {
      return pb_1.Message.getFieldWithDefault(this, 5, '') as string
    }
    set data(value: string) {
      pb_1.Message.setField(this, 5, value)
    }
    static fromObject(data: { id?: number; version?: number; service?: number; kind?: number; data?: string }): EntityConfig {
      const message = new EntityConfig({})
      if (data.id != null) {
        message.id = data.id
      }
      if (data.version != null) {
        message.version = data.version
      }
      if (data.service != null) {
        message.service = data.service
      }
      if (data.kind != null) {
        message.kind = data.kind
      }
      if (data.data != null) {
        message.data = data.data
      }
      return message
    }
    toObject() {
      const data: {
        id?: number
        version?: number
        service?: number
        kind?: number
        data?: string
      } = {}
      if (this.id != null) {
        data.id = this.id
      }
      if (this.version != null) {
        data.version = this.version
      }
      if (this.service != null) {
        data.service = this.service
      }
      if (this.kind != null) {
        data.kind = this.kind
      }
      if (this.data != null) {
        data.data = this.data
      }
      return data
    }
    serialize(): Uint8Array
    serialize(w: pb_1.BinaryWriter): void
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
      const writer = w || new pb_1.BinaryWriter()
      if (this.id != 0) writer.writeInt64(1, this.id)
      if (this.version != 0) writer.writeInt64(2, this.version)
      if (this.service != 0) writer.writeInt64(3, this.service)
      if (this.kind != 0) writer.writeInt64(4, this.kind)
      if (this.data.length) writer.writeString(5, this.data)
      if (!w) return writer.getResultBuffer()
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): EntityConfig {
      const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes),
        message = new EntityConfig()
      while (reader.nextField()) {
        if (reader.isEndGroup()) break
        switch (reader.getFieldNumber()) {
          case 1:
            message.id = reader.readInt64()
            break
          case 2:
            message.version = reader.readInt64()
            break
          case 3:
            message.service = reader.readInt64()
            break
          case 4:
            message.kind = reader.readInt64()
            break
          case 5:
            message.data = reader.readString()
            break
          default:
            reader.skipField()
        }
      }
      return message
    }
    serializeBinary(): Uint8Array {
      return this.serialize()
    }
    static deserializeBinary(bytes: Uint8Array): EntityConfig {
      return EntityConfig.deserialize(bytes)
    }
  }
  export class Body extends pb_1.Message {
    #one_of_decls: number[][] = []
    constructor(
      data?:
        | any[]
        | {
            elements?: Element[]
          }
    ) {
      super()
      pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls)
      if (!Array.isArray(data) && typeof data == 'object') {
        if ('elements' in data && data.elements != undefined) {
          this.elements = data.elements
        }
      }
    }
    get elements() {
      return pb_1.Message.getRepeatedWrapperField(this, Element, 1) as Element[]
    }
    set elements(value: Element[]) {
      pb_1.Message.setRepeatedWrapperField(this, 1, value)
    }
    static fromObject(data: { elements?: ReturnType<typeof Element.prototype.toObject>[] }): Body {
      const message = new Body({})
      if (data.elements != null) {
        message.elements = data.elements.map((item) => Element.fromObject(item))
      }
      return message
    }
    toObject() {
      const data: {
        elements?: ReturnType<typeof Element.prototype.toObject>[]
      } = {}
      if (this.elements != null) {
        data.elements = this.elements.map((item: Element) => item.toObject())
      }
      return data
    }
    serialize(): Uint8Array
    serialize(w: pb_1.BinaryWriter): void
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
      const writer = w || new pb_1.BinaryWriter()
      if (this.elements.length) writer.writeRepeatedMessage(1, this.elements, (item: Element) => item.serialize(writer))
      if (!w) return writer.getResultBuffer()
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Body {
      const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes),
        message = new Body()
      while (reader.nextField()) {
        if (reader.isEndGroup()) break
        switch (reader.getFieldNumber()) {
          case 1:
            reader.readMessage(message.elements, () =>
              pb_1.Message.addToRepeatedWrapperField(message, 1, Element.deserialize(reader), Element)
            )
            break
          default:
            reader.skipField()
        }
      }
      return message
    }
    serializeBinary(): Uint8Array {
      return this.serialize()
    }
    static deserializeBinary(bytes: Uint8Array): Body {
      return Body.deserialize(bytes)
    }
  }
  export class Activate extends pb_1.Message {
    #one_of_decls: number[][] = []
    constructor(
      data?:
        | any[]
        | {
            ability?: number
            magnitude?: dependency_1.Astronation.Vector3D
          }
    ) {
      super()
      pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls)
      if (!Array.isArray(data) && typeof data == 'object') {
        if ('ability' in data && data.ability != undefined) {
          this.ability = data.ability
        }
        if ('magnitude' in data && data.magnitude != undefined) {
          this.magnitude = data.magnitude
        }
      }
    }
    get ability() {
      return pb_1.Message.getFieldWithDefault(this, 1, 0) as number
    }
    set ability(value: number) {
      pb_1.Message.setField(this, 1, value)
    }
    get magnitude() {
      return pb_1.Message.getWrapperField(this, dependency_1.Astronation.Vector3D, 2) as dependency_1.Astronation.Vector3D
    }
    set magnitude(value: dependency_1.Astronation.Vector3D) {
      pb_1.Message.setWrapperField(this, 2, value)
    }
    get has_magnitude() {
      return pb_1.Message.getField(this, 2) != null
    }
    static fromObject(data: {
      ability?: number
      magnitude?: ReturnType<typeof dependency_1.Astronation.Vector3D.prototype.toObject>
    }): Activate {
      const message = new Activate({})
      if (data.ability != null) {
        message.ability = data.ability
      }
      if (data.magnitude != null) {
        message.magnitude = dependency_1.Astronation.Vector3D.fromObject(data.magnitude)
      }
      return message
    }
    toObject() {
      const data: {
        ability?: number
        magnitude?: ReturnType<typeof dependency_1.Astronation.Vector3D.prototype.toObject>
      } = {}
      if (this.ability != null) {
        data.ability = this.ability
      }
      if (this.magnitude != null) {
        data.magnitude = this.magnitude.toObject()
      }
      return data
    }
    serialize(): Uint8Array
    serialize(w: pb_1.BinaryWriter): void
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
      const writer = w || new pb_1.BinaryWriter()
      if (this.ability != 0) writer.writeInt32(1, this.ability)
      if (this.has_magnitude) writer.writeMessage(2, this.magnitude, () => this.magnitude.serialize(writer))
      if (!w) return writer.getResultBuffer()
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Activate {
      const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes),
        message = new Activate()
      while (reader.nextField()) {
        if (reader.isEndGroup()) break
        switch (reader.getFieldNumber()) {
          case 1:
            message.ability = reader.readInt32()
            break
          case 2:
            reader.readMessage(message.magnitude, () => (message.magnitude = dependency_1.Astronation.Vector3D.deserialize(reader)))
            break
          default:
            reader.skipField()
        }
      }
      return message
    }
    serializeBinary(): Uint8Array {
      return this.serialize()
    }
    static deserializeBinary(bytes: Uint8Array): Activate {
      return Activate.deserialize(bytes)
    }
  }
  export class State extends pb_1.Message {
    #one_of_decls: number[][] = []
    constructor(
      data?:
        | any[]
        | {
            id?: string
            second?: dependency_1.Astronation.BigDecimal
            translation?: Translation
            body?: Body
            configs?: EntityConfig[]
            activations?: Activate[]
          }
    ) {
      super()
      pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [5, 6], this.#one_of_decls)
      if (!Array.isArray(data) && typeof data == 'object') {
        if ('id' in data && data.id != undefined) {
          this.id = data.id
        }
        if ('second' in data && data.second != undefined) {
          this.second = data.second
        }
        if ('translation' in data && data.translation != undefined) {
          this.translation = data.translation
        }
        if ('body' in data && data.body != undefined) {
          this.body = data.body
        }
        if ('configs' in data && data.configs != undefined) {
          this.configs = data.configs
        }
        if ('activations' in data && data.activations != undefined) {
          this.activations = data.activations
        }
      }
    }
    get id() {
      return pb_1.Message.getFieldWithDefault(this, 1, '') as string
    }
    set id(value: string) {
      pb_1.Message.setField(this, 1, value)
    }
    get second() {
      return pb_1.Message.getWrapperField(this, dependency_1.Astronation.BigDecimal, 2) as dependency_1.Astronation.BigDecimal
    }
    set second(value: dependency_1.Astronation.BigDecimal) {
      pb_1.Message.setWrapperField(this, 2, value)
    }
    get has_second() {
      return pb_1.Message.getField(this, 2) != null
    }
    get translation() {
      return pb_1.Message.getWrapperField(this, Translation, 3) as Translation
    }
    set translation(value: Translation) {
      pb_1.Message.setWrapperField(this, 3, value)
    }
    get has_translation() {
      return pb_1.Message.getField(this, 3) != null
    }
    get body() {
      return pb_1.Message.getWrapperField(this, Body, 4) as Body
    }
    set body(value: Body) {
      pb_1.Message.setWrapperField(this, 4, value)
    }
    get has_body() {
      return pb_1.Message.getField(this, 4) != null
    }
    get configs() {
      return pb_1.Message.getRepeatedWrapperField(this, EntityConfig, 5) as EntityConfig[]
    }
    set configs(value: EntityConfig[]) {
      pb_1.Message.setRepeatedWrapperField(this, 5, value)
    }
    get activations() {
      return pb_1.Message.getRepeatedWrapperField(this, Activate, 6) as Activate[]
    }
    set activations(value: Activate[]) {
      pb_1.Message.setRepeatedWrapperField(this, 6, value)
    }
    static fromObject(data: {
      id?: string
      second?: ReturnType<typeof dependency_1.Astronation.BigDecimal.prototype.toObject>
      translation?: ReturnType<typeof Translation.prototype.toObject>
      body?: ReturnType<typeof Body.prototype.toObject>
      configs?: ReturnType<typeof EntityConfig.prototype.toObject>[]
      activations?: ReturnType<typeof Activate.prototype.toObject>[]
    }): State {
      const message = new State({})
      if (data.id != null) {
        message.id = data.id
      }
      if (data.second != null) {
        message.second = dependency_1.Astronation.BigDecimal.fromObject(data.second)
      }
      if (data.translation != null) {
        message.translation = Translation.fromObject(data.translation)
      }
      if (data.body != null) {
        message.body = Body.fromObject(data.body)
      }
      if (data.configs != null) {
        message.configs = data.configs.map((item) => EntityConfig.fromObject(item))
      }
      if (data.activations != null) {
        message.activations = data.activations.map((item) => Activate.fromObject(item))
      }
      return message
    }
    toObject() {
      const data: {
        id?: string
        second?: ReturnType<typeof dependency_1.Astronation.BigDecimal.prototype.toObject>
        translation?: ReturnType<typeof Translation.prototype.toObject>
        body?: ReturnType<typeof Body.prototype.toObject>
        configs?: ReturnType<typeof EntityConfig.prototype.toObject>[]
        activations?: ReturnType<typeof Activate.prototype.toObject>[]
      } = {}
      if (this.id != null) {
        data.id = this.id
      }
      if (this.second != null) {
        data.second = this.second.toObject()
      }
      if (this.translation != null) {
        data.translation = this.translation.toObject()
      }
      if (this.body != null) {
        data.body = this.body.toObject()
      }
      if (this.configs != null) {
        data.configs = this.configs.map((item: EntityConfig) => item.toObject())
      }
      if (this.activations != null) {
        data.activations = this.activations.map((item: Activate) => item.toObject())
      }
      return data
    }
    serialize(): Uint8Array
    serialize(w: pb_1.BinaryWriter): void
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
      const writer = w || new pb_1.BinaryWriter()
      if (this.id.length) writer.writeString(1, this.id)
      if (this.has_second) writer.writeMessage(2, this.second, () => this.second.serialize(writer))
      if (this.has_translation) writer.writeMessage(3, this.translation, () => this.translation.serialize(writer))
      if (this.has_body) writer.writeMessage(4, this.body, () => this.body.serialize(writer))
      if (this.configs.length) writer.writeRepeatedMessage(5, this.configs, (item: EntityConfig) => item.serialize(writer))
      if (this.activations.length) writer.writeRepeatedMessage(6, this.activations, (item: Activate) => item.serialize(writer))
      if (!w) return writer.getResultBuffer()
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): State {
      const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes),
        message = new State()
      while (reader.nextField()) {
        if (reader.isEndGroup()) break
        switch (reader.getFieldNumber()) {
          case 1:
            message.id = reader.readString()
            break
          case 2:
            reader.readMessage(message.second, () => (message.second = dependency_1.Astronation.BigDecimal.deserialize(reader)))
            break
          case 3:
            reader.readMessage(message.translation, () => (message.translation = Translation.deserialize(reader)))
            break
          case 4:
            reader.readMessage(message.body, () => (message.body = Body.deserialize(reader)))
            break
          case 5:
            reader.readMessage(message.configs, () =>
              pb_1.Message.addToRepeatedWrapperField(message, 5, EntityConfig.deserialize(reader), EntityConfig)
            )
            break
          case 6:
            reader.readMessage(message.activations, () =>
              pb_1.Message.addToRepeatedWrapperField(message, 6, Activate.deserialize(reader), Activate)
            )
            break
          default:
            reader.skipField()
        }
      }
      return message
    }
    serializeBinary(): Uint8Array {
      return this.serialize()
    }
    static deserializeBinary(bytes: Uint8Array): State {
      return State.deserialize(bytes)
    }
  }
  export class Translation extends pb_1.Message {
    #one_of_decls: number[][] = []
    constructor(
      data?:
        | any[]
        | {
            coordinates?: dependency_1.Astronation.Vector3BD
            velocity?: dependency_1.Astronation.Vector3BD
            acceleration?: dependency_1.Astronation.Vector3BD
            rotation?: dependency_1.Astronation.Vector3D
            revolution?: dependency_1.Astronation.Vector3D
            orbit?: Orbit
            dock?: Dock
          }
    ) {
      super()
      pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls)
      if (!Array.isArray(data) && typeof data == 'object') {
        if ('coordinates' in data && data.coordinates != undefined) {
          this.coordinates = data.coordinates
        }
        if ('velocity' in data && data.velocity != undefined) {
          this.velocity = data.velocity
        }
        if ('acceleration' in data && data.acceleration != undefined) {
          this.acceleration = data.acceleration
        }
        if ('rotation' in data && data.rotation != undefined) {
          this.rotation = data.rotation
        }
        if ('revolution' in data && data.revolution != undefined) {
          this.revolution = data.revolution
        }
        if ('orbit' in data && data.orbit != undefined) {
          this.orbit = data.orbit
        }
        if ('dock' in data && data.dock != undefined) {
          this.dock = data.dock
        }
      }
    }
    get coordinates() {
      return pb_1.Message.getWrapperField(this, dependency_1.Astronation.Vector3BD, 1) as dependency_1.Astronation.Vector3BD
    }
    set coordinates(value: dependency_1.Astronation.Vector3BD) {
      pb_1.Message.setWrapperField(this, 1, value)
    }
    get has_coordinates() {
      return pb_1.Message.getField(this, 1) != null
    }
    get velocity() {
      return pb_1.Message.getWrapperField(this, dependency_1.Astronation.Vector3BD, 2) as dependency_1.Astronation.Vector3BD
    }
    set velocity(value: dependency_1.Astronation.Vector3BD) {
      pb_1.Message.setWrapperField(this, 2, value)
    }
    get has_velocity() {
      return pb_1.Message.getField(this, 2) != null
    }
    get acceleration() {
      return pb_1.Message.getWrapperField(this, dependency_1.Astronation.Vector3BD, 3) as dependency_1.Astronation.Vector3BD
    }
    set acceleration(value: dependency_1.Astronation.Vector3BD) {
      pb_1.Message.setWrapperField(this, 3, value)
    }
    get has_acceleration() {
      return pb_1.Message.getField(this, 3) != null
    }
    get rotation() {
      return pb_1.Message.getWrapperField(this, dependency_1.Astronation.Vector3D, 4) as dependency_1.Astronation.Vector3D
    }
    set rotation(value: dependency_1.Astronation.Vector3D) {
      pb_1.Message.setWrapperField(this, 4, value)
    }
    get has_rotation() {
      return pb_1.Message.getField(this, 4) != null
    }
    get revolution() {
      return pb_1.Message.getWrapperField(this, dependency_1.Astronation.Vector3D, 5) as dependency_1.Astronation.Vector3D
    }
    set revolution(value: dependency_1.Astronation.Vector3D) {
      pb_1.Message.setWrapperField(this, 5, value)
    }
    get has_revolution() {
      return pb_1.Message.getField(this, 5) != null
    }
    get orbit() {
      return pb_1.Message.getWrapperField(this, Orbit, 6) as Orbit
    }
    set orbit(value: Orbit) {
      pb_1.Message.setWrapperField(this, 6, value)
    }
    get has_orbit() {
      return pb_1.Message.getField(this, 6) != null
    }
    get dock() {
      return pb_1.Message.getWrapperField(this, Dock, 7) as Dock
    }
    set dock(value: Dock) {
      pb_1.Message.setWrapperField(this, 7, value)
    }
    get has_dock() {
      return pb_1.Message.getField(this, 7) != null
    }
    static fromObject(data: {
      coordinates?: ReturnType<typeof dependency_1.Astronation.Vector3BD.prototype.toObject>
      velocity?: ReturnType<typeof dependency_1.Astronation.Vector3BD.prototype.toObject>
      acceleration?: ReturnType<typeof dependency_1.Astronation.Vector3BD.prototype.toObject>
      rotation?: ReturnType<typeof dependency_1.Astronation.Vector3D.prototype.toObject>
      revolution?: ReturnType<typeof dependency_1.Astronation.Vector3D.prototype.toObject>
      orbit?: ReturnType<typeof Orbit.prototype.toObject>
      dock?: ReturnType<typeof Dock.prototype.toObject>
    }): Translation {
      const message = new Translation({})
      if (data.coordinates != null) {
        message.coordinates = dependency_1.Astronation.Vector3BD.fromObject(data.coordinates)
      }
      if (data.velocity != null) {
        message.velocity = dependency_1.Astronation.Vector3BD.fromObject(data.velocity)
      }
      if (data.acceleration != null) {
        message.acceleration = dependency_1.Astronation.Vector3BD.fromObject(data.acceleration)
      }
      if (data.rotation != null) {
        message.rotation = dependency_1.Astronation.Vector3D.fromObject(data.rotation)
      }
      if (data.revolution != null) {
        message.revolution = dependency_1.Astronation.Vector3D.fromObject(data.revolution)
      }
      if (data.orbit != null) {
        message.orbit = Orbit.fromObject(data.orbit)
      }
      if (data.dock != null) {
        message.dock = Dock.fromObject(data.dock)
      }
      return message
    }
    toObject() {
      const data: {
        coordinates?: ReturnType<typeof dependency_1.Astronation.Vector3BD.prototype.toObject>
        velocity?: ReturnType<typeof dependency_1.Astronation.Vector3BD.prototype.toObject>
        acceleration?: ReturnType<typeof dependency_1.Astronation.Vector3BD.prototype.toObject>
        rotation?: ReturnType<typeof dependency_1.Astronation.Vector3D.prototype.toObject>
        revolution?: ReturnType<typeof dependency_1.Astronation.Vector3D.prototype.toObject>
        orbit?: ReturnType<typeof Orbit.prototype.toObject>
        dock?: ReturnType<typeof Dock.prototype.toObject>
      } = {}
      if (this.coordinates != null) {
        data.coordinates = this.coordinates.toObject()
      }
      if (this.velocity != null) {
        data.velocity = this.velocity.toObject()
      }
      if (this.acceleration != null) {
        data.acceleration = this.acceleration.toObject()
      }
      if (this.rotation != null) {
        data.rotation = this.rotation.toObject()
      }
      if (this.revolution != null) {
        data.revolution = this.revolution.toObject()
      }
      if (this.orbit != null) {
        data.orbit = this.orbit.toObject()
      }
      if (this.dock != null) {
        data.dock = this.dock.toObject()
      }
      return data
    }
    serialize(): Uint8Array
    serialize(w: pb_1.BinaryWriter): void
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
      const writer = w || new pb_1.BinaryWriter()
      if (this.has_coordinates) writer.writeMessage(1, this.coordinates, () => this.coordinates.serialize(writer))
      if (this.has_velocity) writer.writeMessage(2, this.velocity, () => this.velocity.serialize(writer))
      if (this.has_acceleration) writer.writeMessage(3, this.acceleration, () => this.acceleration.serialize(writer))
      if (this.has_rotation) writer.writeMessage(4, this.rotation, () => this.rotation.serialize(writer))
      if (this.has_revolution) writer.writeMessage(5, this.revolution, () => this.revolution.serialize(writer))
      if (this.has_orbit) writer.writeMessage(6, this.orbit, () => this.orbit.serialize(writer))
      if (this.has_dock) writer.writeMessage(7, this.dock, () => this.dock.serialize(writer))
      if (!w) return writer.getResultBuffer()
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Translation {
      const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes),
        message = new Translation()
      while (reader.nextField()) {
        if (reader.isEndGroup()) break
        switch (reader.getFieldNumber()) {
          case 1:
            reader.readMessage(message.coordinates, () => (message.coordinates = dependency_1.Astronation.Vector3BD.deserialize(reader)))
            break
          case 2:
            reader.readMessage(message.velocity, () => (message.velocity = dependency_1.Astronation.Vector3BD.deserialize(reader)))
            break
          case 3:
            reader.readMessage(message.acceleration, () => (message.acceleration = dependency_1.Astronation.Vector3BD.deserialize(reader)))
            break
          case 4:
            reader.readMessage(message.rotation, () => (message.rotation = dependency_1.Astronation.Vector3D.deserialize(reader)))
            break
          case 5:
            reader.readMessage(message.revolution, () => (message.revolution = dependency_1.Astronation.Vector3D.deserialize(reader)))
            break
          case 6:
            reader.readMessage(message.orbit, () => (message.orbit = Orbit.deserialize(reader)))
            break
          case 7:
            reader.readMessage(message.dock, () => (message.dock = Dock.deserialize(reader)))
            break
          default:
            reader.skipField()
        }
      }
      return message
    }
    serializeBinary(): Uint8Array {
      return this.serialize()
    }
    static deserializeBinary(bytes: Uint8Array): Translation {
      return Translation.deserialize(bytes)
    }
  }
  export class Orbit extends pb_1.Message {
    #one_of_decls: number[][] = []
    constructor(
      data?:
        | any[]
        | {
            parentId?: number
            angle?: dependency_1.Astronation.BigDecimal
            minRadius?: dependency_1.Astronation.BigDecimal
            eccentricity?: dependency_1.Astronation.BigDecimal
            inclination?: dependency_1.Astronation.BigDecimal
            periapsis?: dependency_1.Astronation.BigDecimal
            longitude?: dependency_1.Astronation.BigDecimal
            ownMass?: number
          }
    ) {
      super()
      pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls)
      if (!Array.isArray(data) && typeof data == 'object') {
        if ('parentId' in data && data.parentId != undefined) {
          this.parentId = data.parentId
        }
        if ('angle' in data && data.angle != undefined) {
          this.angle = data.angle
        }
        if ('minRadius' in data && data.minRadius != undefined) {
          this.minRadius = data.minRadius
        }
        if ('eccentricity' in data && data.eccentricity != undefined) {
          this.eccentricity = data.eccentricity
        }
        if ('inclination' in data && data.inclination != undefined) {
          this.inclination = data.inclination
        }
        if ('periapsis' in data && data.periapsis != undefined) {
          this.periapsis = data.periapsis
        }
        if ('longitude' in data && data.longitude != undefined) {
          this.longitude = data.longitude
        }
        if ('ownMass' in data && data.ownMass != undefined) {
          this.ownMass = data.ownMass
        }
      }
    }
    get parentId() {
      return pb_1.Message.getFieldWithDefault(this, 1, 0) as number
    }
    set parentId(value: number) {
      pb_1.Message.setField(this, 1, value)
    }
    get angle() {
      return pb_1.Message.getWrapperField(this, dependency_1.Astronation.BigDecimal, 2) as dependency_1.Astronation.BigDecimal
    }
    set angle(value: dependency_1.Astronation.BigDecimal) {
      pb_1.Message.setWrapperField(this, 2, value)
    }
    get has_angle() {
      return pb_1.Message.getField(this, 2) != null
    }
    get minRadius() {
      return pb_1.Message.getWrapperField(this, dependency_1.Astronation.BigDecimal, 3) as dependency_1.Astronation.BigDecimal
    }
    set minRadius(value: dependency_1.Astronation.BigDecimal) {
      pb_1.Message.setWrapperField(this, 3, value)
    }
    get has_minRadius() {
      return pb_1.Message.getField(this, 3) != null
    }
    get eccentricity() {
      return pb_1.Message.getWrapperField(this, dependency_1.Astronation.BigDecimal, 4) as dependency_1.Astronation.BigDecimal
    }
    set eccentricity(value: dependency_1.Astronation.BigDecimal) {
      pb_1.Message.setWrapperField(this, 4, value)
    }
    get has_eccentricity() {
      return pb_1.Message.getField(this, 4) != null
    }
    get inclination() {
      return pb_1.Message.getWrapperField(this, dependency_1.Astronation.BigDecimal, 5) as dependency_1.Astronation.BigDecimal
    }
    set inclination(value: dependency_1.Astronation.BigDecimal) {
      pb_1.Message.setWrapperField(this, 5, value)
    }
    get has_inclination() {
      return pb_1.Message.getField(this, 5) != null
    }
    get periapsis() {
      return pb_1.Message.getWrapperField(this, dependency_1.Astronation.BigDecimal, 6) as dependency_1.Astronation.BigDecimal
    }
    set periapsis(value: dependency_1.Astronation.BigDecimal) {
      pb_1.Message.setWrapperField(this, 6, value)
    }
    get has_periapsis() {
      return pb_1.Message.getField(this, 6) != null
    }
    get longitude() {
      return pb_1.Message.getWrapperField(this, dependency_1.Astronation.BigDecimal, 7) as dependency_1.Astronation.BigDecimal
    }
    set longitude(value: dependency_1.Astronation.BigDecimal) {
      pb_1.Message.setWrapperField(this, 7, value)
    }
    get has_longitude() {
      return pb_1.Message.getField(this, 7) != null
    }
    get ownMass() {
      return pb_1.Message.getFieldWithDefault(this, 8, 0) as number
    }
    set ownMass(value: number) {
      pb_1.Message.setField(this, 8, value)
    }
    static fromObject(data: {
      parentId?: number
      angle?: ReturnType<typeof dependency_1.Astronation.BigDecimal.prototype.toObject>
      minRadius?: ReturnType<typeof dependency_1.Astronation.BigDecimal.prototype.toObject>
      eccentricity?: ReturnType<typeof dependency_1.Astronation.BigDecimal.prototype.toObject>
      inclination?: ReturnType<typeof dependency_1.Astronation.BigDecimal.prototype.toObject>
      periapsis?: ReturnType<typeof dependency_1.Astronation.BigDecimal.prototype.toObject>
      longitude?: ReturnType<typeof dependency_1.Astronation.BigDecimal.prototype.toObject>
      ownMass?: number
    }): Orbit {
      const message = new Orbit({})
      if (data.parentId != null) {
        message.parentId = data.parentId
      }
      if (data.angle != null) {
        message.angle = dependency_1.Astronation.BigDecimal.fromObject(data.angle)
      }
      if (data.minRadius != null) {
        message.minRadius = dependency_1.Astronation.BigDecimal.fromObject(data.minRadius)
      }
      if (data.eccentricity != null) {
        message.eccentricity = dependency_1.Astronation.BigDecimal.fromObject(data.eccentricity)
      }
      if (data.inclination != null) {
        message.inclination = dependency_1.Astronation.BigDecimal.fromObject(data.inclination)
      }
      if (data.periapsis != null) {
        message.periapsis = dependency_1.Astronation.BigDecimal.fromObject(data.periapsis)
      }
      if (data.longitude != null) {
        message.longitude = dependency_1.Astronation.BigDecimal.fromObject(data.longitude)
      }
      if (data.ownMass != null) {
        message.ownMass = data.ownMass
      }
      return message
    }
    toObject() {
      const data: {
        parentId?: number
        angle?: ReturnType<typeof dependency_1.Astronation.BigDecimal.prototype.toObject>
        minRadius?: ReturnType<typeof dependency_1.Astronation.BigDecimal.prototype.toObject>
        eccentricity?: ReturnType<typeof dependency_1.Astronation.BigDecimal.prototype.toObject>
        inclination?: ReturnType<typeof dependency_1.Astronation.BigDecimal.prototype.toObject>
        periapsis?: ReturnType<typeof dependency_1.Astronation.BigDecimal.prototype.toObject>
        longitude?: ReturnType<typeof dependency_1.Astronation.BigDecimal.prototype.toObject>
        ownMass?: number
      } = {}
      if (this.parentId != null) {
        data.parentId = this.parentId
      }
      if (this.angle != null) {
        data.angle = this.angle.toObject()
      }
      if (this.minRadius != null) {
        data.minRadius = this.minRadius.toObject()
      }
      if (this.eccentricity != null) {
        data.eccentricity = this.eccentricity.toObject()
      }
      if (this.inclination != null) {
        data.inclination = this.inclination.toObject()
      }
      if (this.periapsis != null) {
        data.periapsis = this.periapsis.toObject()
      }
      if (this.longitude != null) {
        data.longitude = this.longitude.toObject()
      }
      if (this.ownMass != null) {
        data.ownMass = this.ownMass
      }
      return data
    }
    serialize(): Uint8Array
    serialize(w: pb_1.BinaryWriter): void
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
      const writer = w || new pb_1.BinaryWriter()
      if (this.parentId != 0) writer.writeInt32(1, this.parentId)
      if (this.has_angle) writer.writeMessage(2, this.angle, () => this.angle.serialize(writer))
      if (this.has_minRadius) writer.writeMessage(3, this.minRadius, () => this.minRadius.serialize(writer))
      if (this.has_eccentricity) writer.writeMessage(4, this.eccentricity, () => this.eccentricity.serialize(writer))
      if (this.has_inclination) writer.writeMessage(5, this.inclination, () => this.inclination.serialize(writer))
      if (this.has_periapsis) writer.writeMessage(6, this.periapsis, () => this.periapsis.serialize(writer))
      if (this.has_longitude) writer.writeMessage(7, this.longitude, () => this.longitude.serialize(writer))
      if (this.ownMass != 0) writer.writeDouble(8, this.ownMass)
      if (!w) return writer.getResultBuffer()
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Orbit {
      const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes),
        message = new Orbit()
      while (reader.nextField()) {
        if (reader.isEndGroup()) break
        switch (reader.getFieldNumber()) {
          case 1:
            message.parentId = reader.readInt32()
            break
          case 2:
            reader.readMessage(message.angle, () => (message.angle = dependency_1.Astronation.BigDecimal.deserialize(reader)))
            break
          case 3:
            reader.readMessage(message.minRadius, () => (message.minRadius = dependency_1.Astronation.BigDecimal.deserialize(reader)))
            break
          case 4:
            reader.readMessage(message.eccentricity, () => (message.eccentricity = dependency_1.Astronation.BigDecimal.deserialize(reader)))
            break
          case 5:
            reader.readMessage(message.inclination, () => (message.inclination = dependency_1.Astronation.BigDecimal.deserialize(reader)))
            break
          case 6:
            reader.readMessage(message.periapsis, () => (message.periapsis = dependency_1.Astronation.BigDecimal.deserialize(reader)))
            break
          case 7:
            reader.readMessage(message.longitude, () => (message.longitude = dependency_1.Astronation.BigDecimal.deserialize(reader)))
            break
          case 8:
            message.ownMass = reader.readDouble()
            break
          default:
            reader.skipField()
        }
      }
      return message
    }
    serializeBinary(): Uint8Array {
      return this.serialize()
    }
    static deserializeBinary(bytes: Uint8Array): Orbit {
      return Orbit.deserialize(bytes)
    }
  }
  export class Dock extends pb_1.Message {
    #one_of_decls: number[][] = []
    constructor(
      data?:
        | any[]
        | {
            parentId?: string
            position?: dependency_1.Astronation.Vector3D
          }
    ) {
      super()
      pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls)
      if (!Array.isArray(data) && typeof data == 'object') {
        if ('parentId' in data && data.parentId != undefined) {
          this.parentId = data.parentId
        }
        if ('position' in data && data.position != undefined) {
          this.position = data.position
        }
      }
    }
    get parentId() {
      return pb_1.Message.getFieldWithDefault(this, 1, '') as string
    }
    set parentId(value: string) {
      pb_1.Message.setField(this, 1, value)
    }
    get position() {
      return pb_1.Message.getWrapperField(this, dependency_1.Astronation.Vector3D, 2) as dependency_1.Astronation.Vector3D
    }
    set position(value: dependency_1.Astronation.Vector3D) {
      pb_1.Message.setWrapperField(this, 2, value)
    }
    get has_position() {
      return pb_1.Message.getField(this, 2) != null
    }
    static fromObject(data: {
      parentId?: string
      position?: ReturnType<typeof dependency_1.Astronation.Vector3D.prototype.toObject>
    }): Dock {
      const message = new Dock({})
      if (data.parentId != null) {
        message.parentId = data.parentId
      }
      if (data.position != null) {
        message.position = dependency_1.Astronation.Vector3D.fromObject(data.position)
      }
      return message
    }
    toObject() {
      const data: {
        parentId?: string
        position?: ReturnType<typeof dependency_1.Astronation.Vector3D.prototype.toObject>
      } = {}
      if (this.parentId != null) {
        data.parentId = this.parentId
      }
      if (this.position != null) {
        data.position = this.position.toObject()
      }
      return data
    }
    serialize(): Uint8Array
    serialize(w: pb_1.BinaryWriter): void
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
      const writer = w || new pb_1.BinaryWriter()
      if (this.parentId.length) writer.writeString(1, this.parentId)
      if (this.has_position) writer.writeMessage(2, this.position, () => this.position.serialize(writer))
      if (!w) return writer.getResultBuffer()
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Dock {
      const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes),
        message = new Dock()
      while (reader.nextField()) {
        if (reader.isEndGroup()) break
        switch (reader.getFieldNumber()) {
          case 1:
            message.parentId = reader.readString()
            break
          case 2:
            reader.readMessage(message.position, () => (message.position = dependency_1.Astronation.Vector3D.deserialize(reader)))
            break
          default:
            reader.skipField()
        }
      }
      return message
    }
    serializeBinary(): Uint8Array {
      return this.serialize()
    }
    static deserializeBinary(bytes: Uint8Array): Dock {
      return Dock.deserialize(bytes)
    }
  }
  export class Element extends pb_1.Message {
    #one_of_decls: number[][] = []
    constructor(
      data?:
        | any[]
        | {
            shapeId?: number
            radius?: number
            height?: number
            fullness?: number
            emptiness?: number
            rotation?: dependency_1.Astronation.Vector3D
            position?: dependency_1.Astronation.Vector3D
            composite?: Compound[]
            elements?: Element[]
            peh?: PEH
            cfgId?: number
          }
    ) {
      super()
      pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [8, 9], this.#one_of_decls)
      if (!Array.isArray(data) && typeof data == 'object') {
        if ('shapeId' in data && data.shapeId != undefined) {
          this.shapeId = data.shapeId
        }
        if ('radius' in data && data.radius != undefined) {
          this.radius = data.radius
        }
        if ('height' in data && data.height != undefined) {
          this.height = data.height
        }
        if ('fullness' in data && data.fullness != undefined) {
          this.fullness = data.fullness
        }
        if ('emptiness' in data && data.emptiness != undefined) {
          this.emptiness = data.emptiness
        }
        if ('rotation' in data && data.rotation != undefined) {
          this.rotation = data.rotation
        }
        if ('position' in data && data.position != undefined) {
          this.position = data.position
        }
        if ('composite' in data && data.composite != undefined) {
          this.composite = data.composite
        }
        if ('elements' in data && data.elements != undefined) {
          this.elements = data.elements
        }
        if ('peh' in data && data.peh != undefined) {
          this.peh = data.peh
        }
        if ('cfgId' in data && data.cfgId != undefined) {
          this.cfgId = data.cfgId
        }
      }
    }
    get shapeId() {
      return pb_1.Message.getFieldWithDefault(this, 1, 0) as number
    }
    set shapeId(value: number) {
      pb_1.Message.setField(this, 1, value)
    }
    get radius() {
      return pb_1.Message.getFieldWithDefault(this, 2, 0) as number
    }
    set radius(value: number) {
      pb_1.Message.setField(this, 2, value)
    }
    get height() {
      return pb_1.Message.getFieldWithDefault(this, 3, 0) as number
    }
    set height(value: number) {
      pb_1.Message.setField(this, 3, value)
    }
    get fullness() {
      return pb_1.Message.getFieldWithDefault(this, 4, 0) as number
    }
    set fullness(value: number) {
      pb_1.Message.setField(this, 4, value)
    }
    get emptiness() {
      return pb_1.Message.getFieldWithDefault(this, 5, 0) as number
    }
    set emptiness(value: number) {
      pb_1.Message.setField(this, 5, value)
    }
    get rotation() {
      return pb_1.Message.getWrapperField(this, dependency_1.Astronation.Vector3D, 6) as dependency_1.Astronation.Vector3D
    }
    set rotation(value: dependency_1.Astronation.Vector3D) {
      pb_1.Message.setWrapperField(this, 6, value)
    }
    get has_rotation() {
      return pb_1.Message.getField(this, 6) != null
    }
    get position() {
      return pb_1.Message.getWrapperField(this, dependency_1.Astronation.Vector3D, 7) as dependency_1.Astronation.Vector3D
    }
    set position(value: dependency_1.Astronation.Vector3D) {
      pb_1.Message.setWrapperField(this, 7, value)
    }
    get has_position() {
      return pb_1.Message.getField(this, 7) != null
    }
    get composite() {
      return pb_1.Message.getRepeatedWrapperField(this, Compound, 8) as Compound[]
    }
    set composite(value: Compound[]) {
      pb_1.Message.setRepeatedWrapperField(this, 8, value)
    }
    get elements() {
      return pb_1.Message.getRepeatedWrapperField(this, Element, 9) as Element[]
    }
    set elements(value: Element[]) {
      pb_1.Message.setRepeatedWrapperField(this, 9, value)
    }
    get peh() {
      return pb_1.Message.getWrapperField(this, PEH, 10) as PEH
    }
    set peh(value: PEH) {
      pb_1.Message.setWrapperField(this, 10, value)
    }
    get has_peh() {
      return pb_1.Message.getField(this, 10) != null
    }
    get cfgId() {
      return pb_1.Message.getFieldWithDefault(this, 11, 0) as number
    }
    set cfgId(value: number) {
      pb_1.Message.setField(this, 11, value)
    }
    static fromObject(data: {
      shapeId?: number
      radius?: number
      height?: number
      fullness?: number
      emptiness?: number
      rotation?: ReturnType<typeof dependency_1.Astronation.Vector3D.prototype.toObject>
      position?: ReturnType<typeof dependency_1.Astronation.Vector3D.prototype.toObject>
      composite?: ReturnType<typeof Compound.prototype.toObject>[]
      elements?: ReturnType<typeof Element.prototype.toObject>[]
      peh?: ReturnType<typeof PEH.prototype.toObject>
      cfgId?: number
    }): Element {
      const message = new Element({})
      if (data.shapeId != null) {
        message.shapeId = data.shapeId
      }
      if (data.radius != null) {
        message.radius = data.radius
      }
      if (data.height != null) {
        message.height = data.height
      }
      if (data.fullness != null) {
        message.fullness = data.fullness
      }
      if (data.emptiness != null) {
        message.emptiness = data.emptiness
      }
      if (data.rotation != null) {
        message.rotation = dependency_1.Astronation.Vector3D.fromObject(data.rotation)
      }
      if (data.position != null) {
        message.position = dependency_1.Astronation.Vector3D.fromObject(data.position)
      }
      if (data.composite != null) {
        message.composite = data.composite.map((item) => Compound.fromObject(item))
      }
      if (data.elements != null) {
        message.elements = data.elements.map((item) => Element.fromObject(item))
      }
      if (data.peh != null) {
        message.peh = PEH.fromObject(data.peh)
      }
      if (data.cfgId != null) {
        message.cfgId = data.cfgId
      }
      return message
    }
    toObject() {
      const data: {
        shapeId?: number
        radius?: number
        height?: number
        fullness?: number
        emptiness?: number
        rotation?: ReturnType<typeof dependency_1.Astronation.Vector3D.prototype.toObject>
        position?: ReturnType<typeof dependency_1.Astronation.Vector3D.prototype.toObject>
        composite?: ReturnType<typeof Compound.prototype.toObject>[]
        elements?: ReturnType<typeof Element.prototype.toObject>[]
        peh?: ReturnType<typeof PEH.prototype.toObject>
        cfgId?: number
      } = {}
      if (this.shapeId != null) {
        data.shapeId = this.shapeId
      }
      if (this.radius != null) {
        data.radius = this.radius
      }
      if (this.height != null) {
        data.height = this.height
      }
      if (this.fullness != null) {
        data.fullness = this.fullness
      }
      if (this.emptiness != null) {
        data.emptiness = this.emptiness
      }
      if (this.rotation != null) {
        data.rotation = this.rotation.toObject()
      }
      if (this.position != null) {
        data.position = this.position.toObject()
      }
      if (this.composite != null) {
        data.composite = this.composite.map((item: Compound) => item.toObject())
      }
      if (this.elements != null) {
        data.elements = this.elements.map((item: Element) => item.toObject())
      }
      if (this.peh != null) {
        data.peh = this.peh.toObject()
      }
      if (this.cfgId != null) {
        data.cfgId = this.cfgId
      }
      return data
    }
    serialize(): Uint8Array
    serialize(w: pb_1.BinaryWriter): void
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
      const writer = w || new pb_1.BinaryWriter()
      if (this.shapeId != 0) writer.writeUint64(1, this.shapeId)
      if (this.radius != 0) writer.writeDouble(2, this.radius)
      if (this.height != 0) writer.writeDouble(3, this.height)
      if (this.fullness != 0) writer.writeDouble(4, this.fullness)
      if (this.emptiness != 0) writer.writeDouble(5, this.emptiness)
      if (this.has_rotation) writer.writeMessage(6, this.rotation, () => this.rotation.serialize(writer))
      if (this.has_position) writer.writeMessage(7, this.position, () => this.position.serialize(writer))
      if (this.composite.length) writer.writeRepeatedMessage(8, this.composite, (item: Compound) => item.serialize(writer))
      if (this.elements.length) writer.writeRepeatedMessage(9, this.elements, (item: Element) => item.serialize(writer))
      if (this.has_peh) writer.writeMessage(10, this.peh, () => this.peh.serialize(writer))
      if (this.cfgId != 0) writer.writeInt64(11, this.cfgId)
      if (!w) return writer.getResultBuffer()
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Element {
      const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes),
        message = new Element()
      while (reader.nextField()) {
        if (reader.isEndGroup()) break
        switch (reader.getFieldNumber()) {
          case 1:
            message.shapeId = reader.readUint64()
            break
          case 2:
            message.radius = reader.readDouble()
            break
          case 3:
            message.height = reader.readDouble()
            break
          case 4:
            message.fullness = reader.readDouble()
            break
          case 5:
            message.emptiness = reader.readDouble()
            break
          case 6:
            reader.readMessage(message.rotation, () => (message.rotation = dependency_1.Astronation.Vector3D.deserialize(reader)))
            break
          case 7:
            reader.readMessage(message.position, () => (message.position = dependency_1.Astronation.Vector3D.deserialize(reader)))
            break
          case 8:
            reader.readMessage(message.composite, () =>
              pb_1.Message.addToRepeatedWrapperField(message, 8, Compound.deserialize(reader), Compound)
            )
            break
          case 9:
            reader.readMessage(message.elements, () =>
              pb_1.Message.addToRepeatedWrapperField(message, 9, Element.deserialize(reader), Element)
            )
            break
          case 10:
            reader.readMessage(message.peh, () => (message.peh = PEH.deserialize(reader)))
            break
          case 11:
            message.cfgId = reader.readInt64()
            break
          default:
            reader.skipField()
        }
      }
      return message
    }
    serializeBinary(): Uint8Array {
      return this.serialize()
    }
    static deserializeBinary(bytes: Uint8Array): Element {
      return Element.deserialize(bytes)
    }
  }
  export class Compound extends pb_1.Message {
    #one_of_decls: number[][] = []
    constructor(
      data?:
        | any[]
        | {
            share?: number
            number?: number
            count?: number
          }
    ) {
      super()
      pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls)
      if (!Array.isArray(data) && typeof data == 'object') {
        if ('share' in data && data.share != undefined) {
          this.share = data.share
        }
        if ('number' in data && data.number != undefined) {
          this.number = data.number
        }
        if ('count' in data && data.count != undefined) {
          this.count = data.count
        }
      }
    }
    get share() {
      return pb_1.Message.getFieldWithDefault(this, 1, 0) as number
    }
    set share(value: number) {
      pb_1.Message.setField(this, 1, value)
    }
    get number() {
      return pb_1.Message.getFieldWithDefault(this, 2, 0) as number
    }
    set number(value: number) {
      pb_1.Message.setField(this, 2, value)
    }
    get count() {
      return pb_1.Message.getFieldWithDefault(this, 3, 0) as number
    }
    set count(value: number) {
      pb_1.Message.setField(this, 3, value)
    }
    static fromObject(data: { share?: number; number?: number; count?: number }): Compound {
      const message = new Compound({})
      if (data.share != null) {
        message.share = data.share
      }
      if (data.number != null) {
        message.number = data.number
      }
      if (data.count != null) {
        message.count = data.count
      }
      return message
    }
    toObject() {
      const data: {
        share?: number
        number?: number
        count?: number
      } = {}
      if (this.share != null) {
        data.share = this.share
      }
      if (this.number != null) {
        data.number = this.number
      }
      if (this.count != null) {
        data.count = this.count
      }
      return data
    }
    serialize(): Uint8Array
    serialize(w: pb_1.BinaryWriter): void
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
      const writer = w || new pb_1.BinaryWriter()
      if (this.share != 0) writer.writeDouble(1, this.share)
      if (this.number != 0) writer.writeDouble(2, this.number)
      if (this.count != 0) writer.writeDouble(3, this.count)
      if (!w) return writer.getResultBuffer()
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Compound {
      const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes),
        message = new Compound()
      while (reader.nextField()) {
        if (reader.isEndGroup()) break
        switch (reader.getFieldNumber()) {
          case 1:
            message.share = reader.readDouble()
            break
          case 2:
            message.number = reader.readDouble()
            break
          case 3:
            message.count = reader.readDouble()
            break
          default:
            reader.skipField()
        }
      }
      return message
    }
    serializeBinary(): Uint8Array {
      return this.serialize()
    }
    static deserializeBinary(bytes: Uint8Array): Compound {
      return Compound.deserialize(bytes)
    }
  }
  export class PEH extends pb_1.Message {
    #one_of_decls: number[][] = []
    constructor(
      data?:
        | any[]
        | {
            power?: number
            energy?: number
            heat?: number
          }
    ) {
      super()
      pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls)
      if (!Array.isArray(data) && typeof data == 'object') {
        if ('power' in data && data.power != undefined) {
          this.power = data.power
        }
        if ('energy' in data && data.energy != undefined) {
          this.energy = data.energy
        }
        if ('heat' in data && data.heat != undefined) {
          this.heat = data.heat
        }
      }
    }
    get power() {
      return pb_1.Message.getFieldWithDefault(this, 1, 0) as number
    }
    set power(value: number) {
      pb_1.Message.setField(this, 1, value)
    }
    get energy() {
      return pb_1.Message.getFieldWithDefault(this, 2, 0) as number
    }
    set energy(value: number) {
      pb_1.Message.setField(this, 2, value)
    }
    get heat() {
      return pb_1.Message.getFieldWithDefault(this, 3, 0) as number
    }
    set heat(value: number) {
      pb_1.Message.setField(this, 3, value)
    }
    static fromObject(data: { power?: number; energy?: number; heat?: number }): PEH {
      const message = new PEH({})
      if (data.power != null) {
        message.power = data.power
      }
      if (data.energy != null) {
        message.energy = data.energy
      }
      if (data.heat != null) {
        message.heat = data.heat
      }
      return message
    }
    toObject() {
      const data: {
        power?: number
        energy?: number
        heat?: number
      } = {}
      if (this.power != null) {
        data.power = this.power
      }
      if (this.energy != null) {
        data.energy = this.energy
      }
      if (this.heat != null) {
        data.heat = this.heat
      }
      return data
    }
    serialize(): Uint8Array
    serialize(w: pb_1.BinaryWriter): void
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
      const writer = w || new pb_1.BinaryWriter()
      if (this.power != 0) writer.writeDouble(1, this.power)
      if (this.energy != 0) writer.writeDouble(2, this.energy)
      if (this.heat != 0) writer.writeDouble(3, this.heat)
      if (!w) return writer.getResultBuffer()
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): PEH {
      const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes),
        message = new PEH()
      while (reader.nextField()) {
        if (reader.isEndGroup()) break
        switch (reader.getFieldNumber()) {
          case 1:
            message.power = reader.readDouble()
            break
          case 2:
            message.energy = reader.readDouble()
            break
          case 3:
            message.heat = reader.readDouble()
            break
          default:
            reader.skipField()
        }
      }
      return message
    }
    serializeBinary(): Uint8Array {
      return this.serialize()
    }
    static deserializeBinary(bytes: Uint8Array): PEH {
      return PEH.deserialize(bytes)
    }
  }
}
