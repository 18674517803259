import React, { useEffect, useRef, useState } from 'react'
import { ElementController } from './ElementController'
import * as THREE from 'three'
import { ThreeEvent } from '@react-three/fiber'
import { Element, ElementBody } from '../../../domain/state/Element'

interface BodyOfElementsProps {
  body?: ElementBody
  rotation?: THREE.Quaternion
  position?: THREE.Vector3
  size?: number
  onClick?: (event: ThreeEvent<MouseEvent>) => void
}

export function BodyOfElements({
  body,
  position,
  rotation,
  size,
  onClick
}: BodyOfElementsProps) {
  if (!body || !body.elements) return
  const groupRef = useRef<THREE.Group>(null!)

  const [scale, setScale] = useState(1)
  const [bounds, setBounds] = useState<THREE.Box3>(new THREE.Box3())

  const bodyRotation = rotation ?? new THREE.Quaternion().identity()
  const bodyPosition = position ?? new THREE.Vector3()

  useEffect(() => {
    if (size && size > 0) {
      var result = new THREE.Box3()

      const updateBounds = (element: Element) => {
        result = result.expandByPoint(
          new THREE.Vector3(element.position.x - element.height, element.position.y - element.height, element.position.z - element.height)
        )
        result = result.expandByPoint(
          new THREE.Vector3(element.position.x + element.height, element.position.y + element.height, element.position.z + element.height)
        )
        for (var i = 0; i < element.elements.length; i++) {
          updateBounds(element.elements[i])
        }
      }

      for (var i = 0; i < body.elements.length; i++) {
        updateBounds(body.elements[i])
      }

      setBounds(result)
      setScale(size / result.min.distanceTo(result.max))
    }
  }, [body, size])

  return (
    <group ref={groupRef} onClick={onClick} scale={scale} quaternion={bodyRotation}>
      {body.elements.map((element, index) => (
        <ElementController
          key={`element-controller-${index}`}
          parentScale={1}
          parentRotation={new THREE.Quaternion().identity()}
          parentPosition={bodyPosition}
          element={element}
        />
      ))}
    </group>
  )
}
