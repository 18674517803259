import React, { useMemo } from 'react'
import * as THREE from 'three'
import { useLoader } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { config } from '../../../config'
import { Star } from '../../../shaders/Star'

export function Shape({
  id,
  scale,
  rotation,
  position
}: {
  id: string
  scale: number
  rotation: THREE.Quaternion
  position: THREE.Vector3
}) {
  if (!id) return <></>

  if (id == '2eaf7543-3c5c-4cd1-b7e9-f24b2ab26838')
    return (
      <group scale={scale} quaternion={rotation} position={position}>
        <Star />
      </group>
    )

  const groupRef = React.useRef<THREE.Group>(null)

  useMemo(() => {
    groupRef.current?.position.set(position.x, position.y, position.z)  
    groupRef.current?.quaternion.set(rotation.x, rotation.y, rotation.z, rotation.w)
    groupRef.current?.scale.set(scale, scale, scale)
  }, [rotation, position, scale])

  const gltf = useLoader(GLTFLoader, config.shape(id))
  return (
    <group ref={groupRef} scale={scale} quaternion={rotation} position={position}>
      <primitive 
        object={gltf.scene.clone()} 
        receiveShadow={true}
        castShadow={true}
      />
    </group>
  )
}
