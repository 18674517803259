import React from 'react'
import styles from './RotationControls.module.scss'
import clsx from 'clsx'
import { config } from '../../../../config'

type Rotation = [number, number, number]

export interface RotationControlsProps {
  options: {
    thumbnail: string
    rotation: Rotation
  }[]
  selectedOption: number
  onChange: (rotationIndex: number) => void
  className?: string
}

export const getVehicleRotationOptions = (vehicleId: string): RotationControlsProps['options'] => {
  const vehicleThumbnailImage = (uuid: string, angle?: number) => {
    const suffix = angle ? `detail_${angle}` : 'min'
    return `${config.statics}/si/${uuid}/${uuid}_${suffix}.png`
  }

  return [
    { thumbnail: vehicleThumbnailImage(vehicleId, 1), rotation: [0, -Math.PI / 2, 0] },
    { thumbnail: vehicleThumbnailImage(vehicleId, 3), rotation: [0, 0, 0] },
    {
      thumbnail: vehicleThumbnailImage(vehicleId, 2),
      rotation: [Math.PI / 2, Math.PI / 2, 0]
    },
    { thumbnail: vehicleThumbnailImage(vehicleId), rotation: [Math.PI / 6, Math.PI / 4, 0] },
    {
      thumbnail: vehicleThumbnailImage(vehicleId, 4),
      rotation: [-Math.PI / 4, -Math.PI / 4, 0]
    }
  ]
}

export const RotationControls = ({
  options,
  selectedOption,
  onChange,
  className
}: RotationControlsProps) => {
  return (
    <div className={clsx(styles.rotationControls, className)}>
      <ul>
        {options.map((option, index) => (
          <li
            key={option.rotation.toString()}
            className={clsx({ [styles.selected]: selectedOption === index })}
            onClick={() => onChange(index)}
            role="button"
          >
            <img src={option.thumbnail} alt={`Rotation ${index + 1}`} />
          </li>
        ))}
      </ul>
    </div>
  )
}
