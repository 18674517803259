import React, { useEffect, useState } from 'react'
import styles from './ScrollDots.module.scss'
import {
  getSectionsCount,
  scrollToSection,
  useScrollContainerContentResize
} from '../../../../helpers/scroll'
import clsx from 'clsx'
import { useLocation } from 'react-router-dom'
import { useActiveSection } from './useActiveSection'

export const ScrollDots = () => {
  const activeSection = useActiveSection()
  const [sectionsCount, setSectionsCount] = useState(0)

  const location = useLocation()

  useEffect(() => {
    setSectionsCount(getSectionsCount())
  }, [location])

  useScrollContainerContentResize(() => {
    setSectionsCount(getSectionsCount())
  })

  const renderDotsList = (side: 'left' | 'right') => (
    <div className={clsx(styles.dotsContainer, styles[side])}>
      <div className={styles.dots}>
        {Array.from({ length: sectionsCount }, (_, sectionIndex) => (
          <div
            key={[side, sectionIndex].join('-')}
            className={clsx(styles.dot, sectionIndex === activeSection && styles.active)}
            onClick={() => scrollToSection(sectionIndex)}
          />
        ))}
      </div>
    </div>
  )

  if (sectionsCount === 0) {
    return null
  }

  return (
    <>
      {renderDotsList('left')}
      {renderDotsList('right')}
    </>
  )
}
