import React, { ReactNode } from 'react'
import styles from './ContentSection.module.scss'
import clsx from 'clsx'
import { Link } from 'react-router-dom'

export interface RelatedItem {
  id: string
  link: string
  image: string
  name: string
}

interface RelatedProps {
  visible?: boolean
  kind?: string
  items: RelatedItem[]
}

export const Related = ({ visible, kind, items }: RelatedProps) => {
  if (!visible) return

  return (
    <div className={clsx(styles.related)}>
      <h4>{kind}</h4>
      <ul>
        {items.map((r) => {
          return (
            <li key={r.id}>
              <Link to={r.link}>
                <img src={r.image} alt={r.name} title={r.name} />
                <span>{r.name}</span>
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
