import React, { useEffect, useState } from 'react'
import { Container, Image } from '@react-three/uikit'
import { SessionContext } from '../../../utils/session/SessionProvider'
import { statics } from '../../../api/Statics'
import { config } from '../../../config'

export default function AccountStatus() {
  const { user } = React.useContext(SessionContext)

  const [useDesoProfile, setUseDesoProfile] = useState(false)

  const profileImageUri = useDesoProfile
    ? `${config.desoNode}/api/v0/get-single-profile-picture/${user?.deso ?? ''}`
    : statics.defaultProfileImageUri

  useEffect(() => {
    const hasDesoProfile = !!user?.deso
    setUseDesoProfile(hasDesoProfile)
  }, [user?.deso])

  return (
    <Container justifyContent="center" alignContent="center">
      <Container width={64} height={64} justifyContent="center" borderRadius={32} padding={2}>
        <Image src={profileImageUri} borderRadius={32} />
      </Container>

      <Container width={64} height={64} justifyContent="center" marginLeft={-64}>
        <Image src={statics.accountStatusBgUri} />
      </Container>
    </Container>
  )
}
