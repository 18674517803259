import React, { useRef } from 'react'
import { ErrorPage } from './ErrorPage'
import { Button } from '../../ui/layout/controls/button/Button'
import { Link } from 'react-router-dom'
import { routes } from '../../router'

const NotFoundPage = () => {
  return (
    <ErrorPage code='404' text='Page not found'>
      <Link to={routes.home}>
        <Button variant='primary' size='large' >Open homepage</Button>
      </Link>
    </ErrorPage>
  )
}

export default NotFoundPage
