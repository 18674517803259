import React, { ReactNode } from 'react'
import { SessionContext } from './SessionProvider'
import { Navigate } from 'react-router-dom'
import { routes } from '../../router'

export default function AuthenticatedRoute({
  element,
  loginOnError: redirectOnError
}: {
  element: ReactNode
  loginOnError?: boolean
}) {
  const sessionCtx = React.useContext(SessionContext)

  if (sessionCtx?.identified) {
    return element
  } else {
    return redirectOnError ? <Navigate to={routes.login} replace /> : <Navigate to={routes.unauthenticated} replace />
  }
}
