import * as THREE from 'three'
import { Orbit } from './movement/Orbit'
import { Exclude, Expose, Transform, Type } from 'class-transformer'
import { DXYZ, XYZ } from '../Common'
import { Astronation } from '../protobuf/state'
import { BigDecimal } from '../../math/BigDecimal'
import Decimal from 'decimal.js'
import { AbilityKind, State } from './State'
import { Linear } from './movement/Linear'

export class Dock {
  @Expose()
  parentId!: string

  @Expose()
  position!: DXYZ

  constructor(parentId: string, position: DXYZ) {
    this.parentId = parentId
    this.position = position
  }

  static translate(state: State, target: Decimal) {
    if (state.translation.dock) {
      const timeOffset = target.sub(state.second)
      const acceleration = state.activation(AbilityKind.Thrusters)
      const deceleration = state.activation(AbilityKind.Brakes)
      
      const { moved, velocity } = Linear.getTranslationParams(state.translation, timeOffset, acceleration, deceleration)

      state.translation.dock.position.x = state.translation.dock.position.x.add(moved.x)
      state.translation.dock.position.y = state.translation.dock.position.y.add(moved.y)
      state.translation.dock.position.z = state.translation.dock.position.z.add(moved.z)
      state.translation.velocity.x = velocity.x
      state.translation.velocity.y = velocity.y
      state.translation.velocity.z = velocity.z
    }
  }

  static fromProtobuf(input: Astronation.Dock): Dock | undefined {
    if (input.parentId && input.parentId.length == 0) return undefined
    let output = { 
      parentId: input.parentId, 
      position: new DXYZ(
        new Decimal(input.position.x), 
        new Decimal(input.position.y), 
        new Decimal(input.position.z)
      ),
    }
    return output
  }
}

export class Translation {
  @Expose()
  coordinates!: DXYZ

  @Expose()
  velocity!: DXYZ

  @Expose()
  acceleration!: DXYZ

  @Expose()
  @Type(() => XYZ)
  @Transform(
    ({ value }) => (value ? new THREE.Quaternion().setFromEuler(new THREE.Euler(value.x, value.y, value.z, 'YXZ')) : new THREE.Quaternion().identity()),
    { toClassOnly: true }
  )
  rotation!: THREE.Quaternion

  @Expose()
  @Type(() => XYZ)
  @Transform(
    ({ value }) => (value ? new THREE.Quaternion().setFromEuler(new THREE.Euler(value.x, value.y, value.z, 'YXZ')) : new THREE.Quaternion().identity()),
    { toClassOnly: true }
  )
  revolution!: THREE.Quaternion

  @Type(() => Orbit)
  @Expose()
  orbit?: Orbit

  @Type(() => Dock)
  @Expose()
  dock?: Dock

  constructor(
    coordinates: DXYZ,
    velocity: DXYZ,
    acceleration: DXYZ,
    rotation: THREE.Quaternion,
    revolution: THREE.Quaternion,
    orbit?: Orbit,
    dock?: Dock
  ) {
    this.coordinates = coordinates
    this.velocity = velocity
    this.acceleration = acceleration
    this.rotation = rotation
    this.revolution = revolution
    this.orbit = orbit
    this.dock = dock
  }

  static fromProtobuf(input: Astronation.Translation): Translation {
    const output: Translation = {
      coordinates: {
        x: BigDecimal.ToJsNumber(input.coordinates.x), 
        y: BigDecimal.ToJsNumber(input.coordinates.y), 
        z: BigDecimal.ToJsNumber(input.coordinates.z)
      },
      velocity: {
        x: BigDecimal.ToJsNumber(input.velocity.x), 
        y: BigDecimal.ToJsNumber(input.velocity.y), 
        z: BigDecimal.ToJsNumber(input.velocity.z)
      },
      acceleration: {
        x: BigDecimal.ToJsNumber(input.acceleration.x), 
        y: BigDecimal.ToJsNumber(input.acceleration.y), 
        z: BigDecimal.ToJsNumber(input.acceleration.z)
      },
      rotation: new THREE.Quaternion().setFromEuler(new THREE.Euler(input.rotation.x, input.rotation.y, input.rotation.z, 'YXZ')),
      revolution: new THREE.Quaternion().setFromEuler(new THREE.Euler(input.revolution.x, input.revolution.y, input.revolution.z, 'YXZ')),
      orbit: Orbit.fromProtobuf(input.orbit),
      dock: Dock.fromProtobuf(input.dock)
    }
  
    return output
  }
}
