import React, { useEffect, useState } from 'react'
import {
  getScrollContainer,
  useScrollContainerContentResize,
  useScrollContainerRef
} from '../../../helpers/scroll'
import styles from './Overlay.module.scss'
import clsx from 'clsx'

export const BackToTop = () => {
  const scrollContainerRef = useScrollContainerRef()
  const [isScrollable, setIsScrollable] = useState(false)

  const scrollToTop = () => {
    scrollContainerRef.current?.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const [hasReachedBottom, setHasReachedBottom] = useState(false)

  useScrollContainerContentResize(() => {
    const scrollContainer = getScrollContainer()
    const scrollHeight = scrollContainer?.scrollHeight ?? 0
    const clientHeight = scrollContainer?.clientHeight ?? 0

    setIsScrollable(scrollHeight > clientHeight)
  })

  useEffect(() => {
    const handleScroll = ({ target }: Event) => {
      if (target === scrollContainerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = target as HTMLElement
        setHasReachedBottom(scrollTop + clientHeight >= scrollHeight)
      }
    }

    scrollContainerRef.current?.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      scrollContainerRef.current?.removeEventListener('scroll', handleScroll)
    }
  }, [scrollContainerRef])

  return (
    <div className={clsx(styles.backToTop, { [styles.visible]: isScrollable })}>
      {hasReachedBottom ? (
        <span style={{ cursor: 'pointer' }} onClick={scrollToTop}>
          Back to top
        </span>
      ) : (
        <span>Scroll</span>
      )}
    </div>
  )
}
