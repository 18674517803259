import { Exclude, Expose } from 'class-transformer'
import Decimal from 'decimal.js'

export class DXYZ {
  @Expose()
  x!: Decimal
  @Expose()
  y!: Decimal
  @Expose()
  z!: Decimal

  constructor(x: Decimal = new Decimal(0), y: Decimal = new Decimal(0), z: Decimal = new Decimal(0)) {
    this.x = x
    this.y = y
    this.z = z
  }

  static add(left: DXYZ, right: DXYZ): DXYZ {
    return new DXYZ(
      left.x.add(right.x),
      left.y.add(right.y),
      left.z.add(right.z)
    )
  }

  static mulScalar(param: DXYZ, value: Decimal): DXYZ {
    return new DXYZ(
      param.x.mul(value),
      param.y.mul(value),
      param.z.mul(value)
    )
  }

  static length(param: DXYZ): number {
    return Math.sqrt(Math.pow(param.x.toNumber(), 2) + Math.pow(param.y.toNumber(), 2) + Math.pow(param.z.toNumber(), 2))
  }

  static normalized(param: DXYZ): DXYZ {
    const length = new Decimal(DXYZ.length(param))
    if (length.isPos()) {
      return new DXYZ(
        param.x.div(length),
        param.y.div(length),
        param.z.div(length)
      )
    }
    
    return new DXYZ()
  }
  static isZero(param: DXYZ): boolean {
    return param.x.isZero() && param.y.isZero() && param.z.isZero()
  }
}

export class XYZ {
  @Expose()
  x!: number
  @Expose()
  y!: number
  @Expose()
  z!: number
}

export class XYZT {
  @Expose()
  x!: number
  @Expose()
  y!: number
  @Expose()
  z!: number
  @Expose()
  t!: number
}

export class WXYZ {
  @Expose()
  w!: number
  @Expose()
  x!: number
  @Expose()
  y!: number
  @Expose()
  z!: number
}