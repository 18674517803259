import React, { useRef, useState } from 'react'
import styles from './CurrentVehicle.module.scss'
import { EntityPreview, EntityPreviewRef } from '../../layout/previews/entity/EntityPreview'
import { Button } from '../../layout/controls/button/Button'
import { ContentSection } from '../../layout/sections/content/ContentSection'
import { Link } from 'react-router-dom'
import { routes } from '../../../router'
import { SurfaceDataWithMeta } from '../../helpers/session/useSurfaceData'
import { labelForVehicleKind } from '../../helpers/labels'
import {
  getVehicleRotationOptions,
  RotationControls
} from '../../layout/controls/rotation/RotationControls'
import { ElementBody } from '../../../domain/state/Element'

interface CurrentVehicleProps {
  visible: boolean
  mainSurface?: SurfaceDataWithMeta
  body?: ElementBody
  id: string
}

export const CurrentVehicle = ({ mainSurface, body, visible, id }: CurrentVehicleProps) => {
  if (!visible) return

  const ref = useRef<EntityPreviewRef>(null)
  const [selected, setSelected] = useState<number>(3) //  default rotation

  const rotationOptions = getVehicleRotationOptions(mainSurface!.guid)

  return (
    <>
      <RotationControls
        className={styles.controls}
        options={rotationOptions}
        selectedOption={selected}
        onChange={(index) => {
          setSelected(index)
          if (index === selected) {
            ref.current?.resetRotation()
          }
        }}
      />
      <div className={styles.cvContainer}>
        <div className={styles.preview}>
          <div className={styles.previewWrapper}>
            <EntityPreview ref={ref} rotation={rotationOptions[selected].rotation} body={body!} />
          </div>
        </div>

        <div className={styles.details}>
          <ContentSection title='Current vehicle' subtitle={mainSurface!.name}></ContentSection>

          <div className={styles.buttons}>
            <Link to={routes.simulator}>
              <Button variant='secondary' size='medium'>
                Board the ship
              </Button>
            </Link>
            
            <Link to={routes.configurationFor(id)}>
              <Button variant='secondary' size='medium'>
                Configure
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}
