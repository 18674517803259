import React from 'react'
import { ErrorPage } from './ErrorPage'
import { Link } from 'react-router-dom'
import { routes } from '../../router'
import { Button } from '../../ui/layout/controls/button/Button'

export const UnauthenticatedPage: React.FC = () => {
  
  return <ErrorPage code='401' text='Unauthenticated'>
    <Link to={routes.login}>
      <Button variant='primary' size='large' >Proceed to login</Button>
    </Link>
  </ErrorPage>
}

