import React from 'react'
import styles from './Spinner.module.scss'
import clsx from 'clsx'

interface SpinnerProps {
  visible: boolean
}

export const Spinner = ({ visible }: SpinnerProps) => {
  if (!visible) return <></>

  return (
    <>
      <div className={clsx(styles.spinnerContainer)}>
        <div className={styles.spinner} />
      </div>
    </>
  )
}
