import { Canvas } from '@react-three/fiber'
import { EffectComposer, HueSaturation } from '@react-three/postprocessing'
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { BlendFunction } from 'postprocessing'
import { Quaternion, Vector3 } from 'three'
import { PresentationControls } from '@react-three/drei'
import { FloatingGroup } from '../../../helpers/3d/FloatingGroup'
import styles from './EntityPreview.module.scss'
import { ElementBody } from '../../../../domain/state/Element'
import { BodyOfElements } from '../../../parts/elements/BodyOfElements'

export interface EntityPreviewRef {
  resetRotation: () => void
}

export type EntityPreviewProps = {
  body: ElementBody
  rotation: [number, number, number]
}

export const EntityPreview = forwardRef<EntityPreviewRef, EntityPreviewProps>((props, ref) => {
  if (!props.body || !props.body!.elements) return null

  const [rotation, setRotation] = useState<[number, number, number] | undefined>(props.rotation)

  useImperativeHandle(ref, () => {
    return {
      resetRotation() {
        // force rotation re-alignment by changing rotation to different one for a short time
        setRotation([props.rotation[0], props.rotation[1] + 0.01, props.rotation[2]])
        setTimeout(() => setRotation(props.rotation), 0)
      }
    }
  }, [props.rotation])

  useEffect(() => {
    setRotation(props.rotation)
  }, [props.rotation])

  return (
    <div className={styles.entityPreview}>
      <Canvas
        dpr={[1,2]}
        camera={{
          position: [0, 0, 75],
          fov: 1
        }}
        gl={{ localClippingEnabled: true, alpha: true }}
        resize={{ scroll: false, offsetSize: true }}
      >
        <ambientLight intensity={0.2} />

        <EffectComposer>
          <HueSaturation blendFunction={BlendFunction.NORMAL} hue={0} saturation={0} />
        </EffectComposer>

        <directionalLight position={[10, 10, 10]} intensity={5} />

        <PresentationControls global cursor={false} rotation={rotation} polar={[-Math.PI, Math.PI]}>
          <FloatingGroup>
            <BodyOfElements
              body={props.body}
              size={3}
              rotation={new Quaternion().identity()}
              position={new Vector3()}
            />
          </FloatingGroup>
        </PresentationControls>
      </Canvas>
    </div>
  )
})
