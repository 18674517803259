import { useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useInterval } from 'usehooks-ts'

export const SCROLL_CONTAINER_ID = 'overlayScrollContainerId'
export const getScrollContainer = () => document.getElementById(SCROLL_CONTAINER_ID)

export const getSectionClassName = (boundType: 'top' | 'bottom' = 'top') =>
  `scroll-section--${boundType}`

const getSectionElement = (sectionIndex: number) => {
  return document.querySelectorAll(`.${getSectionClassName()}`)[sectionIndex] as HTMLElement
}

export const getAllSectionsElements = () => document.querySelectorAll(`.${getSectionClassName()}`)

const areSectionsDefined = () => getAllSectionsElements().length > 0

export const getDynamicScrollCount = () => {
  const scrollContainer = getScrollContainer()
  const scrollHeight = scrollContainer?.scrollHeight ?? 0
  const clientHeight = scrollContainer?.clientHeight ?? 0

  if (scrollHeight <= clientHeight * 2) {
    return 0
  }

  return Math.min(4, Math.floor(scrollHeight / clientHeight))
}

const scrollToHeightPercentage = (percentage: number) => {
  const scrollContainer = getScrollContainer()
  const scrollHeight = scrollContainer?.scrollHeight ?? 0

  return scrollContainer?.scrollTo({ top: scrollHeight * percentage, behavior: 'smooth' })
}

export const scrollToSection = (index: number) => {
  const scrollContainer = getScrollContainer()

  if (index === 0) {
    return scrollContainer?.scrollTo({ top: 0, behavior: 'smooth' })
  }

  if (areSectionsDefined()) {
    return getSectionElement(index)?.scrollIntoView({ behavior: 'smooth' })
  }

  return scrollToHeightPercentage(index / getDynamicScrollCount())
}

export const getSectionsCount = () => {
  if (areSectionsDefined()) {
    return getAllSectionsElements().length
  } else {
    return getDynamicScrollCount()
  }
}

export const useScrollContainerRef = () => {
  const scrollContainerRef = useRef<HTMLElement>()

  useEffect(() => {
    const scrollContainer = getScrollContainer()
    if (scrollContainer) {
      scrollContainerRef.current = scrollContainer
    }
  }, [])

  return scrollContainerRef
}

export const recalculateScrollHeight = () => {
  // force resize observer
  const scrollContainer = getScrollContainer()
  if (scrollContainer) {
    scrollContainer.style.paddingBottom = '0.1px'
    setTimeout(() => {
      scrollContainer.style.paddingBottom = ''
    }, 100)
  }
}

export const useScrollContainerContentResize = (resizeHandler: () => void) => {
  useInterval(resizeHandler, 3000)

  useEffect(() => {
    const scrollContainer = getScrollContainer()
    if (scrollContainer) {
      const resizeObserver = new ResizeObserver(resizeHandler)
      resizeObserver.observe(scrollContainer)
      return () => {
        resizeObserver.unobserve(scrollContainer)
      }
    }
  }, [resizeHandler])
}

export const useScrollToTopOnNavigate = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    getScrollContainer()?.scrollTo({ top: 0, behavior: 'instant' })
  }, [pathname])
}
