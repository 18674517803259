import { Dock, Translation } from './Translation'
import { ElementBody } from './Element'
import { Action } from './Action'
import * as THREE from 'three'
import { Exclude, Expose, Transform, Type } from 'class-transformer'
import Decimal from 'decimal.js'
import { Astronation } from '../protobuf/state'
import { BigDecimal } from '../../math/BigDecimal'
import { DXYZ } from '../Common'
import { Linear } from './movement/Linear'
import { Orbit } from './movement/Orbit'

export enum Flag {
  NeedData = 1,
  RespawnBody
}

export enum AbilityKind {
  Thrusters = 1,
  Brakes = 2
}

export class Activation {
  @Expose()
  @Transform(({ value }) => AbilityKind[value] || value, { toPlainOnly: true })
  @Transform(({ value }) => AbilityKind[value], { toClassOnly: true })
  ability!: AbilityKind

  @Type(() => THREE.Vector3)
  @Expose()
  magnitude!: THREE.Vector3
}

export class EntityConfigData {
  @Expose()
  id!: number

  @Expose()
  version!: number

  @Expose()
  service!: number

  @Expose()
  kind!: number

  @Expose()
  data!: string
}

@Expose()
export class State {
  @Expose()
  id!: string

  @Expose()
  @Transform(({ value }) => (value ? new Decimal(value) : new Decimal(0)), { toClassOnly: true })
  @Transform(({ obj }) => obj.distancePerAttempt.toNumber(), { toPlainOnly: true })
  second!: Decimal

  @Type(() => Translation)
  @Expose()
  translation!: Translation

  @Type(() => ElementBody)
  @Expose()
  body!: ElementBody

  @Type(() => EntityConfigData)
  @Expose()
  cfg?: EntityConfigData[]

  @Type(() => Activation)
  @Expose()
  act?: Activation[]

  constructor(
    id: string,
    second: Decimal,
    body: ElementBody,
    translation: Translation,
    cfg?: EntityConfigData[],
    act?: Activation[]
  ) {
    this.id = id
    this.second = new Decimal(second)
    this.body = body
    this.translation = translation
    this.cfg = cfg
    this.act = act
  }

  activation(kind: AbilityKind) {
    const activation = this.act?.find((a) => (a.ability == kind))
    if (activation) {
      return {
        x: new Decimal(activation.magnitude.x * -1),
        y: new Decimal(activation.magnitude.y * -1),
        z: new Decimal(activation.magnitude.z * -1)
      }
    }
    return new DXYZ()
  }

  static fromProtobuf(input: Astronation.State): State {
    let output: State = new State(
      input.id, 
      BigDecimal.ToJsNumber(input.second),
      ElementBody.fromProtobuf(input.body),
      Translation.fromProtobuf(input.translation),
      input.configs.map((c) => ({ id: c.id, service: c.service, version: c.version, kind: c.kind, data: c.data })),
      input.activations.map((a) => ({ ability: a.ability, magnitude: new THREE.Vector3(a.magnitude.x, a.magnitude.y, a.magnitude.z) }))
    )
    return output
  }

  static updateTo(target: Decimal, state: State) {
    if (target.greaterThan(state.second)) {
      
      if (state.translation.dock) {
        Dock.translate(state, target)
      } else if (state.translation.orbit) {
        state.translation.orbit.updateTo(target)
      } else Linear.translate(state, target)
    }
  }
}
