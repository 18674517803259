import React from 'react'
import styles from './Checkout.module.scss'
import { ContentSection } from '../../layout/sections/content/ContentSection'
import { Button } from '../../layout/controls/button/Button'
import { StoreOffer } from '../../../api/NodeApi'
import { SurfaceDataWithMeta } from '../../helpers/session/useSurfaceData'
import { labelForVehicleKind } from '../../helpers/labels'
import { InfoTable } from '../../layout/infoTable/InfoTable'

interface CheckoutDetailsProps {
  offers: StoreOffer[]
  visible: boolean
  surfaceInfo: SurfaceDataWithMeta
  onBuyPress: () => void
}

export const CheckoutDetails = ({
  visible,
  offers,
  surfaceInfo,
  onBuyPress
}: CheckoutDetailsProps) => {
  if (!visible) return

  const defaultOffer = offers?.find((i) => i.kind == 1)

  const vehicleInfoEntries = [
    { label: 'Length', value: `${Math.round(surfaceInfo.height)} m` },
    { label: 'Mass', value: `${Math.round(surfaceInfo.mass)} kg` },
    { label: 'Energy', value: `${Math.round(surfaceInfo.energy)} C` },
    { label: 'Radius', value: `${Math.round(surfaceInfo.radius)} m` },
    { label: 'Power', value: `${Math.round(surfaceInfo.power / 1e3)} kPa` },
    { label: 'Heat', value: `${Math.round(surfaceInfo.heat)} K` }
  ]

  return (
    <div>
      <ContentSection
        title={surfaceInfo.name}
        subtitle={labelForVehicleKind(surfaceInfo.kind)}
        sidetitle={defaultOffer?.priceString}
        description={surfaceInfo.meta?.description}
      >
        <hr/>
        <h4>Details</h4>
        <InfoTable items={vehicleInfoEntries} />
      </ContentSection>
      <Button variant='primary' size='large' className={styles.cta} onClick={onBuyPress}>
        BUY {surfaceInfo.name}
      </Button>
    </div>
  )
}
