import React from 'react'
import styles from './Checkout.module.scss'
import { PaymentStatus } from './PurchaseOptions'

interface PaymentFailedProps {
  visible: boolean
  paymentStatus: PaymentStatus
}

export const PaymentFailed = ({ visible, paymentStatus }: PaymentFailedProps) => {
  if (!visible) return

  return <h3 className={styles.purchased}>{paymentStatus}</h3>
}
