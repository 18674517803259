import React, { useContext } from 'react'
import { CheckoutsCarousel } from './CheckoutsCarousel'
import { config } from '../../../config'
import { PlanetCarousel } from './PlanetCarousel'
import { FooterSection } from '../../layout/sections/footer/FooterSection'
import { ScrollSection } from '../../layout/sections/scroll/ScrollSection'
import { Link } from 'react-router-dom'
import { routes } from '../../../router'
import { Button } from '../../layout/controls/button/Button'
import { SessionContext } from '../../../utils/session/SessionProvider'

const checkouts: string[] = [
  '2e08fc34-60e5-4095-8744-a3c7f5f0a180',
  '8faaf3cf-47ac-4dd9-b5e8-31d6338fa06a',
  '8c9489bc-0fce-47b0-8e8b-e58248940763',
  '3f994707-a365-453a-b4e3-bb8090c2f79e',
  '061b8499-ce7b-4e02-aefc-2f74ba3bc687'
]

export const Homepage = () => {
  const logoHeaderUrl = config.image('logo/a-logo-header.png')
  const { session } = useContext(SessionContext)
  const link = session ? routes.vehicles : routes.login
  const text = session ? 'Board your spaceship' : 'Get a free spaceship'
  return (
    <div className="container">
      <ScrollSection>
        <img src={logoHeaderUrl} alt="Astronation" className="logo" />
        <h1>Join the Galactic Space Squadron!</h1>
        <p>Get ready to blast off into a universe of adventure with your very own spacecraft!</p>
        <Link to={link}>
          <Button variant="primary" size="large">
            {text}
          </Button>
        </Link>
      </ScrollSection>
      <ScrollSection>
        <CheckoutsCarousel ids={checkouts} />
      </ScrollSection>

      <ScrollSection>
        <h2>Explore the vast universe of Astronation!</h2>
        <p>
          Travel to any planet or moon in the PE31 star system and discover a world of
          possibilities, where you can farm, produce and create like never before.
          <br />
          <strong>Explore, trade, fight, transport, or recover - it's up to you!</strong>
        </p>
        <PlanetCarousel />
      </ScrollSection>

      <FooterSection />
    </div>
  )
}
