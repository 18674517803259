import React from 'react'
import styles from './Tabs.module.scss'
import clsx from 'clsx'
import { Link, Outlet, useLocation } from 'react-router-dom'

export interface TabsProps {
  links: { label: string; path: string }[]
  allowVertical: boolean
}

export const Tabs = ({ links, allowVertical }: TabsProps) => {
  const pathname = useLocation().pathname
  const activeTab = links.find((link) => pathname === link.path)

  return (
    <div className={clsx(styles.tabContainer, { [styles.verticalLayout]: allowVertical })}>
      <ul className={styles.switch}>
        {links.map((link) => (
          <li key={link.label} className={clsx(styles.tab, link === activeTab && styles.active)}>
            <Link to={link.path}>{link.label}</Link>
          </li>
        ))}
      </ul>

      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  )
}
