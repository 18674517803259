import { useLoader, useThree } from '@react-three/fiber'
import { EquirectangularReflectionMapping, SRGBColorSpace, TextureLoader } from 'three'
import { statics } from '../../../api/Statics'
import { useEffect } from 'react'

export default function Skybox() {
  const texture = useLoader(TextureLoader, statics.skyboxBetaUri)
  const { scene } = useThree()

  useEffect(() => {
    texture.mapping = EquirectangularReflectionMapping
    texture.colorSpace = SRGBColorSpace
    scene.background = texture

    return () => {
      scene.background = null
      texture.dispose()
    }
  }, [texture, scene])

  return null
}
