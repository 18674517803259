import React, { useEffect, useState } from 'react'
import { State } from '../../../domain/state/State'
import * as THREE from 'three'
import { ThreeEvent } from '@react-three/fiber'
import { nodeApi } from '../../../api/NodeApi'
import { SessionContext } from '../../../utils/session/SessionProvider'
import { BodyOfElements } from '../elements/BodyOfElements'

interface EntityControllerProps {
  id: string
  rotation?: THREE.Quaternion
  position?: THREE.Vector3
  size: number
  onClick?: (event: ThreeEvent<MouseEvent>) => void
}

export function EntityController({ id, rotation, position, size, onClick }: EntityControllerProps) {
  const { session, user } = React.useContext(SessionContext)

  const entityRotation = rotation ?? new THREE.Quaternion().identity()
  const entityPosition = position ?? new THREE.Vector3()

  const [entity, setEntity] = useState<State | undefined>(undefined)

  useEffect(() => {
    const loadEntity = async () => {
      nodeApi.getEntity(id, user?.system).then(
        (response) => {
          setEntity(response)
        },
        (reason) => {
          console.error(`Failed fetching entity ${id}`, reason)
        }
      )
    }

    loadEntity()
  }, [])

  if (!entity) return null

  return (
    <BodyOfElements
      body={entity.body}
      size={size}
      rotation={entityRotation}
      position={entityPosition}
      onClick={onClick}
    />
  )
}
