import React from 'react'
import { useParams } from 'react-router-dom'
import { SessionContext } from '../../utils/session/SessionProvider'
import AccountStatus from '../parts/account/AccountStatus'
import Overlay from '../../utils/interface/Overlay'
import TopRow from '../../utils/interface/TopRow'
import MouseShaker from '../helpers/3d/MouseShaker'
import { EntityController } from '../parts/entities/EntityController'

export default function EntityPreview() {
  const params = useParams()
  if (!params.id) {
    console.log('Entity preview requires id to be provided')
  }

  return (
    <>
      <Overlay>
        <TopRow>
          <AccountStatus />
        </TopRow>
      </Overlay>
      <MouseShaker distance={0.2}>
        <EntityController id={params.id!} size={10} />
      </MouseShaker>
    </>
  )
}
