import React, { useContext } from 'react'
import styles from './Profile.module.scss'
import stylesContent from '../../layout/sections/content/ContentSection.module.scss'
import { ContentSection } from '../../layout/sections/content/ContentSection'
import { SessionContext } from '../../../utils/session/SessionProvider'
import { ScrollSection } from '../../layout/sections/scroll/ScrollSection'

export const ProfileInfo = () => {
  const { user } = useContext(SessionContext)

  const profileImage = user?.deso
    ? `https://node.deso.org/api/v0/get-single-profile-picture/${user?.deso}?fallback=https://desolabs.org/assets/img/default_profile_pic.png`
    : 'https://desolabs.org/assets/img/default_profile_pic.png'

  return (
      <ScrollSection>
        <ContentSection className={styles.profileContent}>
          <h1><span>Profile information</span></h1>
          <div className={stylesContent.columnsFixed}>
            <div>
              <div className={styles.infoRow}>
                <div className={styles.label}>Nickname:</div>
                <div className={styles.value}>{user?.nick ?? '-'}</div>
              </div>

              <div className={styles.infoRow}>
                <div className={styles.label}>User ID:</div>
                <div className={styles.value}>{user?.id ?? '-'}</div>
              </div>
            </div>
            <div className={styles.avatar}>
              <img src={profileImage} alt="Avatar" />
            </div>
          </div>
        </ContentSection>
      </ScrollSection>
  )
}
