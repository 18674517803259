import React from 'react'
import { createBrowserRouter } from 'react-router-dom'
import PageNotFound from './utils/errors/PageNotFound'
import EntityPreview from './ui/pages/EntityPreview'
import AuthenticatedRoute from './utils/session/AuthenticatedRoute'
import SystemMap from './ui/pages/systemMap/SystemMap'
import Constructor from './ui/pages/Constructor'
import Simulator from './ui/pages/simulator/Simulator'
import ErrorView from './utils/errors/ErrorView'
import { Profile } from './ui/pages/profile/Profile'
import SessionProvider from './utils/session/SessionProvider'
import WorldSocketProvider from './utils/session/WorldSocketProvider'
import { Stations } from './ui/pages/stations/Stations'
import { Login } from './ui/pages/login/Login'
import { Frame } from './ui/layout/frame/Frame'
import { CelestialDetails } from './ui/pages/celestials/CelestialDetails'
import { Homepage } from './ui/pages/homepage/Homepage'
import { PrivacyPolicy } from './ui/pages/legal/PrivacyPolicy'
import { TermsOfService } from './ui/pages/legal/TermsOfService'
import { ShopifyListing } from './ui/pages/store/ShopifyListing'
import { Store } from './ui/pages/store/Store'
import { ShipsListing } from './ui/pages/store/ShipsListing'
import { Vehicles } from './ui/pages/vehicles/Vehicles'
import { Checkout } from './ui/pages/checkout/Checkout'
import { UnauthenticatedPage } from './utils/errors/Unauthenticated'
import { ProfileInfo } from './ui/pages/profile/ProfileInfo'
import { ProfileStats } from './ui/pages/profile/ProfileStats'
import { ProfileLinkedAccounts } from './ui/pages/profile/ProfileLinkedAccounts'
import { Configure } from './ui/pages/configure/Configure'
import FrameForCanvas from './ui/layout/frame/FrameForCanvas'

export const routes = {
  home: '/',
  systemMap: '/system-map',
  shape: '/shape/:id',
  entity: '/entity/:id',
  entityFor: (id: string) => `/entity/${id}`,
  constructor: '/constructor',
  configuration: '/config/:id',
  configurationFor: (id: string) => `/config/${id}`,
  simulator: '/simulator',
  vehicles: '/vehicles',
  stations: '/stations',
  profile: '/profile/info',
  profileInfo: '/profile/info',
  profileStats: '/profile/stats',
  profileLinked: '/profile/linked',
  profileValidated: '/profile/validated',
  storeDefault: '/store',
  storeOther: '/store/other',
  unauthenticated: '/need-auth',
  login: '/login',
  terms: '/legal/terms',
  privacy: '/legal/privacy',
  celestial: '/celestials/:id',
  celestialFor: (id: string) => `/celestials/${id}`,
  checkout: '/checkout/:id',
  checkoutFor: (id: string) => `/checkout/${id}`,
}

export const router = createBrowserRouter([
  {
    element: <SessionProvider />,
    errorElement: <ErrorView />,
    children: [
      {
        element: <Frame />,
        children: [
          { path: routes.home, element: <Homepage /> },
          { path: routes.login, element: <Login /> },
          { path: routes.unauthenticated, element: <UnauthenticatedPage /> },
          { path: routes.privacy, element: <PrivacyPolicy /> },
          { path: routes.terms, element: <TermsOfService /> },
          {
            element: <Store />,
            children: [
              { path: routes.storeDefault, element: <ShipsListing /> },
              { path: routes.storeOther, element: <ShopifyListing /> }
            ]
          },
          { path: routes.checkout, element: <Checkout /> },
          { path: routes.configuration, element: <Configure /> },
          {
            path: routes.vehicles,
            element: <AuthenticatedRoute loginOnError element={<Vehicles />} />
          },
          {
            element: <AuthenticatedRoute loginOnError element={<Profile />} />,
            children: [
              { path: routes.profileInfo, element: <ProfileInfo /> },
              { path: routes.profileStats, element: <ProfileStats /> },
              { path: routes.profileLinked, element: <ProfileLinkedAccounts /> },
            ]
          },
          {
            path: routes.stations,
            element: <AuthenticatedRoute loginOnError element={<Stations />} />
          },
          
          { path: routes.systemMap, element: <SystemMap /> },
          { path: routes.celestial, element: <CelestialDetails /> },
        ]
      },
      {
        element: <FrameForCanvas />,
        children: [
          { path: routes.entity, element: <AuthenticatedRoute loginOnError element={<EntityPreview />} /> },
          { path: routes.constructor, element: <AuthenticatedRoute loginOnError element={<Constructor />} /> },

          {
            element: <WorldSocketProvider />,
            children: [
              { path: routes.simulator, element: <AuthenticatedRoute loginOnError element={<Simulator />} /> }
            ]
          }
        ]
      },
      {
        element: <Frame />,
        children: [
          { path: '*', element: <PageNotFound /> }
        ]
      }
    ]
  }
])
