import { animated } from '@react-spring/three'
import { Html } from '@react-three/drei'
import { Canvas } from '@react-three/fiber'
import { Bloom, EffectComposer, FXAA, HueSaturation, N8AO } from '@react-three/postprocessing'
import React, { ReactNode, useState } from 'react'
import { Vector3, Quaternion } from 'three'
import { Slide } from './Slide'
import styles from './BodyCarousel.module.scss'
import { BlendFunction } from 'postprocessing'
import { FloatingGroup } from '../../../helpers/3d/FloatingGroup'
import { useCarousel } from '../../../helpers/useCarousel'
import clsx from 'clsx'
import { ElementBody } from '../../../../domain/state/Element'
import { BodyOfElements } from '../../../parts/elements/BodyOfElements'
import { Spinner } from '../../controls/spinner/Spinner'
import { RotatingGroup } from '../../../helpers/3d/RotatingGroup'

export interface RenderOverlayProps {
  id: string
  isActive: boolean
  body: ElementBody
  index: number
  overlayProps: {
    onClick: () => void
    onMouseEnter: () => void
    onMouseLeave: () => void
  }
}

export interface IdWithBody {
  id: string
  data: ElementBody
}

export interface BodyCarouselProps {
  bodies: IdWithBody[]
  size: number
  overlayOnTop?: boolean
  cameraDistance?: number
  slideOffset?: number
  overflowCount?: number
  modelPosition?: Vector3
  isFloating?: boolean
  isRotating?: boolean
  height: number
  prefix: string
  className?: string
  renderHtmlOverlay: (args: RenderOverlayProps) => ReactNode
}

export const BodyCarousel = ({
  bodies,
  size = 1,
  renderHtmlOverlay,
  overlayOnTop = false,
  cameraDistance = 75,
  slideOffset = 1.2,
  overflowCount = 2,
  isFloating = false,
  isRotating = false,
  modelPosition,
  className,
  height,
  prefix
}: BodyCarouselProps) => {
  const [activeIndex, setActiveIndex] = useState(1)
  const [hoveredIndex, setHoveredIndex] = useState<number>()

  const { position, slides, slideDistance } = useCarousel({
    items: bodies,
    activeIndex,
    slideOffset,
    overflowCount
  })

  if (bodies.length == 0)
    return (
      <div
        className={styles.bodyCarouselPlaceholder}
        style={{ touchAction: 'none', height: `${height}px`, width: '100%' }}
      >
        <Spinner visible={true} />
      </div>
    )

  const modelProps = {
    position: modelPosition ?? new Vector3(0, 0, 0),
    rotation: new Quaternion().identity()
  }

  return (
    <div className={clsx(className, styles.bodyCarousel)}>
      <Canvas
        className={clsx({ [styles.bottomOverlayContainer]: !overlayOnTop })}
        dpr={[1,2]}
        shadows="basic"
        camera={{ position: [0, 0, cameraDistance], fov: 1 }}
        style={{ touchAction: 'none', height: `${height}px`, width: '100%' }}
        gl={{ localClippingEnabled: true, alpha: true, antialias: false }}
        resize={{ scroll: false, offsetSize: true }}
      >
        <ambientLight intensity={Math.PI / 42} />

        <animated.group position={position.to((x, y, z) => [x, y, z])}>
          {slides.map(({ data, index }) => {
            const body = data.data
            const id = data.id
            const isActive = activeIndex === index
            return (
              <Slide
                key={`${prefix}-${index}`}
                position={new Vector3((index - 1) * slideDistance, 0.05, 0)}
                isActive={isActive}
                isHovered={hoveredIndex === index}
              >
                <FloatingGroup paused={!isActive || !isFloating}>
                  <RotatingGroup paused={!isActive || !isRotating}>
                    <group rotation={[0.4, -0.1 * (index - activeIndex), 0]}>
                      <BodyOfElements body={body} size={size} {...modelProps} />
                    </group>
                  </RotatingGroup>
                </FloatingGroup>

                <Html zIndexRange={overlayOnTop ? undefined : [0, 0]}>
                  {renderHtmlOverlay({
                    id,
                    isActive,
                    body,
                    index,
                    overlayProps: {
                      onClick: () => setActiveIndex(index),
                      onMouseEnter: () => setHoveredIndex(index),
                      onMouseLeave: () => setHoveredIndex(undefined)
                    }
                  })}
                </Html>
              </Slide>
            )
          })}
        </animated.group>
        
        <EffectComposer>
          <N8AO aoRadius={0.5} intensity={1} />
          <Bloom luminanceThreshold={1} intensity={0.5} levels={9} mipmapBlur />
        </EffectComposer>
      </Canvas>
    </div>
  )
}
