import { Expose, Transform, Type } from 'class-transformer'
import * as THREE from 'three'
import { XYZT } from '../Common'

export enum EffectKind {
  Explode = 1
}

export class Effect {
  @Expose()
  @Transform(({ value }) => EffectKind[value] || value, { toPlainOnly: true })
  @Transform(({ value }) => EffectKind[value], { toClassOnly: true })
  kind!: EffectKind

  @Expose()
  @Type(() => XYZT)
  @Transform(
    ({ value }) =>
      value ? new THREE.Vector4(value.x, value.y, value.z, value.t) : new THREE.Vector4(),
    { toClassOnly: true }
  )
  size!: THREE.Vector4
}
