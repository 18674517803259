import React, { useContext } from 'react'
import styles from './Profile.module.scss'
import stylesContent from '../../layout/sections/content/ContentSection.module.scss'
import { ContentSection } from '../../layout/sections/content/ContentSection'
import { StatsBadge, StatKind, getLevelProgress } from '../../layout/statsBadge/StatsBadge'
import { queryUserStats } from '../../../api/queries'
import Progress from '../../layout/controls/progress/Progress'

export const ProfileStats = () => {
  const { data: stats } = queryUserStats()
  
  const kills = stats?.kills ?? 0
  const levelProgress = getLevelProgress(kills)

  const nextLevelKills = levelProgress.max
  const nextLevelProgress = levelProgress.percentage * 100

  return (
      <ContentSection className={styles.profileContent}>
        <h1><span>Statistics</span></h1>
        <div className={stylesContent.columns}>
          <div className={styles.statsRows}>
            <div className={styles.statsRow}>
              <div className={styles.label}>Experience</div>
              <div className={stylesContent.innerBox}>
                <StatsBadge stat={stats?.experience ?? 0} kind={StatKind.Experience} />
              </div>
            </div>

            <div className={styles.statsRow}>
              <div className={styles.label}>Reputation</div>
              <div className={stylesContent.innerBox}>
                <StatsBadge stat={stats?.reputation ?? 0} kind={StatKind.Reputation} />
              </div>
            </div>

            <div className={styles.statsRow}>
              <div className={styles.label}>Destructions</div>
              <div className={stylesContent.innerBox}>
                <Progress value={kills} max={nextLevelKills} progress={nextLevelProgress} />
              </div>
            </div>
          </div>
          <div className={styles.details}>
            <div className={styles.label}>Details</div>
            <textarea className={styles.box}></textarea>
          </div>
        </div>
      </ContentSection>
  )
}
