import { useMutation, useQueries, useQuery } from '@tanstack/react-query'
import { nodeApi } from './NodeApi'
import { useContext } from 'react'
import { SessionContext } from '../utils/session/SessionProvider'
import { config } from '../config'
import { State } from '../domain/state/State'

export const queryOwnedVehiclesIds = () => {
  const { session, user } = useContext(SessionContext)

  return useQuery({
    staleTime: 60 * 1000,
    queryKey: ['owned-vehicles'],
    queryFn: () => nodeApi.getUserVehicles(session ?? '', user?.system ?? config.defaults.system)
  })
}

export const queryStations = () => {
  const { session, user } = useContext(SessionContext)

  return useQuery({
    staleTime: 5 * 60 * 1000,
    queryKey: ['stations'],
    queryFn: () => nodeApi.getUserStations(session ?? '', user?.system ?? config.defaults.system)
  })
}

export const queryEntity = (entityId: string) => {
  const { user } = useContext(SessionContext)

  return useQuery({
    staleTime: 60 * 1000,
    queryKey: ['entity', entityId],
    queryFn: () => nodeApi.getEntity(entityId, user?.system ?? config.defaults.system)
  })
}

export const queryEntities = (entityIds: string[]) => {
  const { user } = useContext(SessionContext)

  return useQueries({
    queries: entityIds.map((entityId) => ({
      staleTime: 60 * 1000,
      queryKey: ['entity', entityId],
      queryFn: () => nodeApi.getEntity(entityId, user?.system ?? config.defaults.system)
    })),
    combine: (results) => {
      const data: Record<string, State> = {}
      results.forEach((result) => {
        if (!result.data) return
        data[result.data.id] = result.data
      })

      return {
        data,
        isFetching: results.some((result) => result.isFetching)
      }
    }
  })
}

export const queryOnboardEntity = () => {
  const { user, session } = useContext(SessionContext)

  return useMutation({
    mutationFn: (entityId: string) =>
      nodeApi.onboardEntity(entityId, session ?? '', user?.system ?? config.defaults.system)
  })
}

export const queryUserStats = () => {
  const { session, user } = useContext(SessionContext)

  return useQuery({
    staleTime: 60 * 1000,
    queryKey: ['user-stats', user?.id ?? 0],
    queryFn: () => nodeApi.getUserStats(session ?? '', user?.system ?? config.defaults.system)
  })
}

export const queryStoreVehicles = () => {
  const { user } = useContext(SessionContext)

  return useQuery({
    staleTime: 60 * 60 * 1000,
    queryKey: ['store-vehicles'],
    queryFn: () => nodeApi.getStoreVehicles(user?.system ?? config.defaults.system)
  })
}

export const queryCheckout = (id: string) => {
  const { user } = useContext(SessionContext)

  return useQuery({
    staleTime: 60 * 60 * 1000,
    queryKey: ['checkout', id],
    queryFn: () => nodeApi.getCheckout(id, user?.system ?? config.defaults.system)
  })
}

export const queryCheckouts = (checkoutIds: string[]) => {
  const { user } = useContext(SessionContext)

  return useQueries({
    queries: checkoutIds.map((checkoutId) => ({
      staleTime: 60 * 60 * 1000,
      queryKey: ['checkout-wif-id', checkoutId],
      queryFn: () =>
        nodeApi
          .getCheckout(checkoutId, user?.system ?? config.defaults.system)
          .then((value) => ({ checkoutId, value }))
    })),
    combine: (results) => {
      const data: Record<string, any> = {}
      results.forEach((result) => {
        if (!result.data) return
        data[result.data.checkoutId] = result.data.value
      })

      return {
        data,
        isFetching: results.some((result) => result.isFetching)
      }
    }
  })
}
