import React from 'react'
import styles from './InfoTable.module.scss'

export const InfoTable = ({ items }: { items: { label: string; value: string }[] }) => (
  <ul className={styles.infoTable}>
    {items.map((info) => (
      <li key={info.label}>
        <strong>{info.label}</strong>
        {info.value}
      </li>
    ))}
  </ul>
)
