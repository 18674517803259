import Decimal from 'decimal.js'

export class BasePhysics {
  public static readonly PI = new Decimal(3.1415926535897932384)
  public static readonly TAU = BasePhysics.PI.mul(2)
  public static readonly dTAU = BasePhysics.TAU.toNumber()

  public static readonly G = 66743e-15

  public static readonly C = new Decimal(299792458000)
  public static readonly C2 = BasePhysics.C.mul(2)
}
