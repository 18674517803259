import React, { ReactNode } from 'react'
import styles from './ContentSection.module.scss'
import clsx from 'clsx'
import { Related, RelatedItem } from './Related'
import { FavoriteToggle } from '../../controls/favoriteToggle/FavoriteToggle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

interface ContentSectionProps {
  children?: ReactNode
  className?: string
  primary?: boolean

  caption?: string
  title?: string
  subtitle?: string
  sidetitle?: string
  description?: string

  relatedKind?: string
  related?: RelatedItem[]

  enabledFavorite?: boolean
  isFavorite?: boolean
  enabledBack?: boolean
  handleBackPress?: () => void
}

export const ContentSection = ({
  children,
  className,
  primary,

  caption,
  title,
  subtitle,
  sidetitle,
  description,

  relatedKind,
  related,

  enabledFavorite,
  isFavorite,
  enabledBack,
  handleBackPress
}: ContentSectionProps) => {
  const showTopRow = caption || enabledFavorite || enabledBack
  const showHeader = title || subtitle || sidetitle
  const showRelated = !!related && related.length > 0

  return (
    <section
      className={clsx(styles.content, className, {
        [styles.primary]: primary ?? true
      })}
    >
      <div className={clsx(styles.topRow, { hidden: !showTopRow })}>
        <FontAwesomeIcon
          icon={faArrowLeft}
          size="xl"
          className={clsx(styles.back, { hidden: !enabledBack })}
          onClick={handleBackPress}
        />
        <span>{caption}</span>
        <FavoriteToggle
          visible={enabledFavorite}
          className={styles.favoriteIcon}
          isFavorite={isFavorite ?? false}
        />
      </div>
      <div className={clsx(styles.header, { hidden: !showHeader })}>
        <div className={clsx(styles.titles, { hidden: !title && !subtitle })}>
          <h2 className={clsx({ hidden: !title })}>{title}</h2>
          <h4 className={clsx({ hidden: !subtitle })}>{subtitle}</h4>
        </div>
        <p className={clsx({ hidden: !sidetitle })}>{sidetitle}</p>
      </div>
      <p className={clsx(styles.description, { hidden: !description })}>{description}</p>
      {children}
      {!!showRelated && <hr /> }
      <Related visible={showRelated} kind={relatedKind} items={related ?? []} />
    </section>
  )
}
