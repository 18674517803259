import React from 'react'
import styles from './Stations.module.scss'
import { useAllStations } from '../../helpers/session/useAllStations'
import { FloatingGroup } from '../../helpers/3d/FloatingGroup'
import { EntityController } from '../../parts/entities/EntityController'
import { Vector3 } from 'three'
import { Html, View } from '@react-three/drei'
import { RotatingGroup } from '../../helpers/3d/RotatingGroup'
import { ContentSection } from '../../layout/sections/content/ContentSection'
import { Link } from 'react-router-dom'
import { Button } from '../../layout/controls/button/Button'
import { routes } from '../../../router'
import { Filters } from '../../layout/controls/filters/Filters'
import { ViewPortCanvas } from '../../layout/viewPortCanvas/ViewPortCanvas'
import { config } from '../../../config'

export const Stations = () => {
  const [typeFilter, setTypeFilter] = React.useState<'all' | 'mine' | 'shared'>('all')
  const stations = useAllStations()
  const logoHeaderUrl = config.image('logo/a-logo-header.png')

  return (
    <div className="container">
      <img src={logoHeaderUrl} alt="Astronation" className="logo" />
      <h1>Space stations</h1>
      <Filters
        className={styles.tabsContainer}
        activeFilter={typeFilter}
        onFilterChanged={setTypeFilter}
        filters={{
          all: { label: 'All', count: stations.all.length },
          mine: { label: 'Mine', count: stations.mine.length },
          shared: { label: 'Public', count: stations.shared.length }
        }}
      />

      <ViewPortCanvas />
      <div className="container-list">
        {stations[typeFilter].map((station, index) => (
          <div className="row" key={`station-${index}-${station.entityId}`}>
            <div className={styles.previewContainer}>
              <View className={styles.preview}>
                <directionalLight position={[10, 10, 10]} intensity={2} />

                <group rotation={[0.25, 0, -0.1]}>
                  <RotatingGroup speed={-0.05}>
                    <FloatingGroup>
                      <EntityController size={1.5} id={station.entityId} position={new Vector3()} />
                    </FloatingGroup>
                  </RotatingGroup>
                </group>
              </View>
            </div>

            <div className={styles.info}>
              <ContentSection
                title={station.name}
                subtitle={station.meta.header}
                description={station.meta.description}
                enabledFavorite={true}
              ></ContentSection>
              <div className={styles.buttons}>
                <Link to={routes.simulator}>
                  <Button variant='secondary' size='medium'>
                    Visit
                  </Button>
                </Link>
                <Button variant='secondary' size='medium'>
                  Configure
                </Button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
