import { Canvas } from '@react-three/fiber'
import { Bloom, EffectComposer, HueSaturation } from '@react-three/postprocessing'
import { BlendFunction } from 'postprocessing'
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSurfaceData } from '../../helpers/session/useSurfaceData'
import { SessionContext } from '../../../utils/session/SessionProvider'
import { RotatingGroup } from '../../helpers/3d/RotatingGroup'
import { CelestialController } from '../../parts/celestials/CelestialController'
import { ContentSection } from '../../layout/sections/content/ContentSection'
import { routes } from '../../../router'
import { useCarousel } from '../../helpers/useCarousel'
import { animated } from '@react-spring/three'
import { BodyOfElements } from '../../parts/elements/BodyOfElements'
import { Vector3, Quaternion } from 'three'
import { Html } from '@react-three/drei'
import { InfoTable } from '../../layout/infoTable/InfoTable'
import { Button } from '../../layout/controls/button/Button'

export default function SystemMap() {
  const navigate = useNavigate()
  const surfaces = useSurfaceData()

  const [activeIndex, setActiveIndex] = useState(3)
  const [hoveredIndex, setHoveredIndex] = useState<number>()

  const { celestials } = useContext(SessionContext)

  if (!celestials) return <></>

  const [ parent, setParent ] = useState(0)

  const allRocks = Object.values(celestials)
  
  const parentCelestial = celestials[parent]
  const mainCelestials = allRocks.filter((c) => (c.orbit.parentId == parent) && c.id !== parent)

  const { position, slides, slideDistance } = useCarousel({
    items: mainCelestials.map((c) => ({id: c.id, data: c})),
    activeIndex,
    slideOffset: 200,
    overflowCount: 1
  })

  if (!parentCelestial) return <></>

  const modelProps = {
    position: new Vector3(0, 0, 0),
    rotation: new Quaternion().identity()
  }

  return (
    <div className='container'>
      <Canvas
        dpr={[1,2]}
        camera={{
          position: [0, 0, 475],
          fov: 20
        }}
        gl={{ localClippingEnabled: true, alpha: true }}
        resize={{ scroll: false, offsetSize: true }}
        style={{ position: 'absolute', height: '80%', top: '5px'}}
      >
        <ambientLight intensity={Math.PI*1.5} />
  
        <EffectComposer>
          <Bloom mipmapBlur />
          <HueSaturation blendFunction={BlendFunction.NORMAL} hue={0} saturation={0} />
        </EffectComposer>

        <animated.group position={position.to((x, y, z) => [x, y, z])}>
          {slides.map(({ data, index }) => {
            const isActive = activeIndex === index
            const surface = surfaces(data.data.body.elements[0].shapeId)
            const infoTable = [
              { label: 'Radius', value: `${Math.round(surface.radius / 1e3)} km` },
              { label: 'Mass', value: `${Math.round(data.data.orbit.ownMass / 1e21)} ET` },
              { label: 'Power', value: `${Math.round(surface.power / 1e3)} kPa` },
              { label: 'Energy', value: `${Math.round(surface.energy / 1e6)} MC` },
              { label: 'Heat', value: `${Math.round(surface.heat)} K` }
            ]

            return (
                <group position={new Vector3((index - 1) * slideDistance - 100, isActive? 60 : 80, 0)} key={`sys-map-${parent}-${index}`}>
                  <RotatingGroup>
                    <BodyOfElements 
                      body={data.data.body} 
                      size={isActive? 800 : 200}
                      onClick={() => setActiveIndex(index) }
                      {...modelProps} 
                    />
                  </RotatingGroup>
                  {isActive && <Html position={[100, -70, 0]} center>
                    <ContentSection
                      title={surface.name} 
                      subtitle={surface.meta.header}
                      description={surface.meta.description}
                      >
                        <div style={{ width: '640px' }}>
                          <p></p>
                        </div>
                      </ContentSection>
                      
                      <Button 
                            variant='primary'
                            size='large'
                            onClick={() => navigate(routes.celestialFor(data.id.toString()))}>More information</Button>
                  </Html>
                  }
                </group>
            )
          })}
        </animated.group>
      </Canvas>
    </div>
  )
}
