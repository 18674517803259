import { Canvas } from '@react-three/fiber'
import { EffectComposer, HueSaturation } from '@react-three/postprocessing'
import React from 'react'
import { BlendFunction } from 'postprocessing'
import { RotatingGroup } from '../../helpers/3d/RotatingGroup'
import { CelestialController } from '../../parts/celestials/CelestialController'
import { Celestial } from '../../../domain/Celestials'

export const CelestialPreview = ({ celestial }: { celestial: Celestial }) => {
  return (
    <Canvas
      dpr={[1,2]}
      camera={{
        position: [0, 0, 75],
        fov: 1
      }}
      gl={{ localClippingEnabled: true, alpha: true }}
      resize={{ scroll: false, offsetSize: true }}
      style={{height: '540px'}}
    >
      <ambientLight intensity={0.1} />

      <EffectComposer>
        <HueSaturation blendFunction={BlendFunction.NORMAL} hue={0} saturation={0} />
      </EffectComposer>

      <directionalLight position={[10, 0, 2]} intensity={5} />

      <RotatingGroup>
        <CelestialController celestial={celestial} size={3} />
      </RotatingGroup>
    </Canvas>
  )
}
