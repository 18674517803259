import { queryEntities, queryStations } from '../../../api/queries'
import { SurfaceDataWithMeta, useSurfaceData } from './useSurfaceData'

export const useAllStations = () => {
  const { data } = queryStations()

  const myStationsIds = data?.owned ?? []
  const allStationsIds = data?.known ?? []
  const favoriteStationsIds: string[] = []

  const { data: stationsData } = queryEntities(allStationsIds)
  const getMetadata = useSurfaceData()

  const allStations = allStationsIds
    .map((id) => {
      const shapeId = stationsData[id]?.body.elements[0].shapeId
      return shapeId ? { entityId: id, ...getMetadata(shapeId), entity: stationsData[id] } : null
    })
    .filter((station) => !!station)

  const isMyStation = (station: SurfaceDataWithMeta & { entityId: string }) =>
    myStationsIds.includes(station.entityId)

  return {
    all: allStations,
    mine: allStations.filter(isMyStation),
    shared: allStations.filter((station) => !isMyStation(station)),
    isFavorite: (id: string) => favoriteStationsIds.includes(id)
  }
}
