import React from 'react'
import { config } from '../../../config'
import { FooterSection } from '../../layout/sections/footer/FooterSection'
import { Tabs } from '../../layout/tabs/Tabs'

import { routes } from '../../../router'
import { ScrollSection } from '../../layout/sections/scroll/ScrollSection'

export const Store = () => {
  const logoHeaderUrl = config.image('logo/a-logo-header.png')

  return (
    <div className="container">
      <ScrollSection>
        <img src={logoHeaderUrl} alt="Astronation" className="logo" />
        <h1>GSS Store</h1>
      </ScrollSection>
      <Tabs
        links={[
          { label: 'Spaceships', path: routes.storeDefault },
          { label: 'Cool stuff', path: routes.storeOther }
        ]}
        allowVertical={false}
      />

      <FooterSection />
    </div>
  )
}
