import { useFrame } from '@react-three/fiber'
import React from 'react'
import { ReactNode, useRef } from 'react'
import { Group } from 'three'

export const RotatingGroup = ({
  children,
  paused,
  speed
}: {
  children: ReactNode
  paused?: boolean
  speed?: number
}) => {
  const ref = useRef<Group>(null)
  const timeElapsedRef = useRef<number>(0)

  const rotationSpeed = speed ?? -0.15

  useFrame((_, delta) => {
    if (paused) return
    timeElapsedRef.current += delta

    ref.current?.rotation.set(
      ref.current.rotation.x,
      timeElapsedRef.current * rotationSpeed,
      ref.current.rotation.z
    )
  })

  return <group ref={ref}>{children}</group>
}
