import { config } from '../config'

export const statics = {
  logo440Uri: `${config.image('logo/logo_440.png')}`,
  accountStatusBgUri: `${config.image('ui/account-status.png')}`,
  skyboxBetaUri: `${config.image('beta-skybox.jpg')}`,

  defaultProfileImageUri: 'https://desolabs.org/assets/img/default_profile_pic.png',

  energyIconUri: `${config.statics}/a/images/icons/energy_icon.png`,
  heatIconUri: `${config.statics}/a/images/icons/heat_icon.png`,
  powerIconUri: `${config.statics}/a/images/icons/power_icon.png`,

  antennaIconUri: `${config.statics}/a/images/buildings/as-100.png`,
  coinIconUri: `${config.statics}/a/images/icons/astrocoin_icon.png`,
  explosibilityIconUri: `${config.statics}/a/images/icons/explosibility_icon.png`,

  experienceIcon: (lvl: number) => config.image(`ui/menu/stats/exp_icon_${lvl}.png`),
  positiveRepIcon: (lvl: number) => config.image(`ui/menu/stats/pos_rep_icon_${lvl}.png`),
  negativeRepIcon: (lvl: number) => config.image(`ui/menu/stats/neg_rep_icon_${lvl}.png`),

  thumbnailUrl: (surfaceGuid: string) => `${config.statics}/m/${surfaceGuid}/${surfaceGuid}_thumb.png`
}
