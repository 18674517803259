import React, { useState } from 'react'
import styles from './Checkout.module.scss'
import { ContentSection } from '../../layout/sections/content/ContentSection'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import { StoreOffer } from '../../../api/NodeApi'
import { PaymentButtons } from './PaymentButtons'
import { PaymentComplete } from './PaymentComplete'
import { PaymentFailed } from './PaymentFailed'
import { SurfaceDataWithMeta } from '../../helpers/session/useSurfaceData'

export type PaymentStatus = 'complete' | string | null

interface PurchaseOptionsProps {
  checkout: string
  offers: StoreOffer[]
  visible: boolean
  surfaceInfo: SurfaceDataWithMeta
  onBackPress: () => void
}

export const PurchaseOptions = ({
  checkout,
  offers,
  visible,
  surfaceInfo,
  onBackPress
}: PurchaseOptionsProps) => {
  if (!visible || offers.length == 0) return

  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>(null)

  const handleBackPress = () => {
    if (!!paymentStatus && paymentStatus !== 'complete') {
      setPaymentStatus(null)
    } else {
      onBackPress()
    }
  }

  const updateStatus = (paymentStatus: string) => {
    setPaymentStatus(paymentStatus)
  }

  return (
    <ContentSection
      enabledBack={true}
      handleBackPress={handleBackPress}
      caption={surfaceInfo.name}
      className={clsx(styles.payment, { hidden: !visible })}
    >
      <PaymentButtons offers={offers} visible={!paymentStatus} updateStatus={updateStatus} />
      <PaymentComplete visible={paymentStatus === 'complete'} />
      <PaymentFailed paymentStatus={paymentStatus} visible={paymentStatus !== 'complete'} />
    </ContentSection>
  )
}
