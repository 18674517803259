import React from 'react'
import styles from './Checkout.module.scss'

interface PaymentCompleteProps {
  visible: boolean
}

export const PaymentComplete = ({ visible }: PaymentCompleteProps) => {
  if (!visible) return

  return <h3 className={styles.purchased}>Thank you for your purchase!</h3>
}
