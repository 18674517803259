class Config {
  dev = { system: false, website: false }
  defaults = {
    system: 'pe31'
  }

  domain = 'astronation.world'

  paypalClientId =
    'Actfqy70J02eUpDNJyN4AMLPWR53vyA8z3WF0trkehMjZIRw5FVqHbp22kiB7rDIXLWkwUa28p_MFbVg'

  shopify = {
    domain: 'astronation-world.myshopify.com',
    token: 'c88cecd64c7413154dfa032843fc0582'
  }
  nodeUrl = (system?: string) => {
    if (this.dev.system) return `https://${this.node(system)}`
    else return `https://${this.node(system)}`
  }
  node = (system?: string) => {
    if (this.dev.system) return `node.dev.${this.domain}`
    else return `${system ?? this.defaults.system}.${this.domain}`
  }
  statics = `https://statics.${this.domain}`

  desoNode = `https://node.deso.org`

  socket = (system: string) => {
    if (this.dev.system) return `wss://${this.node(system)}/v4/web-socket`
    else return `wss://${this.node(system)}/v4/web-socket`
  }

  image = (imagePath: string) => `${this.statics}/i/${imagePath}`
  shape = (uuid: string) => `${this.statics}/w/${uuid}` // GLB
  shapeMaster = (uuid: string) => `${this.statics}/s/${uuid}` // GLB
  audio = (audioPath: string) => `${this.statics}/ogg/${audioPath}` // OGG

  // local storage keys
  sessionStorageKey = 'jwt'
  surfacesCacheKey = 'surface'
  surfacesMetadataCacheKey = 'surface-metadata'

  coinbaseCheckoutUrl = (storeId: string) => `https://commerce.coinbase.com/checkout/${storeId}`
  nftzCheckoutUrl = (storeId: string) => `${storeId}`
  openseaCheckoutUrl = (storeId: string) => `https://opensea.io/assets//${storeId}`
}

export const config = new Config()
