import React, { useContext } from 'react'
import styles from './PlanetCarousel.module.scss'
import { Button } from '../../layout/controls/button/Button'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../../router'
import { SessionContext } from '../../../utils/session/SessionProvider'
import { BodyCarousel } from '../../layout/carousels/body/BodyCarousel'
import { useSurfaceData } from '../../helpers/session/useSurfaceData'
import clsx from 'clsx'

export const PlanetCarousel = () => {
  const navigate = useNavigate()
  const surfaces = useSurfaceData()

  const { celestials } = useContext(SessionContext)

  const data = Object.values(celestials ?? {})
    .filter((value) => value.orbit.parentId == 0 && !value.orbit.minRadius.isZero())
    .map((celestial) => ({ id: celestial.id.toString(), data: celestial.body }))

  const handleDetailsClick = (id: string) => navigate(routes.celestialFor(id))

  if (!celestials) {
    console.warn('Failed to fetch celestials or surface metadata')
    return
  }

  return (
    <section className={styles.planetCarousel}>
      <BodyCarousel
        bodies={data}
        overlayOnTop={false}
        size={3.2}
        overflowCount={3}
        slideOffset={1.5}
        cameraDistance={130}
        isRotating={true}
        isFloating={false}
        height={540}
        prefix="planets-carousel"
        renderHtmlOverlay={({ id, isActive, body, overlayProps }) => {
          const shapeId = body.elements[0].shapeId
          const surface = surfaces(shapeId)

          return (
            <div
              className={clsx(styles.overlay, {
                [styles.active]: isActive
              })}
              {...overlayProps}
            >
              <div className={styles.info}>
                <h3>{surface.name}</h3>
                <p>{surface.meta.header}</p>
                <div className={styles.link}>
                  <Button variant="secondary" size="small" onClick={() => handleDetailsClick(id)}>
                    MORE INFORMATION
                  </Button>
                </div>
              </div>
            </div>
          )
        }}
      />
    </section>
  )
}
