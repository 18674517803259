import React, { useState } from 'react'
import Header from './Header'
import styles from './OverlayGame.module.scss'
import { isHighEndGpu } from '../../../helpers/isHighEndGpu'
import clsx from 'clsx'
import { SCROLL_CONTAINER_ID, useScrollToTopOnNavigate } from '../../../helpers/scroll'

const CLIP_PATH_ID = 'overlayClipId'
export const OVERLAY_CONTAINER_ID = 'overlayContainerId'

export default function OverlayGame({ children }: { children?: React.ReactNode }) {
  useScrollToTopOnNavigate()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const isGpu = isHighEndGpu()

  return (
    <div className={styles.overlay}>
      <Header isMenuOpen={isMenuOpen} onMenuToggle={setIsMenuOpen} />
    </div>
  )
}
