import { clsx } from 'clsx'
import React from 'react'
import { InputHTMLAttributes, forwardRef } from 'react'
import styles from './Input.module.scss'

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string
  error?: string | null
  inputClassname?: string
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ label, error, className, inputClassname, ...inputProps }, ref) => {
    return (
      <div className={clsx([styles.input, className, { [styles.invalid]: error }])}>
        {label && <label htmlFor={label}>{label}</label>}
        <div>
          <input ref={ref} className={inputClassname} {...inputProps} />
        </div>
        {error && <span className={styles.error}>{error}</span>}
      </div>
    )
  }
)

Input.displayName = Input.name
