import React, { useState } from 'react'
import AuthToken from './AuthToken'
import ValidateToken from './ValidateToken'
import { config } from '../../../config'
import styles from './Login.module.scss'

export function Login() {
  const [token, setToken] = useState<string>()

  const logoHeaderUrl = config.image('logo/a-logo-header.png')
  return (
    <div className={styles.login}>
      <section>
        <img src={logoHeaderUrl} alt="Astronation" className={styles.logo} />
        <AuthToken isVisible={!token} setToken={setToken} />
        <ValidateToken token={token} />
      </section>
    </div>
  )
}
