import { Canvas } from '@react-three/fiber'
import { EffectComposer, HueSaturation } from '@react-three/postprocessing'
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { BlendFunction } from 'postprocessing'
import { Quaternion, Vector3 } from 'three'
import { PresentationControls } from '@react-three/drei'
import { FloatingGroup } from '../../../helpers/3d/FloatingGroup'
import styles from './ElementPreview.module.scss'
import { Element } from '../../../../domain/state/Element'
import { Shape } from '../../../parts/elements/Shape'
import { useSurfaceData } from '../../../helpers/session/useSurfaceData'

export interface ElementPreviewRef {
  resetRotation: () => void
}

export type ElementPreviewProps = {
  element: Element
  rotation: [number, number, number]
}

export const ElementPreview = forwardRef<ElementPreviewRef, ElementPreviewProps>((props, ref) => {
  if (!props.element) return null

  const surfaces = useSurfaceData()
  const guid = surfaces(props.element.shapeId).guid
  const [rotation, setRotation] = useState<[number, number, number] | undefined>(props.rotation)

  useImperativeHandle(ref, () => {
    return {
      resetRotation() {
        // force rotation re-alignment by changing rotation to different one for a short time
        setRotation([props.rotation[0], props.rotation[1] + 0.01, props.rotation[2]])
        setTimeout(() => setRotation(props.rotation), 0)
      }
    }
  }, [props.rotation])

  useEffect(() => {
    setRotation(props.rotation)
  }, [props.rotation])

  return (
    <div className={styles.entityPreview}>
      <Canvas
        dpr={[1,2]}
        camera={{
          position: [0, 0, 75],
          fov: 1
        }}
        gl={{ localClippingEnabled: true, alpha: true }}
        resize={{ scroll: false, offsetSize: true }}
      >
        <ambientLight intensity={0.2} />

        <EffectComposer>
          <HueSaturation blendFunction={BlendFunction.NORMAL} hue={0} saturation={0} />
        </EffectComposer>

        <directionalLight position={[10, 10, 10]} intensity={5} />

        <PresentationControls global cursor={false} rotation={rotation} polar={[-Math.PI, Math.PI]}>
          <FloatingGroup>
            <Shape id={guid} scale={1} position={new Vector3()} rotation={new Quaternion().identity()} />
          </FloatingGroup>
        </PresentationControls>
      </Canvas>
    </div>
  )
})
