import React from 'react'
import styles from './StationsCarousel.module.scss'
import { BodyCarousel, RenderOverlayProps } from '../../layout/carousels/body/BodyCarousel'
import clsx from 'clsx'
import { Button } from '../../layout/controls/button/Button'
import { Vector3 } from 'three'
import { useSurfaceData } from '../../helpers/session/useSurfaceData'
import { labelForVehicleKind } from '../../helpers/labels'
import { VehicleEntity } from '../../helpers/session/useOwnedVehicles'
import { useAllStations } from '../../helpers/session/useAllStations'
import { ElementBody } from '../../../domain/state/Element'
import { State } from '../../../domain/state/State'

interface StationsCarouselProps {
  stations: State[]
}

export const StationsCarousel = ({
  stations
}: StationsCarouselProps) => {
  if (stations.length == 0) return

  const surfaceData = useSurfaceData()
  const allStations = useAllStations()

  const bodies = stations.map((e) => ({
    id: e.id,
    data: e.body
  }))

  const renderOverlay = ({ id, body, isActive, overlayProps }: RenderOverlayProps) => {
    return (
      <div
        className={clsx(styles.overlay, {
          [styles.active]: isActive
        })}
        {...overlayProps}
      >
        <div className={styles.content}>
          {isActive && 
            <Button variant='secondary' size='small'>Visit</Button>
          }
        </div>
      </div>
    )
  }

  return (
    <div className={styles.ovContainer}>
      <h2>Known stations</h2>
      <BodyCarousel
        overlayOnTop={false}
        slideOffset={1}
        size={1}
        prefix="other-vehicles"
        bodies={bodies}
        height={400}
        modelPosition={new Vector3(0, 0.13, 0)}
        renderHtmlOverlay={renderOverlay}
      />
    </div>
  )
}
