import Decimal from "decimal.js";
import { AbilityKind, State } from "../State";
import { DXYZ } from "../../Common";
import { Translation } from "../Translation";

export interface TranlationParams {
    moved: DXYZ
    velocity: DXYZ
}

export class Linear {

    static getTranslationParams(translation: Translation, timeOffset: Decimal, acceleration: DXYZ, deceleration: DXYZ): TranlationParams {
        const accelerationInTime = {
            x: acceleration.x.mul(timeOffset),
            y: acceleration.y.mul(timeOffset),
            z: acceleration.z.mul(timeOffset)
        }

        const decelerationNorm: Decimal = new Decimal(DXYZ.length(deceleration))
        const decelerationNormInTime = decelerationNorm.mul(timeOffset)

        const velocityAccelerated = DXYZ.add(translation.velocity, accelerationInTime)
        const velocityAcceleratedNorm = new Decimal(DXYZ.length(velocityAccelerated))

        const velocityDirection = DXYZ.isZero(translation.velocity) ? new DXYZ() : DXYZ.normalized(translation.velocity)
        const velocityReduction = DXYZ.mulScalar(velocityDirection, decelerationNormInTime.mul(-1))

        const effectiveReduction = (decelerationNormInTime > velocityAcceleratedNorm)? velocityAcceleratedNorm.div(decelerationNormInTime) : new Decimal(1)
        

        // Calculate moved offset
        const moveByVelocity = DXYZ.mulScalar(translation.velocity, timeOffset)
        const timeDerivative = (timeOffset.mul(2)).div(2)
        const moveByAcceleration = DXYZ.mulScalar(accelerationInTime, timeDerivative)

        const moved = DXYZ.add(moveByVelocity, moveByAcceleration)
        const movedNorm = new Decimal(DXYZ.length(moved))

        const braked = decelerationNorm.isPositive()? (() => {
            const moveDirection = DXYZ.isZero(moved) ? new DXYZ() : DXYZ.normalized(moved)
            const brakesAcceleration = DXYZ.mulScalar(moveDirection, decelerationNorm.mul(-1))
            const moveByBrakes = DXYZ.mulScalar(brakesAcceleration, timeDerivative)
            const moveByBrakesNorm = new Decimal(DXYZ.length(moveByBrakes))

            const effectiveBrakes = (moveByBrakesNorm > movedNorm) ? movedNorm.div(moveByBrakesNorm) : new Decimal(1)

            return DXYZ.mulScalar(moveByBrakes, effectiveBrakes)
        })() : new DXYZ()

        return {
            moved: DXYZ.add(moved, braked),
            velocity: DXYZ.add(velocityAccelerated, DXYZ.mulScalar(velocityReduction, effectiveReduction))
        }
    }

    static translate(state: State, target: Decimal) {
        const timeOffset = target.sub(state.second)
        const acceleration = state.activation(AbilityKind.Thrusters)
        const deceleration = state.activation(AbilityKind.Brakes)
        
        const { moved, velocity } = Linear.getTranslationParams(state.translation, timeOffset, acceleration, deceleration)

        state.translation.coordinates.x = state.translation.coordinates.x.add(moved.x)
        state.translation.coordinates.y = state.translation.coordinates.y.add(moved.y)
        state.translation.coordinates.z = state.translation.coordinates.z.add(moved.z)
        state.translation.velocity.x = velocity.x
        state.translation.velocity.y = velocity.y
        state.translation.velocity.z = velocity.z
    }
}