import { Outlet } from 'react-router-dom'
import React from 'react'
import { Canvas } from '@react-three/fiber'
import { canvasInputProps } from '@react-three/uikit'
import { OrbitControls } from '@react-three/drei'
import Skybox from '../skybox/Skybox'
import { BlendFunction } from 'postprocessing'
import { Bloom, EffectComposer, HueSaturation } from '@react-three/postprocessing'
import OverlayGame from './overlayGame/OverlayGame'

interface FrameForCanvasProps {
  children?: React.ReactNode
}

export default function FrameForCanvas(props: FrameForCanvasProps) {
  return (<>
    <Canvas
      dpr={[1,2]}
      camera={{ position: [0, 0, 0], fov: 52.5 }}
      style={{ touchAction: 'none' }}
      gl={{ localClippingEnabled: true }}
      {...canvasInputProps}
    >
      <Skybox />
      <Outlet />
    </Canvas>
    <OverlayGame />
    </>
  )
}
