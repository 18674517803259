import React from 'react'
import styles from './Filters.module.scss'
import clsx from 'clsx'

interface FiltersProps<T extends string | number | symbol, A extends T> {
  activeFilter: A
  onFilterChanged?: (filter: A) => void
  filters: Record<A, { label: string; count: number }>
  className?: string
}

export const Filters = <T extends string | number | symbol, A extends T>({
  activeFilter,
  onFilterChanged,
  filters,
  className
}: FiltersProps<T, A>) => {
  return (
    <div className={clsx(styles.filters, className)}>
      <ul className={styles.list}>
        {Object.keys(filters).map((filter) => {
          const { label, count } = filters[filter as A]

          return (
            <li key={label} className={clsx(styles.item, filter === activeFilter && styles.active)}>
              <button
                onClick={() => {
                  onFilterChanged?.(filter as A)
                }}
              >
                <span className={styles.count}>{count}</span>
                {label}
              </button>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
