import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { routes } from '../../../../router'
import { SessionContext } from '../../../../utils/session/SessionProvider'
import { config } from '../../../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faGlobe, faRightFromBracket, faRightToBracket } from '@fortawesome/free-solid-svg-icons'
import styles from './OverlayGame.module.scss'

interface HeaderProps {
  isMenuOpen?: boolean
  onMenuToggle: (isOpen: boolean) => void
}

export default function Header({ isMenuOpen, onMenuToggle }: HeaderProps) {
  const { identified, openAuth, logout } = React.useContext(SessionContext)
  const navigate = useNavigate()

  const nonIdentifiedLinks = [
    ['Store', routes.storeDefault],
    ['Stations', routes.stations]
  ]

  const identifiedLinks = [
    ...nonIdentifiedLinks,
    ['Vehicles', routes.vehicles],
    ['Profile', routes.profile]
  ]

  const links = identified ? identifiedLinks : nonIdentifiedLinks

  const underlineBlur = (
    <span className={styles.underline}>
      <span className={styles.underlineBlur} />
    </span>
  )

  const closedMenu = (
    <button className={styles.menuToggle}>
      Menu
      <span className={styles.icon}>
        <FontAwesomeIcon icon={faBars} />
      </span>
    </button>
  )

  const openMenu = (
    <>
      {links.map(([label, path]) => (
        <Link to={path} key={path} className={styles.menuLink}>
          {label}
          {underlineBlur}
        </Link>
      ))}
    </>
  )

  const handleAuthAction = () => {
    if (identified) {
      logout?.()
    } else {
      openAuth?.()
    }
  }

  const textLogoUrl = config.image('logo/text-logo.png')

  return (
    <header className={styles.header}>
      <div className={styles.logoContainer}>
        <Link to={routes.home}>
          <img
            src={textLogoUrl}
            alt="Astronation"
            className={styles.logo}
          />
        </Link>
      </div>

      <div
        className={styles.menu}
        onMouseEnter={() => onMenuToggle(true)}
        onMouseLeave={() => onMenuToggle(false)}
      >
        {isMenuOpen ? openMenu : closedMenu}
      </div>

      <div className={styles.headerActions}>
        <button onClick={() => navigate(routes.systemMap)} className={styles.action} title='System map'>
            <FontAwesomeIcon icon={faGlobe} />
        </button>
        <button onClick={handleAuthAction} className={styles.action} title='Logout'>
          <FontAwesomeIcon icon={faRightFromBracket} />
        </button>
      </div>
    </header>
  )
}
