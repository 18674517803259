
import React, { Component } from "react"
import { BodyOfElements } from "../../ui/parts/elements/BodyOfElements"
import { Astronation as AM } from "../protobuf/messages"
import { Astronation } from "../protobuf/state"
import { BigDecimal } from "../../math/BigDecimal"
import { ElementBody } from "../state/Element"
import { Translation } from "../state/Translation"
import { DXYZ } from "../Common"
import { Orbit } from "../state/movement/Orbit"

import { World } from 'miniplex'
import createReactAPI from 'miniplex-react'
import { State } from '../state/State'
import * as THREE from 'three'
import { Action } from '../state/Action'
import { ReactNode } from 'react'
import Decimal from 'decimal.js'
import { SimulatedEntity } from "../../ui/parts/entities/SimulatedEntity"

export const simIds: { [key: string]: number } = {}

export type SimCS = { // Simulated Current State
    translation: {
        position: THREE.Vector3
        rotation: THREE.Quaternion
    }
    state: State
    three?: THREE.Group
    actions?: Action[]
}

const world = new World<SimCS>()

world.onEntityAdded.subscribe((entity) => {
    const id = world.id(entity)
    if (id) {
        simIds[entity.state.id] = id
        console.debug("Added entity id", entity.state.id, id)
    } else {
        console.error("Failed to get entity id", entity.state.id)
    }
})

world.onEntityRemoved.subscribe((entity) => {
    delete simIds[entity.state.id]
    console.debug("Removed entity id", entity.state.id)
})

export const ECS = createReactAPI(world)

export const spawnSystem = (systemInfo: AM.SystemInfo): SimCS[] => {
    const result: SimCS[] = []
    systemInfo.celestials.forEach((celestial) => {
        const state = new State(
            `celestial-${celestial.id}`,
            BigDecimal.ToJsNumber(celestial.second),
            ElementBody.fromProtobuf(celestial.body),
            new Translation(
                new DXYZ(),
                new DXYZ(),
                new DXYZ(),
                new THREE.Quaternion().identity(),
                new THREE.Quaternion().identity(),
                Orbit.fromProtobuf(celestial.orbit)
            )
        )
        const entity: SimCS = ECS.world.add({ state,
            translation: {
                position: new THREE.Vector3(),
                rotation: state.translation.rotation
            }
         })
        console.debug("Spawned celestial", state.id)
        result.push(entity)
    })
    
    return result
}

export const spawnEntity = (input: Astronation.State): SimCS => {
    const state: State = State.fromProtobuf(input)
    
    const entity: SimCS = ECS.world.add({ state,
        translation: {
            position: new THREE.Vector3(),
            rotation: state.translation.rotation
        }
    })
    console.debug("Spawned entity", state.id)

    return entity
}
