import { easings, useSpring } from '@react-spring/three'
import { useMemo } from 'react'

interface CarouselOptions<T> {
  items: T[]
  activeIndex: number
  overflowCount?: number
  slideOffset?: number
}

export const useCarousel = <T>({
  activeIndex,
  items,
  overflowCount = 2,
  slideOffset = 1.2
}: CarouselOptions<T>) => {
  const { position } = useSpring({
    position: [(activeIndex - 1) * -slideOffset, 0, 0],
    config: { duration: 500, easing: easings.easeInOutCubic }
  })

  const getItemByOffset = (offset: number) => {
    const index = (activeIndex + offset + items.length) % items.length
    if (index < 0) {
      return items[items.length + index]
    }
    return items[index]
  }

  const slides = useMemo(() => {
    // array of integer numbers from -OVERFLOW_ITEMS_COUNT to OVERFLOW_ITEMS_COUNT
    const renderedItems = Array.from({ length: overflowCount * 2 + 1 }, (_, i) => i - overflowCount)
    return renderedItems.map((offset) => {
      const item = getItemByOffset(offset)
      return {
        data: item,
        index: activeIndex + offset
      }
    })
  }, [items, activeIndex])

  return {
    slides,
    slideDistance: slideOffset,
    position
  }
}
