import React, { useRef } from 'react'
import { extend, useFrame, useLoader } from '@react-three/fiber'
import { shaderMaterial } from '@react-three/drei'
import * as THREE from 'three'
import { Mesh } from 'three'

const vertexShader = `
varying vec2 vUv;

void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}
`

const fragmentShader = `
uniform float iBrightness;
uniform vec2 iResolution;
uniform sampler2D iChannel0;
varying vec2 vUv;

float snoise(vec3 uv, float res) {
    vec3 s = vec3(1e0, 1e2, 1e4);
    uv *= res;
    
    vec3 uvf = floor(mod(uv, res));
    vec3 uv0 = uvf * s;
    vec3 uv1 = floor(mod(uv + 1.0, res)) * s;
    
    vec3 f = fract(uv);
    f = f * f * (3.0 - 2.0 * f);
    
    vec4 v = vec4(uv0.x + uv0.y + uv0.z, 
                  uv1.x + uv0.y + uv0.z,
                  uv0.x + uv1.y + uv0.z, 
                  uv1.x + uv1.y + uv0.z);
    
    vec4 r = fract(sin(v * 1e-3) * 1e5);
    float r0 = mix(mix(r.x, r.y, f.x), mix(r.z, r.w, f.x), f.y);
    
    r = fract(sin((v + uv1.z - uv0.z) * 1e-3) * 1e5);
    float r1 = mix(mix(r.x, r.y, f.x), mix(r.z, r.w, f.x), f.y);
    
    return mix(r0, r1, f.z) * 2.0 - 1.0;
}

void main() {
    const vec3 orange = vec3(0.8, 0.65, 0.3);
    const vec3 orangeRed = vec3(0.8, 0.35, 0.1);
    
    float radius = 0.24 + iBrightness * 0.2;
    float aspect = iResolution.x/iResolution.y;
    
    vec2 p = vUv - 0.5;
    p.x *= aspect;
    
    float dist = length(p);
    float fade = pow(length(2.0 * p), 0.5);
    float fVal1 = 1.0 - fade;
    float fVal2 = fVal1;
    
    if (dist > radius * 2.0) {
        gl_FragColor = vec4(0.0);
        return;
    }
    
    float angle = atan(p.x, p.y) * 0.159155; // 1.0/6.2832
    vec3 coord = vec3(angle, dist, 0);
    
    for(int i=1; i<=7; i++) {
        float power = pow(2.0, float(i + 1));
        float invPower = 0.5 / power;
        fVal1 += invPower * snoise(coord, power * 10.0);
        fVal2 += invPower * snoise(coord, power * 25.0);
    }
    
    float fadeOffset = max(1.1 - fade, 0.0);
    float corona = (pow(fVal1 * fadeOffset, 2.0) + pow(fVal2 * fadeOffset, 2.0)) * 75.0;
    
    vec2 sp = (vUv * 2.0 - 1.0);
    sp.x *= aspect;
    sp *= (2.0 - iBrightness);
    float r = dot(sp, sp);
    float f = (1.0 - sqrt(abs(1.0 - r))) / r + iBrightness * 0.5;
    
    if(dist < radius) {
        corona *= pow(dist / radius, 24.0);
    }
    
    float starGlow = clamp(1.0 - dist * (1.0 - iBrightness), 0.0, 1.0);
    gl_FragColor = vec4(vec3(f * (0.75 + iBrightness * 0.3) * orange) + corona * orange + starGlow * orangeRed, 1.0);
}
`

export const Star: React.FC = () => {
  const uniforms = {
    iResolution: { value: new THREE.Vector2(window.innerWidth, window.innerHeight) },
    iBrightness: { value: 12 }
  }

  const material = new THREE.ShaderMaterial({
    uniforms: uniforms,
    vertexShader: vertexShader,
    fragmentShader: fragmentShader
  })

  return (
    <mesh material={material}>
      <sphereGeometry args={[0.1, 32, 32]} />
    </mesh>
  )
}
