import React, { useState, useEffect, useCallback, useMemo } from 'react'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import { SessionContext } from './SessionProvider'
import { config } from '../../config'
import { Outlet } from 'react-router-dom'
import { Astronation } from '../../domain/protobuf/messages'
import { Astronation as AS } from '../../domain/protobuf/state'
import { WebSocketMessage } from 'react-use-websocket/dist/lib/types'
import { SimCS, spawnEntity, spawnSystem } from '../../domain/systems/ECS'


export interface WorldSocketCtx {
  readyState: ReadyState
  observer?: SimCS
  nonEmpty: boolean
  pop: () => MessageEvent<any> | undefined
  send: (kind: number, payload: Uint8Array) => void
}

export const WorldSocketContext = React.createContext<WorldSocketCtx>({
  readyState: ReadyState.UNINSTANTIATED,
  nonEmpty: false,
  observer: undefined,
  pop: () => undefined,
  send: (kind: number, payload: Uint8Array) => {} 
})

export default function WorldSocketProvider() {
  const { session, user } = React.useContext(SessionContext)

  if (!session || !user) return null

  const socketUrl = useMemo(() => `${config.socket(user.system)}?jwt=${session}`, [session])
  const [ observer, setObserver ] = useState<SimCS | undefined>(undefined)

  const [incomingQueue, setIncomingQueue] = useState<MessageEvent<any>[]>([])

  const handleMessage = async (blob: Blob) => {
    const data = await blob.arrayBuffer()
    const msg = Astronation.SocketMessage.deserializeBinary(new Uint8Array(data))
    switch (msg.kind) {
      case 0: console.debug('Received a pong', msg.payload); break
      case 2:  // Initial request for SystemInfo
        const systemInfo = Astronation.SystemInfo.deserializeBinary(msg.payload)
        spawnSystem(systemInfo)
        console.debug('Received system info:', systemInfo.toObject())
        send(3, new Uint8Array([1])) // Request entity info on initial request
        break
      case 4:  // Receive the state of the entity
        const entityState = AS.State.deserializeBinary(msg.payload)
        const entity = spawnEntity(entityState)
        if (entity.state.id == user.guid) {
          console.info('Setting observer:', entity.state.id)
          setObserver(entity)
        }
        console.debug('Received entity state:', entityState.toObject())
        break
      default: console.error('Unknown message kind:', msg.kind)
    }
  }

  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
    onOpen: (event) => {
      console.debug('Opened websocket :)', event)
      send(1, new Uint8Array([1]))
    },
    onClose: (event) => console.warn('Closed websocket :(', event),
    onError: (event) => console.error('Socket error:', event),
    onReconnectStop: (event) => console.error('Reconnecting stopped :(', event),
    shouldReconnect: (closeEvent) => {
      console.debug('Should reconnect:', closeEvent)
      return true
    },
    reconnectAttempts: 6,
    reconnectInterval: 5000,
    heartbeat: true,
    onMessage: (message: MessageEvent<any>) => {
      console.debug('Socket received message:', message)
      handleMessage(message.data)
    }
  })

  useEffect(() => {
    if (lastMessage !== null) {
      setIncomingQueue((prevQueue) => prevQueue.concat(lastMessage))
    }
  }, [lastMessage])

  const send = useCallback(
    (kind: number, payload: Uint8Array) => {
      const wrapped: WebSocketMessage = new Astronation.SocketMessage({kind, payload}).serializeBinary()
      
      console.debug('Sending message:', wrapped)
      sendMessage(new Blob([wrapped], { type: 'application/octet-stream' }))
    },
    [sendMessage]
  )

  const pop = useCallback(() => {
    setIncomingQueue((prevQueue) => {
      if (prevQueue.length === 0) return prevQueue
      const [first, ...rest] = prevQueue
      return rest
    })
    return incomingQueue[0]
  }, [incomingQueue])

  const worldSocketCtx = useMemo(
    () => ({
      readyState,
      nonEmpty: incomingQueue.length > 0,
      observer,
      pop,
      send
    }),
    [readyState, incomingQueue.length, observer, pop, send]
  )

  return (
    <WorldSocketContext.Provider value={worldSocketCtx}>
      <Outlet />
    </WorldSocketContext.Provider>
  )
}
