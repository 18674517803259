import React, { useState } from 'react'
import { Form, Link } from 'react-router-dom'
import { SessionContext } from '../../../utils/session/SessionProvider'
import { Input } from '../../layout/controls/input/Input'
import { Button } from '../../layout/controls/button/Button'
import { routes } from '../../../router'
import { nodeApi } from '../../../api/NodeApi'

export default function ValidateToken({ token }: { token?: string }) {
  const sessionCtx = React.useContext(SessionContext)

  if (!sessionCtx || !token) return <></>

  const [code, setCode] = useState('')
  const [codeError, setCodeError] = useState<string | null>(null)

  const handleAccessCodeChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setCode(event.target.value)

  const handleCheckCode = async () => {
    if (!code) {
      setCodeError('Enter the code you got via email')
      return
    } else setCodeError(null)

    nodeApi.validateAuthToken({ token, code, kind: 2 }).then(
      async (loginResult) => {
        if (loginResult) {
          sessionCtx.useAuth!(loginResult.token)
          sessionCtx.closeAuth!()
        } else {
          setCodeError('Failed. Check the code and try again')
        }
      },
      (reason) => {
        console.error('Error sending code:', reason)
        setCodeError('Failed to login. Please try again.')
      }
    )
  }

  return (
    <Form onSubmit={handleCheckCode}>
      <h1>Access Code Verification</h1>

      <Input
        placeholder="Access code"
        label="Check your email for a code from the Astral Bot"
        value={code}
        onChange={handleAccessCodeChange}
        error={codeError}
      />

      <p>
        Check your email inbox for a message from Astral Bot and enter the access code to continue.
      </p>

      <Button type="submit">Validate the access code</Button>
      <Link to={routes.home}>
        <Button variant="ghost">Go back to main page</Button>
      </Link>
    </Form>
  )
}
